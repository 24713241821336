import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, combineLatestWith, debounce, distinctUntilChanged, filter, first, map, of, switchMap, tap } from 'rxjs';
import { CommunitySelectSelectors, GroupsSelectors } from 'src/app/core/state/app.selectors';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { CommunitySelectActions, GroupsActions } from '../../../../core/state/app.actions';
import { DataleanGroupService } from '../services/groups.service';
import {
	setDeleteDataleanGroup,
	setDeleteDataleanGroupError,
	setDeleteDataleanGroupSuccess,
	setGroupsError,
	setGroupsSuccess,
	setStatus,
} from './groups.actions';
import { selectGridSearch, selectPagedData, selectPages, selectSort, selectStatus } from './groups.selectors';
import { Router } from '@angular/router';

@Injectable()
export class GroupsEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private groupService = inject(DataleanGroupService);
	private activeRoute = inject(Router);

	constructor() {}

	fetchGroups$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(GroupsActions.setPages, GroupsActions.setForceRefreshGroups),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(GroupsSelectors.selectCachedPages),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
			]),
			debounce(() =>
				this.store.select(selectStatus).pipe(
					filter((stat) => {
						return !stat || stat !== 'loading';
					})
				)
			),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, cachedPages, communityUUID]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				return this.groupService.fetchGroups(actualPages, sort, gridSearch, communityUUID).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						startingResult.pages = cachedPages;
						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.numberOfPage);
								acc.totalGroupsCount = item.paginationInfo.totalNumberOfElements;
								if (item.result) {
									acc.groups[item.paginationInfo.numberOfPage] = item.result;
								}
							}
							return acc;
						}, structuredClone(startingResult));

						return setGroupsSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setGroupsError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(GroupsActions.setGridSearch, GroupsActions.setSort),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(GroupsSelectors.selectCachedPages),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				([, pagesPrev, sortPrev, gridSearchPrev,], [, pagesCurr, sortCurr, gridSearchCurr, cachedPages]) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => GroupsActions.resetCache())
		)
	);

	deleteGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeleteDataleanGroup),
			switchMap(({ group }) => {
				return this.groupService.deleteGroup(group).pipe(
					map(() => {
						return setDeleteDataleanGroupSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setGroupsError({ error }));
					})
				);
			})
		)
	);

	deleteGroups$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeleteDataleanGroupSuccess, setDeleteDataleanGroupError),
			map(() => GroupsActions.resetCache())
		)
	);

	groupSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setGroupsSuccess),
			map(({ data: { pages } }) => GroupsActions.setCachedPages({ pages }))
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(GroupsActions.resetCache),
			map(() => GroupsActions.setForceRefreshGroups())
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url.includes('groups')),
			map(() => GroupsActions.resetCache())
		)
	);
}
