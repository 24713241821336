import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { Page } from 'addiction-components';
import { ProductError } from 'src/app/pages/products/state/product.state';
import { Course } from 'src/app/shared/models';
import { Recipient } from 'src/app/shared/models/recipient';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';

export const setForceRefreshCourses = createAction('[Datalean Courses] Set Force Refresh');
export const setCourses = createAction('[Datalean Courses] Set Datalean Courses');
export const setCoursesSuccess = createAction(
	'[Datalean Courses] Set Datalean Courses Success',
	props<{ data: { totalCoursesCount: number; pages: number[]; courses: Course[][] } }>()
);
export const setCoursesError = createAction('[Datalean Courses] Set Datalean Courses Error', props<{ error: HttpErrorResponse }>());
export const updatePage = createAction('[Datalean Courses] Update Page', props<{ updatedPage: Partial<Page> }>());
export const setDeleteCourses = createAction('[Datalean Courses] Set Delete Datalean Courses', props<{ uuids: string[] }>());
export const setDeleteCoursesSuccess = createAction('[Datalean Courses] Set Delete Datalean Courses Success');
export const setDeleteCoursesError = createAction('[Datalean Courses] Set Delete Datalean Courses Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Datalean Courses] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Datalean Courses] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Datalean Courses] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Datalean Courses] Set search', props<{ search: string | null }>());
export const setRecipients = createAction('[Datalean Courses] Set recipients', props<{ recipients: Recipient[] }>());
export const setCachedPages = createAction('[Datalean Courses] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Datalean Courses] reset cache');
export const setDataGraph = createAction('[Datalean Courses] Set Course Score', props<{ dataGraph: { percentage: number; total: number } }>());

// Error
export const error = createAction('[Datalean Courses] Error', props<{ error: ProductError }>());
