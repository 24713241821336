import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { DataleanBaseApiService, PaginationInfo, Parts, SimpleObject, UnknownObject } from 'addiction-components';
import { forkJoin, Observable, tap } from 'rxjs';
import { RolesActions } from 'src/app/core/state/app.actions';
import { environment } from 'src/environments/environment';
import { Permission, Role } from '../models';

// @Injectable()
@Injectable({ providedIn: 'root' })
export class RolesService {
	constructor(private store: Store, private baseApi: DataleanBaseApiService) {}

	fetchRoles(pages: number[], sort?: Sort, gridSearch?: string | null): Observable<{ result: Role[] | null; paginationInfo?: PaginationInfo }[]> {
		const obs: Observable<{ result: Role[] | null; paginationInfo?: PaginationInfo }>[] = [];

		const params: { sortBy?: string; q?: string; searchFields?: string } = {};
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name';
		}

		for (const page of pages) {
			const pagination = new PaginationInfo(environment.pageSize, page);

			obs.push(this.baseApi.getEntitiesWithPaginationData<Role>(environment.rolesUrl, params, [], undefined, pagination, undefined));
		}
		return forkJoin(obs);
	}

	getRoles(filters?: SimpleObject) {
		return this.baseApi.getManyPaged<Role>(environment.rolesUrl, [Parts.ROLES], { additionalParams: filters }).pipe();
	}

	countRoles(filters: { structureUUID?: string; q?: string; searchFields?: string }) {
		return this.baseApi.getOne<{ count: number }>(environment.rolesUrl + 'count', '', [], {
			organizationUUID: environment.organizationUUID,
			...filters,
		});
	}

	getPermissions(params: UnknownObject | undefined) {
		return this.baseApi.getEntities<Permission>(environment.permissionUrl, params, [Parts.EMPTY]);
	}

	createRole(role: Role) {
		return this.baseApi.createEntity<Role>(environment.rolesUrl, role, [Parts.ROLES]).pipe(tap(() => this.store.dispatch(RolesActions.resetCache())));
	}

	updateRole(role: Role) {
		return this.baseApi.updateEntity<Role>(environment.rolesUrl, role, [Parts.ROLES]).pipe(tap(() => this.store.dispatch(RolesActions.resetCache())));
	}

	getRole(uuid: string, locale: string = 'all') {
		return this.baseApi.getOne<Role>(environment.rolesUrl, uuid, [Parts.ROLES], { locale });
	}

	deleteRole(role: Role) {
		return this.baseApi.deleteEntity(environment.rolesUrl, role);
	}

	deleteRoles(uuid: string) {
		return this.baseApi.deleteEntity(environment.rolesUrl, { uuid }).pipe(tap(() => this.store.dispatch(RolesActions.setForceRefreshRoles())));
	}
}
