import { FormArray, FormControl, FormGroup } from '@angular/forms';

type Flatten<Type> = Type extends Array<infer Item> ? Item : Type;
class TypedFormArray<T> extends FormArray<FormControl<T> | TypedFormGroup<T>> {}
class TypedFormGroup<T> extends FormGroup<ModelForm<T>> {}

export type ModelForm<T> = {
  [K in keyof T]: T[K] extends Array<unknown>
    ? TypedFormArray<Flatten<T[K]>> | FormControl<T[K]>
    : FormControl<T[K]>;
};
