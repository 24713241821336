import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { ObConfiguratorActions } from 'src/app/core/state/app.actions';
import { OBObjectiveGroupState } from './ob-configurator.state';

export const TAG_OBOBJECTIVEGROUP = 'obObjectiveGroup';

export const initialState: OBObjectiveGroupState = {
	objectiveGroups: [],
	loading: false,
};

export const reducer = createReducer(
	initialState,
	// GENERIC LOADING\ERROR ACTIONS
	on(
		ObConfiguratorActions.createObjectiveGroup,
		ObConfiguratorActions.deleteObjectiveGroup,
		ObConfiguratorActions.updateObjectiveGroup,
		ObConfiguratorActions.reloadObjectiveGroups,
		(state) => ({
			...state,
			loading: true,
			error: undefined,
		})
	),
	on(
		ObConfiguratorActions.createObjectiveGroupFailure,
		ObConfiguratorActions.deleteObjectiveGroupFailure,
		ObConfiguratorActions.updateObjectiveGroupFailure,
		ObConfiguratorActions.loadObjectiveGroupsFailure,
		(state) => ({
			...state,
			loading: false,
			error: true,
		})
	),
	on(ObConfiguratorActions.loadObjectiveGroupsSuccess, (state, { objectiveGroups }) => ({
		...state,
		loading: false,
		objectiveGroups: objectiveGroups,
		editingUUID: undefined,
	})),

	on(ObConfiguratorActions.createObjectiveGroupSuccess, (state, { objectiveGroup }) => ({
		...state,
		loading: false,
		selectedObjectiveGroup: { ...state.selectedObjectiveGroup, objectiveGroup },
		editingUUID: undefined,
		error: false,
	})),

	on(ObConfiguratorActions.deleteObjectiveGroupSuccess, (state, { uuid }) =>
		produce(state, (draft) => {
			draft.loading = false;
			draft.error = false;
			draft.selectedObjectiveGroup = {
				objectiveGroup: uuid === draft.selectedObjectiveGroup?.objectiveGroup?.uuid ? undefined : draft.selectedObjectiveGroup?.objectiveGroup,
			};
		})
	),

	on(ObConfiguratorActions.updateObjectiveGroupSuccess, (state, { objectiveGroup }) =>
		produce(state, (draft) => {
			draft.loading = true;
			draft.error = false;
			draft.selectedObjectiveGroup = { ...draft.selectedObjectiveGroup, objectiveGroup };
		})
	),

	on(ObConfiguratorActions.selectObjectiveGroup, (state, { objectiveGroup }) => ({
		...state,
		selectedObjectiveGroup: { objectiveGroup },
	})),

	on(ObConfiguratorActions.editObjectiveGroup, (state, { uuid }) => ({
		...state,
		editingUUID: uuid,
	}))
);
