import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, forwardRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	ControlValueAccessor,
	FormArray,
	FormControl,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	UntypedFormArray,
	UntypedFormGroup,
} from '@angular/forms';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ExtraStructureFieldType, LocalizableField, StructureField, StructureFieldType } from 'addiction-components';
import { AvailabilityRule, AvailabilityRuleType } from 'src/app/shared/models/reservations/availability.interface';
import { v4 } from 'uuid';
import { ContainerComponent } from '../../container/container.component';

@Component({
	selector: 'datalean-availability-rule-list',
	standalone: true,
	imports: [CommonModule, TranslateModule, forwardRef(() => ContainerComponent), ReactiveFormsModule],
	templateUrl: './availability-rule-list.component.html',
	styleUrls: ['./availability-rule-list.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => AvailabilityRuleListComponent),
		},
	],
})
export class AvailabilityRuleListComponent implements ControlValueAccessor {
	@Input() defaultValue?: AvailabilityRule[] = [];

	private tranlatePipe = inject(TranslatePipe);
	protected destroyRef = inject(DestroyRef);

	public dataset?: unknown;

	ruleForm = new UntypedFormGroup({
		uuid: new FormControl('', { nonNullable: true }),
		name: new FormControl('', { nonNullable: true }),
		isActive: new FormControl(true, { nonNullable: true }),
		type: new FormControl<AvailabilityRuleType>(AvailabilityRuleType.GENERIC, { nonNullable: true }),
		dates: new UntypedFormArray([]),
		availabilityOptions: new UntypedFormArray([]),
	});

	ruleList: StructureField[] = [
		{
			name: 'uuid',
			uuid: v4(),
			type: StructureFieldType.BOOLEAN,
			isRequired: false,
			hidden: true,
		},
		{
			name: 'name',
			label: new LocalizableField([
				{ language: 'it-IT', value: 'Nome' },
				{ language: 'en-US', value: 'Name' },
			]),
			uuid: v4(),
			type: StructureFieldType.TEXT,
			isRequired: true,
			desktopRatio: 50,
		},
		{
			name: 'isActive',
			label: new LocalizableField([
				{ language: 'it-IT', value: 'Attivo' },
				{ language: 'en-US', value: 'Active' },
			]),
			uuid: v4(),
			type: StructureFieldType.TOGGLE,
			isRequired: true,
			predefinedValue: new LocalizableField([{ language: 'it-IT', value: 'true' }]),
			desktopRatio: 50,
		},
		{
			name: 'type',
			label: new LocalizableField([
				{ language: 'it-IT', value: 'Tipo regola' },
				{ language: 'en-US', value: 'Rule Type' },
			]),
			uuid: v4(),
			type: StructureFieldType.RADIO,
			isRequired: true,
			predefinedValue: new LocalizableField([{ language: 'it-IT', value: AvailabilityRuleType.GENERIC }]),
			parsedExtra: Object.values(AvailabilityRuleType).map((type) => ({
				label: this.tranlatePipe.transform('AVAILABILITY-RULE.' + type.toUpperCase()),
				value: type,
			})),
		},
		{
			name: 'dates',
			label: new LocalizableField([
				{ language: 'it-IT', value: 'Date' },
				{ language: 'en-US', value: 'Dates' },
			]),
			uuid: v4(),
			repeatable: true,
			type: StructureFieldType.CONTAINER,
			isRequired: true,
			parsedExtra: { parentControl: true },
			fields: [
				{
					name: 'from',
					label: new LocalizableField([
						{ language: 'it-IT', value: 'Da' },
						{ language: 'en-US', value: 'From' },
					]),
					uuid: v4(),
					type: StructureFieldType.DATE,
					isRequired: true,
					desktopRatio: 50,
				},
				{
					name: 'to',
					label: new LocalizableField([
						{ language: 'it-IT', value: 'A' },
						{ language: 'en-US', value: 'To' },
					]),
					uuid: v4(),
					type: StructureFieldType.DATE,
					isRequired: true,
					desktopRatio: 50,
				},
			],
		},
		{
			name: 'availabilityOptions',
			uuid: v4(),
			type: ExtraStructureFieldType.AVAILABILITY_OPTION,
			repeatable: true,
			isRequired: false,
			label: new LocalizableField([
				{ language: 'it-IT', value: 'Disponibilità' },
				{ language: 'en-US', value: 'Availability' },
			]),
		},
	];

	public onTouched: () => void = () => {};
	public onChange: (value: AvailabilityRule) => void = () => {};

	constructor() {
		this.ruleForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
			next: (value) => {
				this.changeRuleType(value.type);

				this.onChange(value as AvailabilityRule);
			},
		});
	}

	writeValue(obj?: AvailabilityRule): void {
		console.log(obj);
		if (obj) {
			if (obj.dates) {
				const arr = this.ruleForm.get('dates') as FormArray;
				arr.clear();
				for (const date of obj.dates) {
					arr.push(new FormControl(date));
				}
			}

			if (obj.availabilityOptions) {
				const arr = this.ruleForm.get('availabilityOptions') as FormArray;
				arr.clear();
				for (const option of obj.availabilityOptions) {
					arr.push(new FormControl(option));
				}
			}
			this.ruleForm.patchValue(obj);
			this.dataset = structuredClone(obj);
			this.ruleForm.updateValueAndValidity();
		}
	}

	registerOnChange(fn: (value: AvailabilityRule) => void) {
		this.ruleForm.updateValueAndValidity();
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	private changeRuleType(type: AvailabilityRuleType) {
		const rule = this.ruleForm;
		if (rule) {
			const rValue = rule.value;

			if (type === AvailabilityRuleType.GENERIC) {
				if (rValue.dates) {
					this.ruleForm.removeControl('dates');
				}
			} else {
				if (!rValue.dates) {
					this.ruleForm.addControl('dates', new FormArray([]));
				}
			}
		}
	}
}
