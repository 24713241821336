export class PrivacySetting {
  static readonly UUID_FIELD_KEY = 'uuid';
  static readonly STRUCTURE_FIELD_UUID_FIELD_KEY = 'structureFieldUUID';
  static readonly VALUE_FIELD_KEY = 'value';

  constructor(private _id: string, private _structureFieldUUID: string, private _value: boolean) {}

  get id() {
    return this._id;
  }

  get structureFieldUUID() {
    return this._structureFieldUUID;
  }

  get value() {
    return this._value;
  }
}
