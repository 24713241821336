import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Optional,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControlStatus, FormGroupDirective, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { of } from 'rxjs';
import { AutocompleteOption, AutocompleteOptionGroup } from './model/autocomplete-model';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'addiction-autocomplete',
	templateUrl: './autocomplete.component.html',
	standalone: true,
	imports: [CommonModule, MatAutocompleteModule, MatIconModule, FormsModule, ReactiveFormsModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: AutoCompleteComponent,
		},
	],
})
/** Componenete generico per gestire i form-input con varie opzioni di visualizzazione */
export class AutoCompleteComponent implements ControlValueAccessor, OnInit, OnChanges {
	_value?: string = '';

	get value() {
		return this._value ?? '';
	}
	@Input()
	set value(val: string | undefined) {
		this._value = val;
	}

	get hasValue() {
		return !!this._value;
	}

	@Input() disabled: boolean = false;
	@Input() type: 'text' | 'password' | 'email' | 'number' = 'text';
	@Input() name!: string;
	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input() formControlName?: string;
	@Input() icon: string | null = null;
	@Input() iconPosition: 'prepend' | 'append' = 'prepend';
	@Input() requiredIcon?: boolean = false;
	@Input() readonly: boolean = false;
	@Input() options: AutocompleteOption[] = [];
	@Input() enableCalculateOptionWhenEmpty: boolean = false;
	filteredOptions: AutocompleteOption[] = [];
	@Input() optionGroups: AutocompleteOptionGroup[] = [];
	filteredOptionGroups: AutocompleteOptionGroup[] = [];

	@Output() valueChange = new EventEmitter<string>();
	@Output() clickInput = new EventEmitter<Event>();
	@Output() valueSelected = new EventEmitter<string>();
	@Input() keydown = new EventEmitter<KeyboardEvent>();

	//se passato a true, non appena entri calcola le opzioni
	//così al primo click sul componente è già disponibile
	//la lista per l'autocomplete
	@Input() forceStartAutocomplite: boolean = false;

	@ViewChild('input') input!: ElementRef;

	formControlStatus$ = of<FormControlStatus>('VALID');

	get hasIcon() {
		return !!this.icon;
	}

	private _registeredOnChange = (_value: string | undefined) => {
		return;
	};
	private _registeredOnTouched = () => {
		return;
	};

	constructor(@Optional() protected form: FormGroupDirective) {}

	ngOnInit(): void {
		// si linka allo stato del form
		if (!!this.formControlName) {
			const formControl = this.form?.form?.get(this.formControlName);
			if (formControl) {
				this.formControlStatus$ = formControl.statusChanges;
			}
		}

		if (this.forceStartAutocomplite) {
			this.calculateOptions();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('options' in changes) {
			this.calculateOptions(this._value);
		}
	}

	writeValue(obj: string | undefined): void {
		this._value = obj;
	}
	registerOnChange(fn: (val: string | undefined) => void): void {
		this._registeredOnChange = fn;
	}
	registerOnTouched(fn: () => void): void {
		this._registeredOnTouched = fn;
	}
	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	markAsTouched() {
		this._registeredOnTouched();
	}

	manageChange(value: string) {
		this.calculateOptions(value);
		this._registeredOnChange(value);
		this.valueChange.emit(value);
	}

	onOptionSelected($event: MatAutocompleteSelectedEvent) {
		this.valueSelected.emit($event.option.value);
	}

	forceValueFromCode(value?: string) {
		this._value = value;
		this.manageChange(value ?? '');
	}

	calculateOptions(value: string = '') {
		value = value?.toLowerCase();
		if (this.options && (this.options.length || this.enableCalculateOptionWhenEmpty))
			this.filteredOptions = structuredClone(this.options).filter((o: AutocompleteOption) => o.label.toLowerCase().includes(value));
		if (this.optionGroups.length)
			this.filteredOptionGroups = structuredClone(this.optionGroups).filter((group: AutocompleteOptionGroup) => {
				const hasChildren = group.options.some((o: AutocompleteOption) => o.label.toLowerCase().includes(value));
				if (hasChildren) group.options = group.options.filter((o: AutocompleteOption) => o.label.toLowerCase().includes(value));
				return group.label.toLowerCase().includes(value) || hasChildren;
			});
	}
}
