  /**
   * confronta due array di elementi semplici, o con un livello massimo di profondità
   * @param array1
   * @param array2
   * @param findFields se valorizzato, confronta gli elementi dell'array andando a confrontare elemento[findFields], altrimente si usa l'includes normale
   * @returns true se i due array sono uguali, false altrimenti
   */
  export function checkEqualArray(array1: any[] | undefined, array2: any[] | undefined, findFields?: string): boolean {
    if(array1 === undefined && array2 === undefined) return true
    if((array1 === undefined && array2 !== undefined) || (array1 !== undefined && array2 === undefined)) return false
    if(array1?.length !== array2?.length) return false

    if(array1?.length === 0 && array2?.length ===  0) return true

    if(findFields){
      return array1?.every(element1 => array2?.find(el => el[findFields] == element1[findFields])) ?? false
    }
    return array1?.every(element1 => array2?.includes(element1)) ?? false
  }

  export function firstArray<T>(array: T[]) : T | undefined {
    if(array.length) return array[0]

    return undefined
  }
