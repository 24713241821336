<div class="option-container" [matMenuTriggerFor]="menu" [ngClass]="menu._classList">
  <ng-content select=".button"></ng-content>
</div>

<mat-menu #menu="matMenu" [yPosition]="yPosition" [xPosition]="xPosition" class="option-menu">
  <ng-container *ngFor="let option of options">
    <button class="profile {{option.label}}" mat-menu-item (click)="onOptionClick(option)">
      <span>{{'GRID_OPTION.'+option.label | translate}}</span>
    </button>
  </ng-container>
</mat-menu>
