import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IntegrationReducers } from 'src/app/core/state/app.reducers';
import { IntegrationState } from './integration.state';

const selectIntegrationsState = createFeatureSelector<IntegrationState>(IntegrationReducers.TAG);

export const selectData = createSelector(selectIntegrationsState, (state) => state.data);
export const selectPages = createSelector(selectIntegrationsState, (state) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectIntegrationsState, (state) => state.gridSearch);
export const selectStatus = createSelector(selectIntegrationsState, (state) => state.status);
export const selectSort = createSelector(selectIntegrationsState, (state) => state.sort);
export const selectCachedPages = createSelector(selectIntegrationsState, (state) => state.cachedPages);
