import { Injectable, inject } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	DataleanBaseApiService,
	DataleanPagedResult,
	DataleanSelectOption,
	FeatureValue,
	FeatureValueType,
	ModalService,
	PaginationInfo,
	Parts,
	SimpleObject,
	SortInfo,
	Structure,
	StructureType,
} from 'addiction-components';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { HeaderLocaleService } from 'src/app/components';
import { ProductVariantsActions } from 'src/app/core/state/app.actions';
import { CommunitySelectSelectors } from 'src/app/core/state/app.selectors';
import { ImportExportDialogComponent } from 'src/app/shared/components/import-export-dialog/import-export-dialog.component';
import { ProductVariant } from 'src/app/shared/models/product-variants.interface';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ProductVariantsService {
	private store = inject(Store);
	private translateService = inject(TranslateService);
	private headerSrv = inject(HeaderLocaleService);
	private baseApi = inject(DataleanBaseApiService);
	private modalSrv = inject(ModalService);
	private translate = inject(TranslateService);
	private destroyed$ = new Subject();
	private lastCommunitySelected$ = this.store.select(CommunitySelectSelectors.selectLastCommunitySelected).pipe(takeUntil(this.destroyed$));
	private lastCommunitySelected: string | undefined = undefined;

	constructor() {}

	init(): void {
		this.lastCommunitySelected$.pipe(takeUntil(this.destroyed$)).subscribe((communityUUID) => {
			this.lastCommunitySelected = communityUUID;
			this.getStructureAndFeatures(communityUUID);
		});
	}

	destroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	getStructureAndFeatures(communityUUID?: string): void;
	getStructureAndFeatures() {
		const structureAdditionalParams: {
			type?: StructureType;
		} = { type: StructureType.PRODUCTVARIANTS };

		const featureAdditionalParams: {
			featureValueCommunityUUID?: string;
			type?: FeatureValueType;
			locale?: string;
		} = { type: FeatureValueType.PRODUCTVARIANTS, locale: this.translateService.currentLang ?? this.translateService.defaultLang };

		//TODO rimttere quando è possibile prendere le categorie in modo decente filtrate per community
		// if (communityUUID !== undefined) {
		// 	featureAdditionalParams.featureValueCommunityUUID = communityUUID;
		// } else if (this.lastCommunitySelected !== undefined) {
		// 	featureAdditionalParams.featureValueCommunityUUID = this.lastCommunitySelected;
		// }

		this.baseApi
			.getMany<Structure>(environment.structureUrl, [Parts.STRUCTURE_FIELDS], { additionalParams: structureAdditionalParams })
			.subscribe((structures) => {
				this.store.dispatch(ProductVariantsActions.setStructures({ structures }));
			});
		this.baseApi
			.getMany<FeatureValue>(environment.featureValueUrl, [Parts.FEATURE_VALUES], {
				additionalParams: featureAdditionalParams,
			})
			.subscribe((features) => {
				this.store.dispatch(ProductVariantsActions.setFeatures({ features }));
			});
	}

	getProducts(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: { uuids?: string[]; structureUUID?: string; query?: string; communityUUID?: string };
	}) {
		const { filters, parts, locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name']; // parametrizzare i campi di ricerca??
		}
		if (filters?.communityUUID) params['communityUUID'] = filters.communityUUID;

		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		return this.baseApi.getManyPaged<ProductVariant>(environment.productVariantsUrl ?? '', parts ?? [Parts.ALL], {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	getStructures(parts: Parts[]) {
		if (!parts) parts = [Parts.EMPTY];

		return this.baseApi.getManyPaged<Structure>(environment.structureUrl, parts);
	}

	countProducts(filters: { structureUUID?: string; communityUUID?: string; q?: string; searchFields?: string }) {
		// console.log('countProducts', filters, environment.productVariantsUrl ?? '' + 'count');
		return this.baseApi.getOne<{ count: number }>((environment.productVariantsUrl ?? '') + 'count', '', [], {
			organizationUUID: environment.organizationUUID,
			...filters,
		});
	}

	public multipleUpdate(bulkPayload: { uuidList: string[]; delete?: boolean; active?: boolean }) {
		return this.baseApi.updateBulk((environment.productVariantsUrl ?? '') + 'multipleUpdate', bulkPayload).pipe(map(() => true));
	}

	fetchProducts(
		pages: number[],
		sort?: Sort,
		locale?: string,
		gridSearch?: string | null,
		filters?: { featureValue?: string[]; structureUUID?: string; communityUUID?: string; active?: boolean }
	): Observable<DataleanPagedResult<ProductVariant>[]> {
		const obs: Observable<DataleanPagedResult<ProductVariant>>[] = [];

		const params: {
			sortBy?: string;
			q?: string;
			searchFields?: string;
			locale?: string;
			active?: boolean;
			structureUUID?: string;
			featureValueList?: string;
			communityUUID?: string;
		} = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;

		if (filters?.featureValue?.length) params['featureValueList'] = filters.featureValue.join(',') + '#and';

		if (filters?.active !== undefined) params['active'] = filters.active;

		if (filters?.communityUUID) params['communityUUID'] = filters.communityUUID;

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name,description';
		}

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<ProductVariant>(
					environment.productVariantsUrl ?? '',
					[Parts.FEATURE_VALUE_LIST, Parts.LESSON_CONTENT_LIST, Parts.UPDATE_DATE],
					{
						pagination,
						additionalParams: params,
					}
				)
			);
		}
		return forkJoin(obs);
	}

	openImportExportModal(structures: DataleanSelectOption<string>[] | null | undefined) {
		const $openModalRes = this.modalSrv.openDialog(
			ImportExportDialogComponent,
			{ title: this.translate.instant('MODALS.IMPORT_EXPORT.MODAL_TITLE') },
			{
				showExport: true,
				showImport: true,
				endpointImport: (environment.productVariantsUrl ?? '') + 'import/',
				endpointExport: (environment.productVariantsUrl ?? '') + 'export/',
				structures: structures,
				negativeReflection: true,
			}
		);
		return $openModalRes.result$;
	}
}
