import { createAction as createActionNGRX, props } from '@ngrx/store';
import { TailType } from 'src/app/shared/models/on-boarding/tail.model';
import { Tail } from '../../../../shared/models/on-boarding/tail.model';

/* READ */
export const loadTails = createActionNGRX('[Tails] Load Tails');
export const setActiveType = createActionNGRX('[Tails] Set Type Tails', props<{ tailType: TailType }>());
export const reloadTails = createActionNGRX('[Tails] Reload Tails');
export const loadTailsSuccess = createActionNGRX('[Tails] Load Tails Success', props<{ tails: Tail[] }>());
export const loadTailsFailure = createActionNGRX('[Tails] Load Tails Failure', props<{ error: unknown }>());

/* CREATE */
export const createTail = createActionNGRX('[Tails] Create Tail', props<{ tail: Tail; parentUuid: string | null }>());
export const createTailSuccess = createActionNGRX('[Tails] Create Tail Success', props<{ tail: Tail; parentUuid: string | null }>());
export const createTailFailure = createActionNGRX('[Tails] Create Tail Failure', props<{ error: unknown }>());

/* UPDATE */
export const updateTail = createActionNGRX('[Tails] Update Tail', props<{ tail: Tail; uuid: string }>());
export const updateTailSuccess = createActionNGRX('[Tails] Update Tail Success', props<{ tail: Tail }>());
export const updateTailFailure = createActionNGRX('[Tails] Update Tail Failure', props<{ error: unknown }>());

/* DELETE */
export const deleteTail = createActionNGRX('[Tails] Delete Tail', props<{ uuid: string }>());
export const deleteTailSuccess = createActionNGRX('[Tails] Delete Tail Success', props<{ uuid: string }>());
export const deleteTailFailure = createActionNGRX('[Tails] Delete Tail Failure', props<{ error: unknown }>());

/* SELECTION */
export const selectTail = createActionNGRX('[Tails] Select Tail', props<{ tail?: Tail }>());
export const changeTailSelected = createActionNGRX('[Tails] Change Tail Selected', props<{ tail: Tail }>());
export const editTail = createActionNGRX('[Tails] Edit Tail', props<{ uuid: string }>());
export const createNewTail = createActionNGRX('[Tails] Create New Tail', props<{ tail: Tail }>());
