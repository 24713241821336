export const mimeTypeMap: Record<string, string> = {
  // pdf
  pdf: 'application/pdf',
  // xls
  xls: 'application/vnd.ms-excel',
  xlm: 'application/vnd.ms-excel',
  xla: 'application/vnd.ms-excel',
  xlc: 'application/vnd.ms-excel',
  xlt: 'application/vnd.ms-excel',
  xlw: 'application/vnd.ms-excel',
  xlam: 'application/vnd.ms-excel.addin.macroenabled.12',
  xlsb: 'application/vnd.ms-excel.sheet.binary.macroenabled.12',
  xlsm: 'application/vnd.ms-excel.sheet.macroenabled.12',
  xltm: 'application/vnd.ms-excel.template.macroenabled.12',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // ppt
  ppt: 'application/vnd.ms-powerpoint',
  pps: 'application/vnd.ms-powerpoint',
  pot: 'application/vnd.ms-powerpoint',
  ppam: 'application/vnd.ms-powerpoint.addin.macroenabled.12',
  pptm: 'application/vnd.ms-powerpoint.presentation.macroenabled.12',
  sldm: 'application/vnd.ms-powerpoint.slide.macroenabled.12',
  ppsm: 'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
  potm: 'application/vnd.ms-powerpoint.template.macroenabled.12',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  // doc
  doc: 'application/msword',
  dot: 'application/msword',
  docm: 'application/vnd.ms-word.document.macroenabled.12',
  dotm: 'application/vnd.ms-word.document.macroenabled.12',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  // odt
  odc: 'application/vnd.oasis.opendocument.chart',
  otc: 'application/vnd.oasis.opendocument.chart-template',
  odb: 'application/vnd.oasis.opendocument.database',
  odf: 'application/vnd.oasis.opendocument.formula',
  odft: 'application/vnd.oasis.opendocument.formula-template',
  odg: 'application/vnd.oasis.opendocument.graphics',
  otg: 'application/vnd.oasis.opendocument.graphics-template',
  odi: 'application/vnd.oasis.opendocument.image',
  oti: 'application/vnd.oasis.opendocument.image-template',
  odp: 'application/vnd.oasis.opendocument.presentation',
  otp: 'application/vnd.oasis.opendocument.presentation-template',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  ots: 'application/vnd.oasis.opendocument.spreadsheet-template',
  odt: 'application/vnd.oasis.opendocument.text',
  odm: 'application/vnd.oasis.opendocument.text-master',
  ott: 'application/vnd.oasis.opendocument.text-template',
  oth: 'application/vnd.oasis.opendocument.text-web',
  // 3gp
  '3gp': 'video/3gpp',
  'video/3gpp2': '3g2',
  // h
  h261: 'video/h261',
  h263: 'video/h263',
  h264: 'video/h264',
  // jpeg
  jpgv: 'video/jpeg',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  // png
  png: 'image/png',
  // mp4
  mp4: 'video/mp4',
  // mpeg
  mpeg: 'video/mpeg',
  mpg: 'video/mpeg',
  mpe: 'video/mpeg',
  m1v: 'video/mpeg',
  m2v: 'video/mpeg',
  // ogg
  ogv: 'video/ogg',
  // quicktime
  qt: 'video/quicktime',
  mov: 'video/quicktime',
  // gll
  gll: 'application/octet-stream',
  // dwg
  dwg: 'application/acad',
};

export function getMimeTypeFromAssetExtension(extension?: string): string|undefined{
  if(!extension) return undefined
  return mimeTypeMap[extension]
}

export function getAssetTypeFromExtension(extension?: string): "video"|"pdf"|"image"|undefined {
  const mime = getMimeTypeFromAssetExtension(extension);
  if(mime?.includes("pdf")) return "pdf"
  if(mime?.includes("video")) return "video"
  if(mime?.includes("image")) return "image"
  return undefined
}
