import { Component, Input } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PaginationInfo, TableRow } from 'addiction-components';
import { environment } from 'src/environments/environment';
import { ApplicationUser } from '../../models';
import { GenericSelectorDialogComponent } from '../generic-selector-dialog/generic-selector-dialog.component';

@Component({
	selector: 'datalean-app-user-selector-dialog',
	templateUrl: './app-user-selector-dialog.component.html',
	styleUrls: ['./app-user-selector-dialog.component.scss'],
})
export class AppUserSelectorDialogComponent extends GenericSelectorDialogComponent<Partial<ApplicationUser>> {
	@Input()
	override selectedUUIDs: string[] = [];
	@Input() override searchFields: string = 'firstName,lastName,email';
	@Input() override searchParamName: string = 'q';
	@Input() limit: number | null = null;

	override endpoint: string = environment.applicationUsersUrl;

	selectedRows?: TableRow[];

	constructor() {
		super();
		this.pageSize = 200;
		this.currentPage$.next([new PaginationInfo(this.pageSize, 0)]);
	}

	override mapData(data: ApplicationUser[]): TableRow[] {
		const result: TableRow[] = [];
		for (const item of data) {
			result.push({
				uuid: item.uuid,
				name: item.firstName + ' ' + item.lastName,
				email: item.email,
			});
		}
		return result;
	}

	override onSelectionChange(event: MatCheckboxChange, entityUUID?: string): void {
		if (entityUUID) {
			// TODO: NECESSARIO GESTIRE IL LIMIT  DENTRO AL GCS @Manu farà la modifica
			if (this.limit && this.gcs.totalSelected + 1 > this.limit && event?.source.checked) {
				if (event) event.source.checked = false;
				return;
			}
		}
		super.onSelectionChange(event, entityUUID);
	 }
}
