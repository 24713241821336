import { Asset } from "./asset";

export interface Collection {
  uuid: string;
  name: string;
  isPublic: boolean;
  updateDate: Date;
  assets?: Asset[];
  assetsRef: string[]
}
