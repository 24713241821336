import { ActionCreator, createAction } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { ObjectValuesType } from 'addiction-components';
import {
	ApplicationUsersActions,
	CommunicationActions,
	ContentsActions,
	CoursesActions,
	GroupsActions,
	LessonsActions,
	ProductActions,
	QuizActions,
	SmartGroupsActions,
	SurveysActions,
} from 'src/app/core/state/app.actions';

export const CommunityRoutes = {
	ApplicationUsers: '/idm/app-users',
	Communications: '/communications',
	Contents: '/contents',
	Courses: '/lms/courses',
	Groups: '/idm/groups',
	Lessons: '/lms/lessons',
	MediaLibrary: '/media-library',
	SmartGroups: '/idm/smart-groups',
	Survey: '/surveys',
	Products: '/products',
	Quiz: '/lms/quiz',
} as const;
export type CommunityRoutes = ObjectValuesType<typeof CommunityRoutes>;

export function getReloadActionFromPath(activatedPath?: string): ActionCreator<string, () => TypedAction<string>> {
	let action: ActionCreator<string, () => TypedAction<string>> = createAction('[MAIN] azione che non fa nulla');
  // console.log('getReloadActionFromPath', activatedPath);
	switch (activatedPath) {
		case CommunityRoutes.ApplicationUsers:
			action = ApplicationUsersActions.setForceRefreshApplicationUsers;
			break;
		case CommunityRoutes.Communications:
			action = CommunicationActions.setForceRefreshCommunications;
			break;
		case CommunityRoutes.Contents:
			action = ContentsActions.setForceRefreshContents;
			break;
		case CommunityRoutes.Courses:
			action = CoursesActions.setForceRefreshCourses;
			break;
		case CommunityRoutes.Groups:
			action = GroupsActions.setForceRefreshGroups;
			break;
		case CommunityRoutes.Lessons:
			action = LessonsActions.setForceRefreshLessons;
			break;
		// case Rotte.MediaLibrary: //gestione diversa perchè usa il contextStore
		// 	action = MediaLibraryActions.setForceRefreshAssets;
		// 	break;
		case CommunityRoutes.SmartGroups:
			action = SmartGroupsActions.setForceRefreshSmartGroups;
			break;
		case CommunityRoutes.Survey:
			action = SurveysActions.setForceRefreshSurveys;
			break;
		case CommunityRoutes.Products:
			action = ProductActions.setForceRefreshProducts;
			break;
		case CommunityRoutes.Lessons:
			action = LessonsActions.setForceRefreshLessons;
			break;
		case CommunityRoutes.Courses:
			action = CoursesActions.setForceRefreshCourses;
			break;
		case CommunityRoutes.Quiz:
			action = QuizActions.setForceRefreshQuiz;
			break;
		default:
			break;
	}

	return action;
}
