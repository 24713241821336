import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DataleanBaseApiService, PaginationInfo, Parts } from 'addiction-components';
import { Observable, forkJoin } from 'rxjs';
import { ApplicationUserGroup } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DataleanGroupService {
	constructor(private dataleanApi: DataleanBaseApiService) {}

	fetchGroups(
		pages: number[],
		sort?: Sort,
		gridSearch?: string | null,
		communityUUID?: string
	): Observable<{ result: ApplicationUserGroup[] | null; paginationInfo?: PaginationInfo }[]> {
		const obs: Observable<{ result: ApplicationUserGroup[] | null; paginationInfo?: PaginationInfo }>[] = [];

		const params: { sortBy?: string; q?: string; searchFields?: string; communityUUIDList?: string[] } = {};

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name';
		}
		if (communityUUID) params.communityUUIDList = [communityUUID];

		for (const page of pages) {
			const pagination = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.dataleanApi.getEntitiesWithPaginationData<ApplicationUserGroup>(
					environment.groupsUrl,
					params,
					[Parts.MEMBERS],
					undefined,
					pagination,
					undefined
				)
			);
		}
		return forkJoin(obs);
	}

	deleteGroup(group: ApplicationUserGroup) {
		return this.dataleanApi.deleteEntity(environment.groupsUrl, group);
	}
}
