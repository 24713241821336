import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, of, switchMap } from 'rxjs';
import { combineLatestWith, distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';
import { CalendarActions, CommunitySelectActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { CalendarService } from '../services/calendar.service';
import { deleteCalendar, setCalendarsSuccess, setDeleteCalendarsError, setDeleteCalendarsSuccess } from './calendar.actions';
import { selectCachedPages, selectCalendarsFilters, selectGridSearch, selectPagedData, selectPages, selectSort } from './calendar.selectors';
import { CommunitySelectSelectors } from '../../../../core/state/app.selectors';
import { Router } from '@angular/router';

@Injectable()
export class CalendarEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private reservationService = inject(CalendarService);
	private activeRoute = inject(Router);

	fetchReservation$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(CalendarActions.setPages, CalendarActions.setForceRefreshCalendars),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCalendarsFilters),
				this.store.select(selectCachedPages),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
			]),
			// debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			//	filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(CalendarActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, filters, cachedPages, communityUUID]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				return this.reservationService.fetchReservations(actualPages, sort, gridSearch, filters, communityUUID).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						// console.log(startingResult);
						startingResult.pages = cachedPages;
						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.pageNumber);
								acc.totalReservationCount = item.paginationInfo.totalElements ?? 0;
								if (item.result) {
									acc.reservations[item.paginationInfo.pageNumber] = item.result.map((reservation) => ({
										...reservation,
									}));
								}
							}
							return acc;
						}, structuredClone(startingResult));

						// console.log(result);

						return CalendarActions.setCalendarsSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						return of(CalendarActions.setCalendarsError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CalendarActions.setGridSearch, CalendarActions.setSort, CalendarActions.updateFilters),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCalendarsFilters),
				this.store.select(selectCachedPages),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				(
					[, pagesPrev, sortPrev, gridSearchPrev, { dateFrom, dateTo, productUUID, status, user }, ],
					[
						,
						pagesCurr,
						sortCurr,
						gridSearchCurr,
						{ dateFrom: dateFromCurr, dateTo: dateToCurr, productUUID: productUUIDCurr, status: statusCurr, user: userCurr },
						cachedPages,
					]
				) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						gridSearchCurr === gridSearchPrev &&
						dateFrom === dateFromCurr &&
						dateTo === dateToCurr &&
						productUUID === productUUIDCurr &&
						status === statusCurr &&
						user === userCurr &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => CalendarActions.resetCache())
		)
	);

	deleteReservation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteCalendar),
			switchMap(({ uuid }) => {
				return this.reservationService.deleteReservation(uuid).pipe(
					map(() => {
						return setDeleteCalendarsSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setDeleteCalendarsError({ error }));
					})
				);
			})
		)
	);

	reservationSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setCalendarsSuccess),
			map(({ data: { pages } }) => CalendarActions.setCachedPages({ pages }))
		)
	);

	deleteReservationSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CalendarActions.setDeleteCalendarsError, CalendarActions.setDeleteCalendarsSuccess),
			map(() => CalendarActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CalendarActions.resetCache),
			map(() => CalendarActions.setForceRefreshCalendars())
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url === '/calendar'),
			map(() => CalendarActions.resetCache())
		)
	);

	constructor() {}
}
