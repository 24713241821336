<div class="header-container">
	<div class="left-container">
		<button (click)="dispatchSelectMenu(menuType.MENU)" class="menu-button" [ngClass]="{ active: isMenuActive[menuType.MENU] }"></button>
		<button (click)="dispatchSelectMenu(menuType.FEATURE)" class="navigation-button" [ngClass]="{ active: isMenuActive[menuType.FEATURE] }"></button>
		<button (click)="dispatchSelectMenu(menuType.FILTER)" class="filters-button" [ngClass]="{ active: isMenuActive[menuType.FILTER] }"></button>
		<addiction-search [value]="searchValue$ | async" [placeholder]="'SEARCH.PLACEHOLDER' | translate" (search)="filter($event)"></addiction-search>
	</div>
	<div class="right-container">
    <ng-container *ngrxLet="{currentSortValue: currentSortValue$, assetDisplayMode:assetDisplayMode$} as vm">
      <datalean-header-with-input
        *ngIf="vm.currentSortValue && vm.assetDisplayMode"
        [sortValue]="vm.currentSortValue"
        [assetdisplayMode]="vm.assetDisplayMode"></datalean-header-with-input>
    </ng-container>
	</div>
</div>
