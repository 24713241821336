import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { CommunitySelectActions, TagsAction } from '../../../core/state/app.actions';
import { TagsService } from '../../../pages/tags/services/tags.service';

@Injectable()
export default class TagsEffects {
	private actions$ = inject(Actions);
	private tagSrv = inject(TagsService);
	private activeRoute = inject(Router);

	constructor() {}

	loadTag$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TagsAction.loadTags),
			switchMap(() =>
				this.tagSrv.getTags().pipe(
					map((tags) => TagsAction.loadTagsSuccess({ tags })),
					catchError((error) => of(TagsAction.loadTagsFailure({ error })))
				)
			)
		)
	);

	updateTag$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TagsAction.updateTag),
			switchMap(({ tag }) =>
				this.tagSrv.updateTag(tag).pipe(
					map((tag) => TagsAction.updateTagSuccess({ tag })),
					catchError((error) => of(TagsAction.updateTagFailure({ error })))
				)
			)
		)
	);

	refresh$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TagsAction.updateTagSuccess, TagsAction.createTagSuccess),
			map(() => {
				return TagsAction.loadTags();
			})
		)
	);

	createTag$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TagsAction.createTag),
			switchMap(({ tag }) =>
				this.tagSrv.createTag(tag).pipe(
					map((tag) => TagsAction.createTagSuccess({ tag })),
					catchError((error) => of(TagsAction.createTagFailure({ error })))
				)
			)
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url.includes('/tags')),
			map(() => TagsAction.resetCache())
		)
	);
}
