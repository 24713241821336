import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { LessonsActions } from 'src/app/core/state/app.actions';
import { Lesson, STATE_STATUS } from 'src/app/shared/models';

export const DATALEAN_LESSON_TAG = 'lessons';

export interface LessonsState {
	gridSearch: string | null;
	sort?: Sort;
	data: { totalLessonsCount: number; lessons: Lesson[][] };
	pages: number[];
	status: STATE_STATUS;
	deleteStatus: STATE_STATUS;
	cachedPages: number[];
}

const initialState: LessonsState = {
	status: STATE_STATUS.UNKNOWN,
	deleteStatus: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	pages: [],
	data: { totalLessonsCount: 0, lessons: [] },
	cachedPages: [],
	sort: { active: 'updateDate', direction: 'desc' },
};

export const reducers = createReducer(
	initialState,
	on(LessonsActions.setSort, (state: LessonsState, { sort }) => ({
		...state,
		sort,
	})),
	on(LessonsActions.setPages, (state: LessonsState, { pages }) => ({
		...state,
		pages,
	})),
	on(LessonsActions.setLessonsSuccess, (state: LessonsState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				lessons: data.lessons,
				totalLessonsCount: data.totalLessonsCount,
			},
			pages: data.pages,
		};
	}),
	on(LessonsActions.setLessonsError, (state: LessonsState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(LessonsActions.setStatus, (state: LessonsState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(LessonsActions.setGridSearch, (state: LessonsState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(LessonsActions.setDeleteLessons, (state: LessonsState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(LessonsActions.setDeleteLessonsSuccess, (state: LessonsState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(LessonsActions.setDeleteLessonsError, (state: LessonsState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
	on(LessonsActions.setCachedPages, (state: LessonsState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(LessonsActions.resetCache, (state: LessonsState) => ({
		...state,
    status: STATE_STATUS.READY,
		cachedPages: [],
		pages: [0],
		data: { totalLessonsCount: 0, lessons: [] },
	}))
);

export const LessonReducer = (state: LessonsState | undefined, action: Action) => {
	return reducers(state, action);
};
