import { Environment } from 'src/app/shared/models/environment.interface';
import { baseEnvironmentProd } from 'src/environments/baseEnvironment.prod';

export const environment:Environment = {
  ...baseEnvironmentProd,
  organizationPrefix: 'EMK',
  organizationUUID: '94d5ab68-a1e9-4c46-9745-bdad002a69dc',
  activeLanguage: 'it-IT',
  productVariantsUrl: 'https://product-variant.datalean-nodejs.catalean.com/product-variants/',
};
