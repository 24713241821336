import { ChangeDetectionStrategy, Component, Input, OnInit, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormGroup,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ControlValueAccessor,
} from '@angular/forms';
import { Observer } from 'rxjs';
import { MatSliderModule } from '@angular/material/slider';

@Component({
  selector: 'addiction-range',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatSliderModule],
  templateUrl: './range.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => RangeComponent) },
  ],
})
export class RangeComponent implements ControlValueAccessor {
  @Input() min?: number;
  @Input() max?: number;
  @Input() step?: number;
  @Input() label?: string;
  @Input() showTickMarks: boolean = true;
  @Input() discrete: boolean = false;

  formGroup = new FormGroup({
    min: new FormControl<number | undefined>(undefined),
    max: new FormControl<number | undefined>(undefined),
  });


  onTouched: () => void = () => {};

  writeValue(obj: Partial<{ min: number; max: number }>): void {
    this.formGroup.patchValue(obj);
  }

  registerOnChange(fn: Observer<unknown | null>): void {
    this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
