/**
 * trasforma una stringa BE di date in due date
 * es r|data1|data2 => {data1, data2}
 * @param backEndString stringa che rappresenta un intervallo di date della forma r|data1|data2
 * @returns { dateFrom, dateTo } le date possono essere nulle
 */
export const getDatesFromBEstring = (backEndString?: string) => {
  let dateFrom: Date|undefined, dateTo: Date|undefined;

  if(backEndString){
    let dates = backEndString.split('|');
    dates = dates.filter(el => el !== 'r'); //tolgo la r iniziale
    if(dates.length === 2){
      if(dates[0] !== '') dateFrom = new Date(dates[0])
      if(dates[1] !== '') dateTo = new Date(dates[1])
    }
    else if(dates.length === 1 && dates[0] !== '')
      dateFrom = new Date(dates[0])
  }

  return { dateFrom, dateTo }
}
export const getDatesStringsFromBEstring = (backEndString?: string) => {
  let dateFrom: string|undefined, dateTo: string|undefined;

  if(backEndString){
    let dates = backEndString.split('|');
    dates = dates.filter(el => el !== 'r'); //tolgo la r iniziale
    if(dates.length === 2){
      if(dates[0] !== '') dateFrom = dates[0]
      if(dates[1] !== '') dateTo = dates[1]
    }
    else if(dates.length === 1 && dates[0] !== '')
      dateFrom = dates[0]
  }

  return { dateFrom, dateTo }
}

/**
 * converte un range di date in una dataBEstring del tipo r|data1|data2
 * @param dateFrom data di inizio range
 * @param dateTo data di fine range
 * @returns una dataBEstring del tipo r|data1|data2
 */
export const convertDateInBEstring = (dateFrom: Date|undefined, dateTo: Date|undefined): string|undefined => {
  let dateBEstring;

  const dateFromISO = dateFrom?.toISOString()
  const dateToISO = dateTo?.toISOString()
  if(dateFromISO){
    dateBEstring = 'r|'+dateFromISO
  }
  if(dateToISO){
    if(dateBEstring) dateBEstring += '|'+dateToISO
    else dateBEstring = 'r||'+dateToISO
  }

  return dateBEstring
}

export const isDateRange = (string: string) => {
  if(typeof string === 'string') {
    return string.match(/^r\|(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)?\|(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)?$/gm)
  }
  return false;
}

