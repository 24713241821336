import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { CoursesActions } from 'src/app/core/state/app.actions';
import { Course, STATE_STATUS } from 'src/app/shared/models';
import { Recipient } from 'src/app/shared/models/recipient';

export const DATALEAN_LESSON_TAG = 'courses';

export interface CoursesState {
	gridSearch: string | null;
	sort?: Sort;
	data: { totalCoursesCount: number; courses: Course[][] };
	pages: number[];
	status: STATE_STATUS;
	deleteStatus: STATE_STATUS;
	recipients?: Recipient[];
	cachedPages: number[];
	courseScore?: number;
	dataGraph: { percentage: number; total: number } | undefined;
}

const initialState: CoursesState = {
	status: STATE_STATUS.UNKNOWN,
	deleteStatus: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	pages: [],
	data: { totalCoursesCount: 0, courses: [] },
	cachedPages: [],
	sort: { active: 'updateDate', direction: 'desc' },
	dataGraph: { percentage: 0, total: 100 },
};

export const reducers = createReducer(
	initialState,
	on(CoursesActions.setSort, (state: CoursesState, { sort }) => ({
		...state,
		sort,
	})),
	on(CoursesActions.setPages, (state: CoursesState, { pages }) => ({
		...state,
		pages,
	})),
	on(CoursesActions.setCoursesSuccess, (state: CoursesState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				courses: data.courses,
				totalCoursesCount: data.totalCoursesCount,
			},
			pages: data.pages,
		};
	}),
	on(CoursesActions.setCoursesError, (state: CoursesState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(CoursesActions.setStatus, (state: CoursesState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(CoursesActions.setGridSearch, (state: CoursesState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(CoursesActions.setDeleteCourses, (state: CoursesState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(CoursesActions.setDeleteCoursesSuccess, (state: CoursesState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(CoursesActions.setDeleteCoursesError, (state: CoursesState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
	on(CoursesActions.setRecipients, (state: CoursesState, { recipients }) => ({
		...state,
		recipients,
	})),
	on(CoursesActions.setCachedPages, (state: CoursesState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(CoursesActions.resetCache, (state: CoursesState) => ({
		...state,
		cachedPages: [],
		state: STATE_STATUS.READY,
		pages: [0],
		data: { totalCoursesCount: 0, courses: [] },
	})),
	on(CoursesActions.setDataGraph, (state: CoursesState, { dataGraph }) => ({
		...state,
		dataGraph: {
			percentage: dataGraph.percentage,
			total: dataGraph.total,
		},
	}))
);

export const CourseReducer = (state: CoursesState | undefined, action: Action) => {
	return reducers(state, action);
};
