import { createReducer, on } from '@ngrx/store';
import { CommunitySelectActions } from 'src/app/core/state/app.actions';
import { CommunitySelectState } from './community-select.state';
import { CommunityRoutes } from '../../helpers/routes-utils';

export const TAG = 'communitySelect';

const initialMapCommunityPages: Record<string, string> = Object.values(CommunityRoutes).reduce((accumolator, key) => {
	accumolator[key] = 'all';
	return accumolator;
}, {} as Record<string, string>);

const initialState: CommunitySelectState = {
	data: [],
	lastCommunitySelected: 'all',
	filters: undefined,
	showNoCommunityChoice: false,
	mapLastComunityPerPage: initialMapCommunityPages,
};

export const counterReducer = createReducer(
	initialState,
	on(CommunitySelectActions.setData, (state: CommunitySelectState, { data }) => ({
		...state,
		data,
	})),
	on(CommunitySelectActions.setLastCommunitySelected, (state: CommunitySelectState, { lastCommunitySelected }) => ({
		...state,
		lastCommunitySelected,
	})),
	on(CommunitySelectActions.setFilters, (state: CommunitySelectState, { filters }) => ({
		...state,
		filters,
	})),
	on(CommunitySelectActions.setShowNoCommunityChoice, (state: CommunitySelectState, { showNoCommunityChoice }) => ({
		...state,
		showNoCommunityChoice,
	})),
	on(CommunitySelectActions.setLastCommunityFromPath, (state: CommunitySelectState, { activePath }) => {
		const newMap = structuredClone(state.mapLastComunityPerPage);
		if (activePath && state.lastCommunitySelected) {
			newMap[activePath] = state.lastCommunitySelected;
		}
		return {
			...state,
			mapLastComunityPerPage: newMap,
		};
	})
);
