import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { ObConfiguratorActions } from 'src/app/core/state/app.actions';
import { OBActionState } from './ob-configurator.state';

export const TAG_OBACTION = 'obAction';

export const initialState: OBActionState = {
	actions: [],
	loading: false,
};

export const reducer = createReducer(
	initialState,
	// GENERIC LOADING\ERROR ACTIONS
	on(
		ObConfiguratorActions.createAction,
		ObConfiguratorActions.deleteAction,
		ObConfiguratorActions.updateAction,
		ObConfiguratorActions.reloadActions,
		(state) => ({
			...state,
			loading: true,
			error: undefined,
		})
	),
	on(
		ObConfiguratorActions.createActionFailure,
		ObConfiguratorActions.deleteActionFailure,
		ObConfiguratorActions.updateActionFailure,
		ObConfiguratorActions.loadActionsFailure,
		(state) => ({
			...state,
			loading: false,
			error: true,
		})
	),
	on(ObConfiguratorActions.loadActionsSuccess, (state, { actions }) => ({
		...state,
		loading: false,
		actions: actions,
		editingUUID: undefined,
	})),
	on(ObConfiguratorActions.createActionSuccess, (state, { action }) => ({
		...state,
		loading: false,
		selectedAction: { ...state.selectedAction, action },
		editingUUID: undefined,
		error: false,
	})),
	on(ObConfiguratorActions.deleteActionSuccess, (state, { uuid }) =>
		produce(state, (draft) => {
			draft.loading = false;
			draft.error = false;
			draft.selectedAction = {
				action: uuid === draft.selectedAction?.action?.uuid ? undefined : draft.selectedAction?.action,
			};
		})
	),
	on(ObConfiguratorActions.updateAction, (state, { action }) =>
		produce(state, (draft) => {
			draft.loading = true;
			draft.error = false;
			draft.selectedAction = { ...draft.selectedAction, action };
		})
	),
	on(ObConfiguratorActions.selectAction, (state, { action }) => ({
		...state,
		selectedAction: { action },
	})),
	on(ObConfiguratorActions.editAction, (state, { uuid }) => ({
		...state,
		editingUUID: uuid,
	}))
);
