/** Interfaccia usata per rappresentare internamente al componente un nodo.
 * Non dovrebbe essere usata al di fuori del componente
 */

import { TreeKeyOptions } from "../../models/tree.interface";



// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface TreeBrowserNode<T = any> {
	/** Etichetta da mostrare nell'HTML (già localizzata se necessario)*/
	title: string;
	/** Figli del nodo */
	children: TreeBrowserNode<T>[];
	/** Parent, NULL solo per il primo nodo "finto" root */
	parent?: TreeBrowserNode<T>;
	/** ID del nodo orignale */
	id: string;
	/** Se è root */
	isRoot: boolean;
	/** Nodo originale in input dal quale è stato costruito questo elemento */
	data: T;
	/** Indica la posizione all'interno dell'array dei figli (del parent) */
	index: number;
	/** Indica se c'è un errore in editing di questo nodo */
	error: boolean;
	/** Identificatore del tipo di nodo */
	type: 'tree-browser-node';
	/** Indica se è stato spuntato  */
	checked: boolean;
}

export interface TreeBrowserConfig {
	/** Impostazioni delle chiavi da usare nella gestione dei nodi */
	keys: TreeKeyOptions;
	rootLabelKey: string;
}

export interface TreeRemoveEvent<T> {
	node: T;
	parent?: T;
	index: number;
}

export interface TreeRenameEvent<T> {
	node: T;
	name: string;
}

/** Descrive l'evento di drop di un altro elemento draggabile in questo componente*/
export interface TreeExternalDropEvent<T> {
	/** Il nodo sul quale è stato droppato */
	target: T;

	/** Il cdkDragData dell'elemento droppato */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	droppedItem: any; // non c'è modo di sapere quale altro elemento viene droppato dafuori
}

export interface TreeBrowserButton {
	cssClass?: string;
	content?: string;
	onClick?: () => void;
	data?: unknown;
	icon?: string;
}
