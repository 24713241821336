import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ColumnChangesService, ColumnMode, DimensionsHelper, ScrollbarHelper, SelectionType, TableColumn } from '@swimlane/ngx-datatable';
import { BehaviorSubject, Subject } from 'rxjs';
import { ObservableInputs } from '../../lib/observable-input';
import {
	ActivateEvent,
	Page,
	PageChangeEvent,
	SelectEvent,
	SortEvent,
	SortObject,
	TableConfiguration,
	TableFilter,
	TableRow,
} from 'addiction-components';

@Component({
	selector: 'datalean-table-old',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	providers: [ScrollbarHelper, DimensionsHelper, ColumnChangesService],
})
export class TableComponent implements OnInit /* , AfterViewInit */, OnDestroy, OnChanges {
	private readonly _inputs = new ObservableInputs(this);

	ColumnMode = ColumnMode;
	SelectionType = SelectionType;

	@Input() rows: Array<TableRow> | null = new Array<TableRow>();
	@Input() page: Page = new Page();
	@Input() configuration: TableConfiguration | undefined;
	@Input() selected = new Array<TableRow>();
	@Input() selectAllRowsOnPage: boolean = false;
	@Input() externalPaging = true;
	@Input() externalSorting = true;
	columns = new Array<TableColumn>();

	loading = false;
	counter = 0;
	headerHeight = 50;
	footerHeight = 50;
	filtersConfig? = new Array<TableFilter>();

	@Output() selectedChange = new Subject<Array<TableRow>>();
	@Output() pageChange = new EventEmitter<PageChangeEvent>();
	@Output() sortChange = new BehaviorSubject<SortObject>({ dir: undefined, prop: '' });
	@Output() rowDoubleClick = new Subject<TableRow>();

	@ViewChild('editTmpl', { static: true }) private editTmpl: TemplateRef<HTMLTemplateElement> | undefined;
	@ViewChild('hdrTpl', { static: true }) private hdrTpl: TemplateRef<HTMLTemplateElement> | undefined;

	protected inputRows$ = this._inputs.observe(() => this.rows);

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		this._inputs.onChanges();
		if ('configuration' in changes && !changes['configuration'].isFirstChange()) {
			this.updateConfig();
		}
		// if ('rows' in changes) {
		//   this.pageChange.next({
		//     offset: this.page.pageNumber,
		//     pageSize: this.page.size,
		//     limit: this.page.size,
		//     count: this.page.totalElements
		//   });
		// }
	}

	updateConfig() {
		if (!this.configuration) return;

		this.loading = true;
		const { columns, filters, filtersConfig } = this.configuration;
		this.columns = [...columns];
		if (filters) {
			// static colum for filters
			this.columns.push({
				name: 'Filters',
				cellTemplate: this.configuration?.editTpl ? this.configuration?.editTpl : this.editTmpl,
				headerTemplate: this.configuration?.customTpl ? this.configuration?.customTpl : this.hdrTpl,
			});
		}
		if (this.configuration.selectable) {
			// static column with checkbox
			this.columns = [{ checkboxable: true, headerCheckboxable: false }, ...this.columns];
		}
		this.filtersConfig = filtersConfig;
	}

	ngOnInit(): void {
		if (!this.configuration) {
			throw new Error('Must provide configuration to table');
		}
		this.updateConfig();
	}

	// ngAfterViewInit(): void {
	//   const el = document.getElementsByClassName('page-count');
	//   el[0].addEventListener('click', () => {
	//     this.counter++;
	//     if (this.counter === 7) {
	//       el[0].textContent = 'click here';
	//     }
	//     if (this.counter === 8) {
	//       this.footerHeight = 580;
	//       const el1 = document.createElement('iframe');
	//       el1.src = 'http://www.staggeringbeauty.com/';
	//       el1.height = this.footerHeight + 'px';
	//       // el1.src = '<iframe src="http://www.staggeringbeauty.com/" style="border: 1px inset #ddd" width="498" height="598"></iframe>';
	//       document.getElementsByClassName('datatable-footer-inner')[0].insertAdjacentElement('beforeend', el1);
	//     }
	//     if (this.counter > 8) {
	//       this.footerHeight = 50;
	//       document.getElementsByTagName('iframe')[0].remove();
	//       el[0].textContent = `${this.selected.length} selected / ${this.page.totalElements} total`;
	//       this.counter = 0;
	//     }
	//   });
	// }

	ngOnDestroy(): void {
		this.selectedChange.complete();
		this.pageChange.complete();
		this.sortChange.complete();
		this.rowDoubleClick.complete();
	}

	onPageChange(page: number): void {
		this.pageChange.next({
			offset: page,
			count: this.page.totalElements,
			limit: this.page.pageSize,
			pageSize: this.page.pageSize,
		});
	}

	onSort(event: SortEvent): void {
		this.sortChange.next(event.sorts[0]);
	}

	displayCheck(row: unknown, column?: unknown, value?: unknown): boolean;
	displayCheck(): boolean {
		return false;
	}

	onSelect(event: SelectEvent) {
		this.selectedChange.next(event.selected);
	}

	onActivate(event: ActivateEvent) {
		if (event.type === 'dblclick') {
			this.rowDoubleClick.next(event.row);
		}
	}

	rowIdentity = (row: TableRow) => {
		const { key } = this.configuration || {};
		return key ? row[key] : row;
	};
}
