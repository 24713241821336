import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnDestroy, TemplateRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { Store } from '@ngrx/store';
import { AddictionComponentsModule, SearchComponent } from 'addiction-components';
import { ContextStore } from 'context-store';
import { Subject, map } from 'rxjs';
import { MediaLibraryActions, NavigationActions } from 'src/app/core/state/app.actions';
import { HeaderSelector, MediaLibrarySelectors, NavigationSelector } from 'src/app/core/state/app.selectors';
import { TreeBrowserModule } from 'src/app/shared/components/tree-browser/tree-browser.module';
import { MenuSelected, OpenedMenuType } from 'src/app/shared/models';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';
import { MainNavbarComponent } from '../../main-navbar/main-navbar.component';
import { HeaderWithInputComponent } from '../header-with-input/header-with-input.component';

@Component({
	selector: 'datalean-header-button',
	templateUrl: './header-button.component.html',
	styleUrls: ['./header-button.component.scss'],
	standalone: true,
	imports: [
		MatTabsModule,
		MatIconModule,
		TreeBrowserModule,
		CommonModule,
		MainNavbarComponent,
		HeaderWithInputComponent,
		SharedModule,
		AddictionComponentsModule,
		SearchComponent,
	],
})
export class HeaderButtonComponent implements OnDestroy {
	//TODO: controllare se null va bene
	@Input('treeBrowserTemplate') treeBrowserTemplateref: TemplateRef<HTMLTemplateElement> | null = null;

	private ctxStore = inject(ContextStore);
	private store = inject(Store);
	private destroySubject$ = new Subject();
	protected destroyRef = inject(DestroyRef);

	menuSelected$ = this.store.select(NavigationSelector.selectMenu);
	isSelectedTreeComponent$ = this.store.select(NavigationSelector.selectHasTreeComponent);
	activePath$ = this.store.select(NavigationSelector.selectActivePath);
	menuSelectedType = MenuSelected;
	sortOptions = environment.sortOptions;

	isMenuActive: { [key: string]: boolean } = {
		[OpenedMenuType.MENU]: false,
		[OpenedMenuType.FEATURE]: false,
		[OpenedMenuType.FILTER]: false,
	};

	menuType = OpenedMenuType;

	currentSortValue$ = this.ctxStore.select(MediaLibrarySelectors.selectSorting).pipe(
		map((sort) => {
			return this.sortOptions.find((so) => {
				const dir = so.value.split('#')[1];
				const value = so.value.split('#')[0];
				return value === sort?.sortBy && dir === sort.sortDirection;
			});
		})
	);

	searchValue$ = this.ctxStore.select(MediaLibrarySelectors.selectFilters).pipe(
		map((filters) => {
			return filters?.q;
		})
	);

	assetDisplayMode$ = this.store.select(HeaderSelector.selectMediaLibraryGridViewOptions).pipe(
		map((vo) => {
			return vo.assetdisplayMode as 1 | 2;
		})
	);

	constructor() {
		this.menuSelected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result) => {
			this.openMenu(result.menuLabel);
		});
	}

	ngOnDestroy(): void {
		this.destroySubject$.next(true);
		this.destroySubject$.complete();
	}

	public dispatchSelectMenu(type: OpenedMenuType): void {
		this.ctxStore.dispatch(NavigationActions.selectedMenu({ menuLabel: type }));
	}

	openMenu(type: OpenedMenuType) {
		if (type === OpenedMenuType.MENU) {
			this.isMenuActive[OpenedMenuType.MENU] = !this.isMenuActive[OpenedMenuType.MENU];
			this.isMenuActive[OpenedMenuType.FILTER] = false;
			this.isMenuActive[OpenedMenuType.FEATURE] = false;
		} else if (type === OpenedMenuType.FILTER || type === OpenedMenuType.FEATURE) {
			this.isMenuActive[type] = !this.isMenuActive[type];
			this.isMenuActive[OpenedMenuType.MENU] = false;
		}
	}

	filter(text: string | null) {
		this.ctxStore.dispatch(MediaLibraryActions.setViewMode({ filters: { q: text || undefined }, viewMode: 'search', mergeFilters: true }));
	}
}
