import { AssetType } from 'addiction-components';

export interface AssetSaveData {
	uuid?: string;
	name?: string;
	extension?: string | null;
	parentUUID?: string | null;
	path?: string | null;
	previewUUID?: string;
	file?: File;
	assetType?: AssetType;
	tags?: string[];
	index?: number;
	featureValueList?: string[];
	originalName?: string;
	communityUUID?: string;
}
