import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, combineLatestWith, debounce, distinctUntilChanged, filter, first, map, of, switchMap, tap } from 'rxjs';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { DataleanUsersActions } from '../../../../core/state/app.actions';
import { DataleanUserService } from '../services/datalean-users.service';
import {
  setDataleanUsersError,
  setDataleanUsersSuccess,
  setDeleteDataleanUser,
  setDeleteDataleanUserSuccess
} from './datalean-users.actions';
import { selectCachedPages, selectGridSearch, selectPagedData, selectPages, selectSort, selectStatus } from './datalean-users.selectors';

@Injectable()
export class DataleanUsersEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private userService = inject(DataleanUserService);

	constructor() {}

	fetchUsers$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(DataleanUsersActions.setPages, DataleanUsersActions.setForceRefreshDataleanUsers),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(DataleanUsersActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, cachedPages]) => {
        const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				return this.userService.fetchUsers(actualPages, sort, gridSearch).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
            startingResult.pages = cachedPages;
						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.numberOfPage);
								acc.totalUsersCount = item.paginationInfo.totalNumberOfElements;
								if (item.result) {
									acc.users[item.paginationInfo.numberOfPage] = item.result;
								}
							}
							return acc;
						}, structuredClone(startingResult));

						return setDataleanUsersSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setDataleanUsersError({ error }));
					})
				);
			})
		)
	);

  datalenaUserSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(setDataleanUsersSuccess),
    map(({ data: { pages } }) => DataleanUsersActions.setCachedPages({ pages }))
  )
);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DataleanUsersActions.setGridSearch, DataleanUsersActions.setSort),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCachedPages),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(([, pagesPrev, sortPrev, gridSearchPrev], [, pagesCurr, sortCurr, gridSearchCurr, cachedPages]) => {
				// console.log(action);
				return (
					pagesPrev.length === pagesCurr.length &&
					pagesPrev.every((f) => pagesCurr.includes(f)) &&
					sortCurr?.active === sortPrev?.active &&
					sortCurr?.direction === sortPrev?.direction &&
					gridSearchCurr === gridSearchPrev &&
					pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
				);
			}),
			map(() => DataleanUsersActions.resetCache())
		)
	);

	deleteUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeleteDataleanUser),
			switchMap(({ user }) => {
				return this.userService.deleteUser(user).pipe(
					map(() => {
						return setDeleteDataleanUserSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setDataleanUsersError({ error }));
					})
				);
			})
		)
	);

	deleteAndDisableProduct$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				DataleanUsersActions.setDeleteDataleanUserSuccess,
				DataleanUsersActions.setDeleteDataleanUserError,

			),
			map(() => DataleanUsersActions.resetCache())
		)
	);


	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				DataleanUsersActions.resetCache,
			),
			map(() => DataleanUsersActions.setForceRefreshDataleanUsers())
		)
	);

}
