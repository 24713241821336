import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { FeatureValue, Structure } from 'addiction-components';
import { ContentError } from 'src/app/pages/contents/state/content.state';
import { FiltersType } from 'src/app/pages/products/state/product.reducers';
import { Content, STATE_STATUS } from 'src/app/shared/models';


export const setFilters = createAction('[Contents] Set Filters', props<{ filters: FiltersType }>());
export const setStructures = createAction('[Contents] Set Contents Structures', props<{ structures: Array<Structure> }>());
export const setFeatures = createAction('[Contents] Set Contents Features', props<{ features: Array<FeatureValue> }>());
export const updateFilters = createAction('[Contents] Update Filters', props<{ updatedFilters: FiltersType }>());
export const addFeatureValueToFilters = createAction('[Contents] Add FeatureValue To Filters', props<{ featureValueUUID: string }>());
export const removeFeatureValueFromFilters = createAction('[Contents] Remove FeatureValue From Filters', props<{ featureValueUUID: string }>());
export const deleteContents = createAction('[Contents] Delete Contents', props<{ uuids: string[] }>());
export const disableContents = createAction('[Contents] Disable Contents', props<{ uuids: string[] }>());

export const setForceRefreshContents = createAction('[Contents] Set Force Refresh');
export const setContents = createAction('[Contents] Set Contents');
export const setContentsSuccess = createAction(
	'[Contents] Set Contents Success',
	props<{ data: { totalContentsCount: number; pages: number[]; contents: Content[][] } }>()
);
export const setContentsError = createAction('[Contents] Set Contents Error', props<{ error: HttpErrorResponse }>());
export const setDeleteContents = createAction('[Contents] Set Delete Contents', props<{ uuids: string[] }>());
export const setDeleteContentsSuccess = createAction('[Contents] Set Delete Contents Success');
export const setDeleteContentsError = createAction('[Contents] Set Delete Contents Error', props<{ error: HttpErrorResponse }>());
export const setDisableContentsSuccess = createAction('[Contents] Set Disable Contents Success');
export const setDisableContentsError = createAction('[Contents] Set Disable Contents Error', props<{ error: HttpErrorResponse }>());
export const setPages = createAction('[Contents] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Contents] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Contents] Set search', props<{ search: string | null }>());
export const setStatus = createAction('[Contents] Set status', props<{ status: STATE_STATUS }>());
export const setCachedPages = createAction('[Contents] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Contents] reset cache');
// Error
export const error = createAction('[Contents] Error', props<{ error: ContentError }>());
