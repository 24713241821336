export interface ConfirmationMessageModel {
	title: string;
	htmlMessage: string;
	buttons: ConfirmationButton[];
	successMessage?: string;
	failureMessage?: string;
}

export interface ConfirmationButton {
	label: string;
	handler?: () => void;
}
