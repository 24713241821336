import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
	selector: 'addiction-virtual-grid',
	standalone: true,
	imports: [CommonModule, ScrollingModule],
	templateUrl: './virtual-grid.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualGridComponent<T> {
	@Input() headerTemplate?: TemplateRef<unknown>;
	@Input() cardTemplate?: TemplateRef<unknown>;
	@Input() noItemsTemplate?: TemplateRef<unknown>;
	@Input() approxCardHeight?: number = 200;

	@Input({ required: true }) set data(data: { items: T[][]; pages?: number[]; totalItems?: number }) {
		this._availablePages = data.pages ? [...data.pages] : [];
		let arrayLength = data.totalItems;
		if (!arrayLength) arrayLength = data.items?.length > 0 ? data.items[0].length : 20;
		const tmpArray = Array.from<T>({ length: arrayLength });
		if (tmpArray.length > 0 && data.items) {
			for (const page of this._availablePages) {
				if (page < data.items.length) tmpArray.splice(page * this.pageSize, data.items[page]?.length ?? 0, ...data.items[page]);
			}
		}
		this._itemsRows = this.chunkArray(tmpArray, this._columns);
	}

	@Input() set columns(columns: number) {
		this._columns = columns;
		if (this._itemsRows.length) {
			this._itemsRows = this.chunkArray(this._itemsRows.flat(), this._columns);
			this.onScroll();
		}
	}
	@Input({ required: true }) pageSize: number = 20;

	@Output() cardClick = new EventEmitter<T>();
	@Output() pageChanges = new EventEmitter<number[]>();

	@ViewChild(CdkVirtualScrollViewport) scrollViewPort?: CdkVirtualScrollViewport;

	_availablePages: number[] = [];
	_itemsRows: T[][] = [];
	_columns = 6;

  chunkArray(array: T[], size: number): T[][] {
		const result = [];
		for (let i = 0; i < array.length; i += size) {
			result.push(array.slice(i, i + size));
		}
		return result;
	}

	loadPaginatedData(pages: number[]) {
		this.pageChanges.emit(pages);
		this._availablePages = pages;
	}

	trackBy(i: number) {
		return i;
	}

  onScroll() {
		const scrollRange = this.scrollViewPort?.getRenderedRange();
		if (scrollRange) {
			const startPage = this._getPageForIndex(scrollRange?.start);
			// if(startPage !== 0) {
			//   startPage--;
			// }
			const endPage = this._getPageForIndex(scrollRange?.end);
			let pagesToLoad = this._range(startPage, endPage /* +1 */);
			// console.log(pagesToLoad, this.scrollViewPort?.getRenderedRange());

			if (pagesToLoad.length === 0) {
				pagesToLoad = [0];
			}
			if (pagesToLoad.every((val) => this._availablePages.includes(val))) {
				this._availablePages = [...pagesToLoad];
			}
			if (!this._arraysEqual(pagesToLoad, this._availablePages) || this._availablePages.length === 0) this.loadPaginatedData(pagesToLoad);
		}
	}

	private _getPageForIndex = (index: number) => Math.floor(((index + 1) * this._columns) / this.pageSize);
	private _range = (start: number, stop: number) => Array.from({ length: stop - start + 1 }, (_, i) => start + i);
	private _arraysEqual(a: unknown[], b: unknown[]) {
		if (a === b) return true;
		if (a == null || b == null) return false;
		if (a.length !== b.length) return false;

		for (let i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) return false;
		}
		return true;
	}
}
