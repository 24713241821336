import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageHandler } from 'addiction-components';
import { catchError, map, of, switchMap } from 'rxjs';
import { ObConfiguratorActions } from 'src/app/core/state/app.actions';
import { ObConfiguratorService } from '../services/ob-configurator.service';

@Injectable()
export class OBObjectiveGroupEffect {
	private actions$ = inject(Actions);
	private obConfiguratorSrv = inject(ObConfiguratorService);
	private store = inject(Store);
	private messageHandler = inject(MessageHandler);
	private translateService = inject(TranslateService);

	loadObjectiveGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				ObConfiguratorActions.loadObjectiveGroups,
				ObConfiguratorActions.reloadObjectiveGroups,
				ObConfiguratorActions.createObjectiveGroupSuccess,
				ObConfiguratorActions.updateObjectiveGroupSuccess,
				ObConfiguratorActions.deleteObjectiveGroupSuccess
			),
			switchMap(() =>
				this.obConfiguratorSrv.getObjectiveGroups().pipe(
					map((objectiveGroups) => ObConfiguratorActions.loadObjectiveGroupsSuccess({ objectiveGroups })),
					catchError((error) => of(ObConfiguratorActions.loadObjectiveGroupsFailure({ error })))
				)
			)
		)
	);
	// UPDATE ONE
	updateObjectiveGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.updateObjectiveGroup),
			switchMap(({ objectiveGroup }) =>
				this.obConfiguratorSrv.updateObjectiveGroup(objectiveGroup).pipe(
					map((objectiveGroup) => {
						this.messageHandler.createToast('ALERT.SUCCESS_SAVE_MESSAGE');
						return ObConfiguratorActions.updateObjectiveGroupSuccess({ objectiveGroup });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('ALERT.ERROR_SAVE_MESSAGE');
						return of(ObConfiguratorActions.updateObjectiveGroupFailure({ error }));
					})
				)
			)
		)
	);

	// CREATE ONE
	createObjectiveGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.createObjectiveGroup),
			switchMap(({ objectiveGroup }) =>
				this.obConfiguratorSrv.createObjectiveGroup(objectiveGroup).pipe(
					map((objectiveGroup) => {
						this.messageHandler.createToast('ALERT.SUCCESS_SAVE_MESSAGE');
						return ObConfiguratorActions.createObjectiveGroupSuccess({ objectiveGroup });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('ALERT.ERROR_SAVE_MESSAGE');
						return of(ObConfiguratorActions.createObjectiveGroupFailure({ error }));
					})
				)
			)
		)
	);

	// DELETE ONE
	deleteObjectiveGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.deleteObjectiveGroup),
			switchMap(({ uuid }) =>
				this.obConfiguratorSrv.deleteObjectiveGroup(uuid).pipe(
					map(() => ObConfiguratorActions.deleteObjectiveGroupSuccess({ uuid })),
					catchError((error) => of(ObConfiguratorActions.deleteObjectiveGroupFailure({ error })))
				)
			)
		)
	);

	selectedObjectiveGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.changeObjectiveGroupSelected),
			map(({ objectiveGroup }) => ObConfiguratorActions.selectObjectiveGroup({ objectiveGroup }))
		)
	);

	selectedEditableObjectiveGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.changeObjectiveGroupSelected),
			map(({ objectiveGroup }) => ObConfiguratorActions.editObjectiveGroup({ uuid: objectiveGroup.uuid }))
		)
	);

	editNewObjectiveGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.createNewObjectiveGroup),
			map(() => ObConfiguratorActions.editObjectiveGroup({ uuid: 'new' }))
		)
	);
}
