import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NavLink } from '../../models/navLink.interface';
import { Permission } from '../../models/permission.enum';
import { IPermission } from '../../models/permission.interface';
import * as UserSelector from 'src/app/shared/state/user/user.selectors';
import { NavigationState } from './navigation.state';
import { NavigationReducer } from 'src/app/core/state/app.reducers';

const selectLayoutState = createFeatureSelector<NavigationState>(NavigationReducer.TAG);
export const selectMenu = createSelector(selectLayoutState, (state) => ({ menuLabel: state.menuLabel }));
export const selectHasTreeComponent = createSelector(selectLayoutState, (state) => state.hasTreeBrowser);
export const selectActivePath = createSelector(selectLayoutState, (state) => state.activePath);

export const selectNavLinks = createSelector(selectLayoutState, UserSelector.selectPermissions, (state, userPermissions) => {
	return state.navLinks.reduce((resultArray: NavLink[], navLink) => {
		// filtra i link visibili all'utente corrente
		if (canSeeNavLink(userPermissions, navLink)) {
			if (navLink.children) {
				const children = navLink.children.filter((child) => canSeeNavLink(userPermissions, child));
				if (children.length) {
					resultArray.push({ ...navLink, children });
				}
			} else if (navLink.path) {
				resultArray.push({ ...navLink });
			}
		}
		// else console.log(`User cannot see ${navLink.label}`, userPermissions)
		return resultArray;
	}, []);
});

function canSeeNavLink(permissions: IPermission[], navLink: NavLink) {
	if (navLink.requiredPermissions?.includes(Permission.ANY) || !navLink.requiredPermissions?.length) {
		return true;
	}
	for (const requiredPermissions of navLink.requiredPermissions || []) {
		if (permissions.some((permission) => permission.name === requiredPermissions)) {
			return true;
		}
	}
	return false;
}
