import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { QuizActions } from 'src/app/core/state/app.actions';
import { Quiz, STATE_STATUS } from 'src/app/shared/models';

export const DATALEAN_QUIZ_TAG = 'quiz';

export interface QuizState {
	gridSearch: string | null;
	sort?: Sort;
	data: { totalQuizCount: number; quiz: Quiz[][] };
	pages: number[];
	status: STATE_STATUS;
	deleteStatus: STATE_STATUS;
	cachedPages: number[];
}

const initialState: QuizState = {
	status: STATE_STATUS.UNKNOWN,
	deleteStatus: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	sort: { active: 'updateDate', direction: 'desc' },
	pages: [0],
	data: { totalQuizCount: 0, quiz: [] },
	cachedPages: [],
};

export const reducers = createReducer(
	initialState,
	on(QuizActions.setSort, (state: QuizState, { sort }) => ({
		...state,
		sort,
	})),
	on(QuizActions.setPages, (state: QuizState, { pages }) => ({
		...state,
		pages,
	})),
	on(QuizActions.setQuizSuccess, (state: QuizState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				quiz: data.quiz,
				totalQuizCount: data.totalQuizCount,
			},
			pages: data.pages,
		};
	}),
	on(QuizActions.setQuizError, (state: QuizState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(QuizActions.setStatus, (state: QuizState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(QuizActions.setGridSearch, (state: QuizState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(QuizActions.setDeleteQuiz, (state: QuizState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(QuizActions.setDeleteQuizSuccess, (state: QuizState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(QuizActions.setDeleteQuizError, (state: QuizState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
	on(QuizActions.setCachedPages, (state: QuizState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(QuizActions.resetCache, (state: QuizState) => ({
		...state,
		cachedPages: [],
    status: STATE_STATUS.READY,
		pages: [0],
		data: { totalQuizCount: 0, quiz: [] },
	}))
);

export const QuizReducer = (state: QuizState | undefined, action: Action) => {
	return reducers(state, action);
};
