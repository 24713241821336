import { ObjectValuesType } from "addiction-components";

export const FlowNodeStatus = {
	START_STATE: 'startState',
	ACTION_REQUIRED: 'actionRequired',
	TRIGGER_EVENT: 'triggerEvent',
	FINAL_STATE: 'finalState',
  END_STATE: 'endState',
} as const;

export type FlowNodeStatus = ObjectValuesType<typeof FlowNodeStatus>;
