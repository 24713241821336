<label *ngIf="label">
	{{ label }} <span class="required" *ngIf="requiredIcon">*</span>
	<span *ngIf="hint" class="tooltip" [matTooltip]="hint" [matTooltipPosition]="'right'"> </span>
</label>
<div class="chips-container" [ngClass]="{ readonly: readonly, disabled: disabled, 'height-auto': heightAuto }">
	<ng-container *ngIf="!(skeletonLoaderChipsCounter | async); else loader">
		<div class="chips-list">
			<div class="chip" *ngFor="let item of items | async">
				<addiction-chip [label]="item.name" (remove)="removeItem(item)" [removable]="!disabled && !readonly"></addiction-chip>
			</div>
		</div>
		<button class="add" type="button" (click)="add()" *ngIf="!disabled && !readonly && showAddButton"></button>
	</ng-container>

	<ng-template #loader>
		<div class="chips-list">
			<div class="chip skeleton" *dataleanRepeat="skeletonLoaderChipsCounter | async">
				<addiction-chip [removable]="false"></addiction-chip>
			</div>
		</div>
		<button class="add" type="button" [disabled]="true"></button>
	</ng-template>
</div>

<ng-template #startDate let-item>
	<span>{{ item.startDate | date : 'dd-MM-yyyy' }}</span>
</ng-template>

<ng-template #endDate let-item>
	<span>{{ item.endDate | date : 'dd-MM-yyyy' }}</span>
</ng-template>
