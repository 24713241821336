import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, retry, throwError } from 'rxjs';
import { DataleanHTTPError } from '../../shared/models/datalean-error-interface';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor() {}
	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(req).pipe(
			catchError((resp: { error?: { Errors?: unknown }; status: number }) => {
				let errorCodes: string[] = [];
				if (resp && resp.error && resp.error.Errors) {
					const errorArray = resp.error.Errors;
					if (Array.isArray(errorArray)) {
						errorCodes = errorArray.filter((error) => !!error.message).map((error) => error.message);
					}
				}

				return throwError(() => new DataleanHTTPError(errorCodes, resp, resp.status));
			}),
			retry({
				/**
				 * The maximum number of times to retry. If `count` is omitted, `retry` will try to
				 * resubscribe on errors infinite number of times.
				 */
				count: 2,
				/**
				 * The number of milliseconds to delay before retrying, OR a function to
				 * return a notifier for delaying. If a function is given, that function should
				 * return a notifier that, when it emits will retry the source. If the notifier
				 * completes _without_ emitting, the resulting observable will complete without error,
				 * if the notifier errors, the error will be pushed to the result.
				 */
				delay: (error: HttpErrorResponse, retryCount: number) => {
					// console.log('retryCount', retryCount);
					// console.log('error', error);
					return retryCount < 2 && [0, 429].includes(error.status) ? of(true) : throwError(() => error);
				},
				/**
				 * Whether or not to reset the retry counter when the retried subscription
				 * emits its first value.
				 */
				resetOnSuccess: true,
			})
		);
	}
}
