<div class="header-left-container">
	<div class="header-title-container">
		<!-- products-menu-button e products-menu nascosti da SCSS temporaneamente -->
		<!-- TODO FUTURO - Mettere nel button la funzionalità per aprire il popup .products-menu aggiungendo .active sia a .products-menu-button che a .products-menu -->
		<button class="products-menu-button"></button>
		<div class="products-menu">
			<span class="text">{{ 'CHANGE_ADDICTION_PRODUCT_POPUP.CHANGE' | translate }}</span>
			<!-- TODO DEVS - Prendere dati e link dal posto giusto -->
			<div class="single-product-item">
				<a href="#" target="_blank">
					<img src="/assets/images/logo/datalean-logo.png" />
				</a>
			</div>
			<div class="single-product-item">
				<a href="#" target="_blank">
					<img src="/assets/images/logo/datalean-dam-logo.png" />
				</a>
			</div>
			<div class="single-product-item">
				<a href="#" target="_blank">
					<img src="/assets/images/logo/smartco-cp-logo.png" />
				</a>
			</div>
		</div>

		<img [src]="headerLogo" alt="Logo DataLean DAM" class="logo" routerLink="" />
	</div>

	<ng-container *ngrxLet="{ showBackButton: showBackButton$, showWarning: showBackButtonWarning$ } as backConfig">
		<button *ngIf="backConfig.showBackButton" type="button" class="back-button" (click)="goBack(backConfig.showWarning)"></button>
	</ng-container>

	<datalean-language-selector
		*ngIf="showLocales$ | async"
		[availableLocales]="hs.allLocales$ | async"
		[compiledLocales]="hs.selectedLocales$ | async"
		[selectedLocale]="hs.activeLocale$ | async"
		[showPlusButton]="showPlusButton$ | async"
		[showRemoveButton]="showRemoveButton$ | async"
		(addedLocale)="changeLocale($event)"
		(clickedLocale)="changeLocale($event)"
		(removedLocale)="removeLocale($event)"></datalean-language-selector>

	<!-- Community nascoste da SCSS temporaneamente -->
	<ng-container *ngIf="showCommunitySelect$ | async">
		<div class="community" *ngIf="communityData$ | async as communities; else loader">
			<label>{{ 'COMMUNITIES.COMMUNITY' | translate }}</label>
			<mat-select [value]="lastCommunitySelected$ | async" class="community-select" (selectionChange)="communitySelectChange($event)">
				<mat-option [value]="'all'" *ngIf="showNoCommunityChoice$ | async">{{ 'COMMUNITIES.NO_COMMUNITY_FILTER' | translate }}</mat-option>
				<mat-option *ngFor="let community of communities" [value]="community.uuid">{{ community.name }} </mat-option>
			</mat-select>
		</div>

		<ng-template #loader>
			<div class="community loader">
				<label>{{ 'COMMUNITIES.COMMUNITY' | translate }}</label>
				<span class="skeleton"></span>
			</div>
		</ng-template>
	</ng-container>
</div>

<div class="header-right-container">
	<ng-container *ngIf="showUserMenuButton">
		<div class="user-menu-container" *ngrxLet="userData$ as user" #container>
			<div class="user-menu" *ngIf="user" [matMenuTriggerFor]="menu" #userMenu="matMenuTrigger" [ngClass]="{ active: userMenu.menuOpen }">
				<div class="user-menu-text">
					<span class="welcome">{{ 'GREETING' | translate }}</span>
					<span class="name" *ngIf="user.firstName.length">{{ user.firstName }}</span>
				</div>
				<!-- TODO DEVS - Manca gestione immagine e se non c'è iniziale come ora -->
				<div class="user-menu-image" *ngIf="user.firstName.length">
					<span>{{ user.firstName[0] }}</span>
				</div>
				<span class="user-menu-arrow"></span>
			</div>

			<mat-menu #menu="matMenu">
				<button class="profile" mat-menu-item (click)="goToUserProfile()" [ngStyle]="{ width: container.clientWidth + 'px' }">
					<span>{{ 'HEADER.USER_PROFILE' | translate }}</span>
				</button>
				<button class="logout" mat-menu-item (click)="logout()">
					<span>{{ 'HEADER.LOGOUT' | translate }}</span>
				</button>
			</mat-menu>
		</div>
	</ng-container>
</div>
