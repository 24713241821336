import { Inject, Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { EnvironmentConfiguration, Parts, StructureType, UrlBuilder, UserStructure } from '../models';
import { DataleanBaseApiService } from './datalean-base-api.service';
@Injectable()
export class RegistrationService {
  constructor(@Inject('env') private environmentSettings: EnvironmentConfiguration, private dataleanService: DataleanBaseApiService) {}

  register(userData: unknown) {
    const requestUrl = new UrlBuilder(this.environmentSettings.registrationUrl).build();
    return this.dataleanService.createEntity(requestUrl, userData, []);
  }

  getUserStructure(): Observable<UserStructure | undefined> {
    if (this.environmentSettings?.userStructureUrl)
      return this.dataleanService
        .getEntities<UserStructure>(
          this.environmentSettings.userStructureUrl + this.environmentSettings.userStructureUUID ?? '',
          { type: StructureType.USER },
          [Parts.STRUCTURE_FIELDS]
        )
								//la variabile d'ambiente userStructureUurl potrebbe far riferimento all'endpoint senza auth
								//il quale non ritorna al momento l'uuid della struttura
        .pipe(map((structure) => ({ ...structure, uuid: structure.uuid ?? this.environmentSettings.userStructureUUID })));
    return of(undefined);
  }
}
