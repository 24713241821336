import { NavigationStart, Router } from '@angular/router';
import { combineLatestWith, filter, first } from 'rxjs';
import { AuthService } from './auth.service';

export const authServiceInit = (authService: AuthService, router: Router) => {
	// init operazioni preliminari
	return () => {
		/**
		 * Setta lo stato dell'auth service in base all'url in cui ci si trova
		 * non so perchè ma non funziona se ritorni un observable
		 * in ogni caso si attiva solo una volta quindi va bene
		 */
		router.events
			.pipe(
				filter((event): event is NavigationStart => event instanceof NavigationStart),
				first(),
				combineLatestWith(authService.isAuthenticated$),
			)
			.subscribe(([event, isAuthenticated]) => {
				const url = router.url !== '/' ? router.url : event.url;
				if (!isAuthenticated) {
					authService.setState(url);
				}
			});
		return authService.runInitialLoginSequence();
	};
};
