import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, SkipSelf, forwardRef } from '@angular/core';
import {
	AbstractControl,
	ControlContainer,
	ControlValueAccessor,
	FormControl,
	FormGroupDirective,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	UntypedFormArray,
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	Validator,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { v4 } from 'uuid';
import { FeatureValueType, StructureField, ExtraStructureFieldType, StructureFieldType } from '../../models';
import { SharedModule } from '../../modules/shared.module';
import { ToLocalizedValuePipe } from '../../pipes';
import { CheckboxPrivacyComponent } from '../checkbox-privacy/checkbox-privacy.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { EmailComponent } from '../email/email.component';
import { HtmlEditorComponent } from '../html-editor/html-editor.component';
import { InputComponent } from '../input/input.component';
import { PhoneComponent } from '../phone/phone.component';
import { RadioFieldComponent } from '../radio-field/radio-field.component';
import { SelectFieldComponent } from '../select-field/select-field.component';
import { TextareaComponent } from '../textarea/textarea.component';

@Component({
	selector: 'addiction-container',
	standalone: true,
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.scss'],
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		TranslateModule,
		MatSlideToggleModule,
		CdkDropList,
		CdkDrag,
		HtmlEditorComponent,
		TextareaComponent,
		CheckboxComponent,
		RadioFieldComponent,
		PhoneComponent,
		EmailComponent,
		DatePickerComponent,
		SelectFieldComponent,
		CheckboxPrivacyComponent,
		InputComponent,
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ContainerComponent),
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => ContainerComponent),
		},
	],
})
export class ContainerComponent<T = unknown> implements OnInit, OnDestroy, ControlValueAccessor, Validator {
	readonly FeatureValueType = FeatureValueType;

	@Input() dataset?: T;
	@Input({
		transform: (sfs: StructureField[]) => {
			return sfs.map((sf) => {
				//non mi interessa andare in profondità perchè se fosse un container verrebbe istanziato un altro ContainerComponent al livello inferiore
				if ([StructureFieldType.SELECT, ExtraStructureFieldType.PRIVACY].some((t) => t === sf.type) && typeof sf.extra === 'string') {
					try {
						sf.parsedExtra = JSON.parse(sf.extra);
					} catch (e) {}
				}
				return sf;
			});
		},
	})
	structureFields: StructureField[] | undefined;
	@Input() depth = 0;
	@Input() readonly = false;
	@Input() showInlineError = false;

	types = { ...StructureFieldType, ...ExtraStructureFieldType };
	formGroup = new UntypedFormGroup({});
	onDestroy = new Subject<boolean>();

	private structureMap: Record<string, { initialStructure: StructureField; releatedStructure?: StructureField }> = {};

	constructor(
		@SkipSelf() private group: ControlContainer,
		private localizePipe: ToLocalizedValuePipe,
	) {}

	ngOnInit(): void {
		//console.log('ContainerComponent constructor', this.group);
		if (this.group instanceof FormGroupDirective) {
			this.formGroup = this.group.form;
		} else {
			// console.log('ContainerComponent ngOnInit', this.group.valid);
		}

		// console.log('ContainerComponent ngOnInit', this.structureFields);
		// console.log('ContainerComponent ngOnInit', this.dataset);
		if (this.structureFields) {
			for (const sf of this.structureFields) {
				//se è presente il parametro structureConnected significa che c'è una struttura correlata
				if (sf.structureConnected) {
					//trovo la structureField a cui è collegata quella che sto ciclando,
					//creo una mappa che le contenga entrambe
					this.structureMap[sf.name] = {
						initialStructure: sf,
						releatedStructure: this.structureFields.find((field) => field.uuid === sf.structureConnected),
					};
				}

				if (sf.type === ExtraStructureFieldType.SINGLE_RELATED_ENTITY) {
					// TODO Rimuovere questo a regime, dovrebbe essere una sottostruttura o extra
					// e questo componente non dovrebbe occuparsene ma dovrebbe esserci la specializzazione per i corsi
					this.formGroup.addControl('uuid', new UntypedFormControl(v4(), Validators.required));
					this.formGroup.addControl('name', new UntypedFormControl(null, Validators.required));
					this.formGroup.addControl('active', new UntypedFormControl(true, Validators.required));
					this.formGroup.addControl('linkedLesson', new UntypedFormControl(null, Validators.required));
					this.formGroup.addControl('blockedBy', new UntypedFormControl());
				}

				if (this.formGroup.get(sf.name) === null) {
					const validators: ValidatorFn[] = [];
					if (sf.isRequired) {
						if (sf.type === ExtraStructureFieldType.PRIVACY || sf.type === StructureFieldType.BOOLEAN) {
							validators.push(Validators.requiredTrue);
						} else {
							validators.push(Validators.required);
						}
					}
					if (sf.repeatable) {
						let def: FormControl[] = [];
						// console.log('sf', this.dataset);
						if (this.dataset && typeof this.dataset === 'object' && sf.name in this.dataset)
							def = (this.dataset[sf.name as keyof T] as unknown[] | undefined)?.map((data: unknown) => new FormControl(data)) ?? [];

						validators.push(Validators.minLength(1));
						this.formGroup.addControl(sf.name, new UntypedFormArray(def, validators));
					} else if (sf.type === StructureFieldType.CONTAINER) {
						this.formGroup.addControl(sf.name, new UntypedFormGroup({}, validators));
					} else {
						let predefinedValue = this.localizePipe.transform(sf.predefinedValue);
						if (predefinedValue) {
							if (sf.type === StructureFieldType.TOGGLE || sf.type === StructureFieldType.RADIO || sf.type === StructureFieldType.BOOLEAN) {
								predefinedValue = predefinedValue === 'true';
							}
						}
						this.formGroup.addControl(sf.name, new UntypedFormControl(predefinedValue, validators));
					}
				}

				// if (sf.rolesCantSee?.length || sf.rolesReadonly?.length) {
				// 	// console.log('sf', sf);
				// 	const hidden = this.userService.authenticatedUser?.roles.some((role) => {
				// 		// console.log('role', role);
				// 		return sf.rolesCantSee?.some((r) => r.uuid === role.uuid);
				// 	});
				// 	const readonly = this.userService.authenticatedUser?.roles.some((role) => {
				// 		return sf.rolesReadonly?.some((r) => r.uuid === role.uuid);
				// 	});

				// 	// console.log('hidden', hidden);

				// 	if (hidden) {
				// 		this.formGroup.removeControl(sf.name);
				// 	} else if (readonly) {
				// 		const control = this.formGroup.get(sf.name);
				// 		if (control) {
				// 			control.disable();
				// 		}
				// 	}
				// }

				if (sf.readOnly) {
					this.readonly = true;
				}
			}
		}
		// console.log('ContainerComponent ngOnInit', this.formGroup.valid);

		if (this.readonly) this.formGroup.disable();

		if (this.dataset) {
			this.formGroup.patchValue(this.dataset);
		}

		// this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((newStructure) => {
		// 	if (newStructure) {
		// 		for (const initialField of Object.keys(this.structureMap)) {
		// 			const structure = this.structureMap[initialField];
		// 			if (newStructure[initialField] && structure.releatedStructure && structure.initialStructure.type === StructureFieldType.SELECT) {
		// 				this.updateFormatAndInputRequired(newStructure[initialField], structure.releatedStructure);
		// 			}
		// 		}
		// 	}
		// console.log('ContainerComponent ngOnInit - ', this.depth, this.formGroup.errors);
		// });
		this.formGroup.updateValueAndValidity({ onlySelf: true });
	}
	//TODO: da implementare i campi allegati

	// private updateFormatAndInputRequired(selectedType: string, structureRelated: StructureField): void {
	// 	if (selectedType && structureRelated) {
	// 		const type = selectedType.toLowerCase();
	// 		let format = '';
	// 		let inputRequired = '';

	// 		if (type === ContentType.VIDEO.toLowerCase()) {
	// 			format = 'mp4,mov,wmv,avi,flav';
	// 			inputRequired = 'video';
	// 		} else if (type === ContentType.DOCUMENT.toLowerCase()) {
	// 			format = '';
	// 			inputRequired = '';
	// 		}
	// 		structureRelated.inputTypeRequired = inputRequired;
	// 		structureRelated.formatRequired = format;
	// 		structureRelated.parentValue = selectedType;
	// 	}

	// 	if (structureRelated) {
	// 		this.structureFields = this.structureFields?.map((x) => {
	// 			if (structureRelated.uuid === x.uuid) {
	// 				return structuredClone(structureRelated);
	// 			}
	// 			return x;
	// 		});
	// 	}
	// }

	public onTouched: () => void = () => {};

	onValidatorChange = () => {
		// console.log('onValidatorChange', this.formGroup);
	};

	registerOnValidatorChange(fn: () => void): void {
		// console.log('registerOnValidatorChange', fn);
		this.formGroup.updateValueAndValidity();

		// console.log('registerOnValidatorChange', this.group);
		if (this.group.control) this.validate(this.group.control);
	}

	validate(c: AbstractControl): ValidationErrors | null {
		/**
		 * Perchè serve questo if?
		 * Praticamente quando andiamo a lavorare con dei ripetibili
		 * può capitare che il container sia valido ma i suoi figli no
		 * questo perchè il container ripetibile è un formArray di formControl
		 * e quindi non è possibile andare a validare il formArray
		 */
		// console.log('onValidatorChange', this.formGroup);

		if (c.valid && this.formGroup.invalid) {
			c.setErrors({ ...this.formGroup.errors, [this.depth]: true });
			c.markAsDirty();
		} else if (c.invalid && this.formGroup.valid) {
			const errors = c.errors;
			if (errors) delete errors[this.depth];
			c.setErrors(errors);
			c.markAsPristine();
		} else if (c.invalid && this.formGroup.invalid && !c.errors) {
			c.setErrors({ ...this.formGroup.errors, [this.depth]: true });
			c.markAsDirty();
		}
		return c.invalid || this.formGroup.invalid ? { ...c.errors, ...this.formGroup.errors } : null;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	writeValue(obj: Record<string, unknown>): void {
		// console.log('ContainerComponent writeValue', obj);
		this.formGroup.patchValue(obj);
		// if (obj) this.formGroup.setValue(obj);
	}

	registerOnChange(fn: (val: T) => void): void {
		if (!(this.group instanceof FormGroupDirective)) {
			this.formGroup.valueChanges.subscribe(fn);
		}
	}

	setDisabledState(disabled: boolean): void {
		if (disabled) this.formGroup.disable({ emitEvent: false });
		else this.formGroup.enable({ emitEvent: false });
	}

	drop(event: CdkDragDrop<UntypedFormArray | undefined>) {
		const control = Object.values(this.formGroup?.controls ?? {})[0] as UntypedFormArray;
		const el = control.at(event.previousIndex);
		control.removeAt(event.previousIndex);
		control.insert(event.currentIndex, el);
	}

	addChild(field: StructureField): void {
		const control = this.formGroup.controls[field.name] as UntypedFormArray;
		control.push(new FormControl());
		// console.log(control, 'control');
		// this.formGroup.patchValue(this.control?.value);
		this.formGroup.updateValueAndValidity();
	}

	removeChild(field: StructureField, index: number): void {
		const control = this.formGroup?.controls[field.name] as UntypedFormArray;
		control.removeAt(index);
	}

	ngOnDestroy(): void {
		this.onDestroy.next(true);
	}

	getUntypedFormControl(control?: AbstractControl | null) {
		return control as UntypedFormControl;
	}

	getUntypedFormGroup(control?: AbstractControl | null) {
		return control as UntypedFormGroup;
	}

	getFormArray(field: StructureField): UntypedFormArray | undefined {
		return this.formGroup.controls[field.name] as UntypedFormArray;
	}

	getFormGroup(field: StructureField): UntypedFormGroup {
		return this.formGroup.controls[field.name] as UntypedFormGroup;
	}

	getFormControl(field: StructureField): UntypedFormControl {
		return this.formGroup.controls[field.name] as UntypedFormControl;
	}

	getClass(formKey: string) {
		if (this.structureFields) {
			const ratio = this.structureFields.find((sf) => sf.name === formKey)?.desktopRatio;
			if (ratio !== undefined) {
				return `ratio-${ratio}`;
			}
		}
		return 'ratio-100';
	}

	// getExtra<T = undefined>(field: StructureField, name: string): T | undefined {
	// 	if (!field.parsedExtra) return undefined;
	// 	if (Array.isArray(field.parsedExtra)) return field.parsedExtra.find((extra) => extra.label === name)?.value as T | undefined;
	// 	return field.parsedExtra?.label[name] as T | undefined;
	// }

	getSubDataset<U>(name: string, index?: number): U | undefined {
		const dataset = this.dataset?.[name as keyof T];
		if (dataset && index !== undefined) {
			return (dataset as U[])[index];
		}
		return dataset as U;
	}

	// isDisabledAddChild(field: StructureField): boolean {
	// 	const isContentList = field.type === StructureFieldType.CONTAINER && field.name === 'contentList';
	// 	if (!isContentList) return false;
	// 	const control = this.formGroup.controls[field.name] as UntypedFormArray;
	// 	const lastControl = control.at(control.length - 1);
	// 	return lastControl?.value?.type === ContentType.QUIZ;
	// }
}
