import { Clipboard } from '@angular/cdk/clipboard';
import { Directive, HostListener, inject, Input } from '@angular/core';
@Directive({
	selector: 'addiction-input[copyInput]',
	standalone: true,
})
export class CopyInputDirective {
	private clipboard = inject(Clipboard);
	@Input() inputValue: string = '';

	@HostListener('click', ['$event']) hostClick(event: Event) {
		/**
		 * Controllo per verificare che il click sia stato fatto sull'icona di copia e non sull'input.
		 */
		if((event.target as HTMLElement).tagName !== 'MAT-ICON') return;
		

		this.clipboard.copy(this.inputValue);
		event.stopPropagation();
	}
}
