import { DataleanEntity, UnknownObject, ObjectValuesType } from 'addiction-components';
import { FlowNodeStatus } from './flowNodeStatus';
import { FlowNodeType } from './flowNodeType';
import { TabOption } from './tab-options';
import { FormControl } from '@angular/forms';

export class WorkFlowConfig extends DataleanEntity {
	constructor(
		public structuresUUID: Array<string> | undefined = undefined,
		public type: FlowNodeType = FlowNodeType.PRODUCT,
		public allowedRoles: string[] = [],
		public parentUUID: string[] | undefined = undefined,
		public approvalUUID: string[] | undefined = undefined,
		public rejectUUID: string[] | undefined = undefined,
		public version: number | undefined = undefined,
		public nodeStatus: FlowNodeStatus = FlowNodeStatus.START_STATE,
	) {
		super();
	}
}

export class WorkFlowEntity extends WorkFlowConfig {
	override active = true;
	// questo flag serve per sapere se lo step è stato superato
	completed = false;
	editable = false;

	constructor(
		public entityUUID: string,
		public workflowUUID: string,
		public approvedBy: string[] = [],
		public rejectedBy: string[] = [],
	) {
		super();
	}
}

export class WorkFlowList extends DataleanEntity {
	userName?: string;
	name?: string;
	type?: string;
	version?: number;
	structure?: string;

	constructor() {
		super();
	}
}

export interface WorkflowProductList {
	uuid: string;
	name: string;
	version: number;
	updateDate?: string;
	[key: string]: unknown | UnknownObject;
}

export const WORKFLOW_ROLE = {
	APPROVER: 'APPROVER',
	EDITOR: 'EDITOR',
} as const;
export type WorkflowRole = ObjectValuesType<typeof WORKFLOW_ROLE>;

export const TASKS_TYPE = {
	ASSIGNED_TASKS: 'assigned-tasks',
	TASKS_TO_APPROVE: 'tasks-to-approve',
} as const;
export type TasksType = ObjectValuesType<typeof TASKS_TYPE>;

export const TasksTabs: TabOption[] = [
	{
		uuid: TASKS_TYPE.ASSIGNED_TASKS,
		name: 'Assegnati a Me',
	},
	{
		uuid: TASKS_TYPE.TASKS_TO_APPROVE,
		name: 'In Approvazione',
	},
];

export interface TaksFiltersForm {
	name?: FormControl<string>;
	type?: FormControl<string>;
	structure?: FormControl<string>;
	dateFrom?: FormControl<Date>;
	dateTo?: FormControl<Date>;
}
