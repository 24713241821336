import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WorkFlowReducers } from '../../../core/state/app.reducers';

const selectWorkFlowState = createFeatureSelector<WorkFlowReducers.WorkFlowState>(WorkFlowReducers.TAG);

export const selectWorkFlowConfigurations = createSelector(selectWorkFlowState, (state) => state?.workFlowConfig);
export const selectWorkFlowInstance = createSelector(selectWorkFlowState, (state) => state?.workFlowInstance);
export const selectWorkFlowsFilters = createSelector(selectWorkFlowState, (state) => state?.filters);
export const selectWorkFlowsStructures = createSelector(selectWorkFlowState, (state) => state?.structures);

export const selectData = createSelector(selectWorkFlowState, (state) => state.data);
export const selectPages = createSelector(selectWorkFlowState, (state) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectStatus = createSelector(selectWorkFlowState, (state) => state.status);
export const selectSort = createSelector(selectWorkFlowState, (state) => state.sort);
export const selectGridSearch = createSelector(selectWorkFlowState, (state) => state.gridSearch);
export const selectCachedPages = createSelector(selectWorkFlowState, (state) => state.cachedPages);
export const selectWorkFlowType = createSelector(selectWorkFlowState, (state) => state.workFlowType);
export const selectWorkFlowTasksTabs = createSelector(selectWorkFlowState, (state) => state.tasksTabs);
export const selectDraftProduct = createSelector(selectWorkFlowState, (state) => state.draftProduct);
