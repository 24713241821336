import { EntityStatuses, FeatureValue } from 'addiction-components';
import { UnknownObject } from './unknown-object.interface';
import { v4 } from 'uuid';

export class Product {
	uuid: string = v4();
	order: number = 0;
	locale?: string = '';
	active: boolean = true;
	structure?: { name: string }[] = [];
	featureValueList: Array<FeatureValue | string> = [];
	related?: Array<Product> = [];
	entityVersion?: number;
	localizedValues?: { locale: string; [key: string]: unknown }[] = [];
	updateDate?: string = '';
  workflowStep?: string[];
	[key: string]: unknown | UnknownObject;

	constructor(public structureUUID: string = '', public name: string = '', public status: string = EntityStatuses.DRAFT) {}
}

export type ProductUpdate = Omit<Product, 'featureValueList' | 'related'> & {
	uuid?: string;
	featureValueList: Array<string>;
	related?: Array<string>;
	localizedValues: { locale: string; [key: string]: unknown }[];
};
