import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
	BaseModalContent,
	DataleanBaseApiService,
	DataleanTableConfiguration,
	Page,
	PageChangeEvent,
	PaginationInfo,
	Parts,
	SearchInfo,
	SimpleObject,
	SortInfo,
	SortObject,
	TableRow,
} from 'addiction-components';
import { BehaviorSubject, combineLatest, filter, map, shareReplay, switchMap, tap } from 'rxjs';
import { isPage } from '../../helpers';

@Component({
	selector: 'datalean-entity-selector-dialog',
	templateUrl: './entity-selector-dialog.component.html',
	styleUrls: ['./entity-selector-dialog.component.scss'],
	standalone: true,
	imports: [CommonModule, TranslateModule],
})
export class EntitySelectorDialogComponent<T = { uuids: string[] }> extends BaseModalContent<T> {
	@Input() selectedUUIDs: string[] | undefined = undefined;
	@Input() unselectedUUIDs: string[] | undefined = undefined;
	/**
	 * Attributo usato per usare la modale in logica contraria all'ordinario, per cui, se non ci sono valori preselezionati negativeReflection è true
	 * sono tutti selezionati.
	 */
	@Input() negativeReflection: boolean = false;
	@Input() endpoint: string = '';
	@Input() searchFields: string = '';
	@Input() searchParamName: string = '';
	@Input() fieldsToShow: string[] = [];
	@Input() filters: SimpleObject = {};

	dataleanService = inject(DataleanBaseApiService);

	pageSize: number = 15;
	loading: boolean = false;
	_currentPageUUIDs: string[] = [];

	// FILTERS
	currentPage$ = new BehaviorSubject<PaginationInfo>(new PaginationInfo(this.pageSize, 0));
	search$ = new BehaviorSubject<string | undefined>(undefined);
	sort$ = new BehaviorSubject<SortInfo | undefined>(undefined);

	fetchParams$ = combineLatest([this.currentPage$, this.search$, this.sort$]);

	pageSettings$ = new BehaviorSubject<Page>(new Page(5, undefined, undefined, 0));
	switchTab$ = new BehaviorSubject(undefined);

	tableRows$ = combineLatest([this.switchTab$, this.fetchParams$]).pipe(
		map(([, fetchParams]) => fetchParams),
		tap(() => (this.loading = true)),
		filter(() => !!this.endpoint),
		// recupera i dati dal server
		switchMap(([paging, search, sort]) =>
			this.dataleanService.getManyPaged(this.endpoint, [Parts.EMPTY], {
				pagination: paging,
				sort,
				search: new SearchInfo(this.searchFields, search, undefined, this.searchParamName),
				additionalParams: this.filters,
			})
		),
		// notifica cambiamento pagina alla tabella
		tap(({ paginationInfo }) => this.changePage(paginationInfo)),
		map(({ result }) => this.mapData(result)),
		tap((rows) => {
			this.loading = false;
			this._currentPageUUIDs = rows.map((r) => r['uuid'] as string);
			if (!this.selectedUUIDs?.length && this.negativeReflection) this.selectedUUIDs = this._currentPageUUIDs;
			if (this.unselectedUUIDs?.length && this.selectedUUIDs?.length) {
				this.selectedUUIDs = this.selectedUUIDs.filter((uid) => !this.unselectedUUIDs?.includes(uid));
			}
		}),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	protected updateSelected$ = new BehaviorSubject<boolean>(true);

	protected updateSelectedObs$ = this.updateSelected$.asObservable();

	selectedRows$ = combineLatest([this.tableRows$, this.updateSelectedObs$]).pipe(
		map(([rows]) => rows),
		map((rows) => rows.filter((row) => this.selectedUUIDs?.includes(row['uuid'] as string)))
	);

	baseTableConfig: DataleanTableConfiguration<TableRow> = {
		columns: [
			{
				columnDef: 'name',
				header: 'NAME',
				cell: (element: TableRow) => `${element['name']}`,
			},
		],
		key: 'uuid',
		filters: true,
		filtersConfig: [],
		displayCheck: () => true,
		selectable: true,
	};

	constructor() {
		super();
	}

	changePage(pagInfo: PaginationInfo | Page) {
		if (isPage(pagInfo)) pagInfo = new PaginationInfo(pagInfo.pageSize, pagInfo.pageNumber, pagInfo.totalElements);

		const totalPages =
			pagInfo.totalNumberOfElements && pagInfo.numberOfElementsOnPage ? Math.ceil(pagInfo.totalNumberOfElements / pagInfo.numberOfElementsOnPage) : 0;
		this.pageSettings$.next(new Page(pagInfo.numberOfElementsOnPage, pagInfo.totalNumberOfElements, totalPages, pagInfo.numberOfPage));
	}

	mapData(data: unknown[]): TableRow[] {
		// console.log('data', data);
		return data.map((dataObj) => ({
			uuid: (dataObj as Record<string, unknown>)['uuid'] as string,
			name: this.fieldsToShow.reduce((prevValue, curValue) => prevValue + (dataObj as Record<string, unknown>)[curValue] + ' ', ''),
		}));
	}

	selectionChanged(selectedRows: TableRow[]) {
		const pageSelection = selectedRows.map((row) => row['uuid'] as string);
		// console.log('pageSelection', pageSelection);

		this.unselectedUUIDs = this.selectedUUIDs?.filter((uuid) => !pageSelection.includes(uuid));
		this.selectedUUIDs = pageSelection;
		this.updateSelected$.next(true);
	}

	pageChanged(pageInfo: PageChangeEvent) {
		if (pageInfo.count != this.pageSettings$.value.pageSize && pageInfo.offset != this.pageSettings$.value.pageNumber) {
			this.currentPage$.next(new PaginationInfo(pageInfo.pageSize, pageInfo.offset, pageInfo.count));
		}
	}

	sort(sortInfo: SortObject) {
		this.sort$.next(new SortInfo(sortInfo.prop, sortInfo.dir));
	}

	close() {
		this.closeDialog({ uuids: this.selectedUUIDs ?? [] } as T);
	}

	filterBy(name: string | null) {
		this.search$.next(name ?? undefined);
	}
}
