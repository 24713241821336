import { Injectable } from '@angular/core';
import { Limit, SortedCheckboxes } from '../models/checkbox-multiple.interface';
import { LimitType } from '../models/checkbox-multiple.enum';

/**
 * Utility che permette di gestire multiple checkbox
 * utilizza la modalità di 'filtri' per capire quali checkbox sono state
 * selezionate, questo perchè in pagine con infiniteScroll se si vuole selezionare
 * tutto non è possibile indicare gli elementi selezionati in un altro modo (poichè non conosciamo
 * tutti gli elementi che sono stati selezionati)
 *
 * il filtro può essere inclusivo o esclusivo es.
 * checboxMap = {ID} & modalità inclusiva ==> ho selezionato solo ID
 * checboxMap = {ID} & modalità esclusiva ==> ho selezionato tutte le checkbox ad esclusione della checkbox ID
 *
 *
 * NB. occorre settare il numero totale di items per far funzionare correttamente il servizio
 * ossia settare la variabile 'totalItems'
 */

@Injectable({ providedIn: 'root' })
export class CheckboxMultipleService {
	private _checkboxMap: Record<string, boolean> = {}; //ci salvo gli uuid
	private _checkboxMapWithValue: Record<string, string> = {}; //ci salvo gli uuid come chiave e come valore una stringa
	currentPageItems = new Array<string>(); //ci salvo gli uuid della pagina corrente
	selectAllChecked: boolean = false; //modalità: se true => 'exclusive', se false => 'inclusive'
	selectAllIndeterminate: boolean = false;
	//necessario settare questo valore per far funzionare correttamente il servizio
	totalItems: number = 0;
	/**
	 * Oggetto per definire il limite massimo di checkbox selezionabili contemporaneamente
	 * e il tipo di limite (blocco selezione o rimozione valori più vecchi)
	 */
	public limit?: Limit;

	/**
	 * Array per tenere in memoria le selezioni ordinate temporalmente in caso in cui il limit type sia REMOVE
	 */
	private _sortedCheckboxes: SortedCheckboxes[] = [];

	get totalSelected() {
		return this.selectAllChecked ? this.totalItems - this.getUuidArray().length : this.getUuidArray().length;
	}

	//#region Checkbox Map Getter e Setter
	public setCheckboxMap(uuid: string, value: boolean): void {
		/**
		 * Se non c'è limite o la checkbox viene deselezionata setto direttamente il valore senza ulteriori controlli
		 */
		if (!this.limit || !value) {
			this._checkboxMap[uuid] = value;
			return;
		}

		/**
		 * Se il limite è di tipo Block e il numero di checkbox selezionate è superiore al limite massimo non setto il valore nel record
		 */
		if (this.limit.type == LimitType.BLOCK && this.numberOfCheckboxMapSelected() >= this.limit.value) return;

		/**
		 * Se il limite è di tipo Remove
		 * Se il numero di checkbox selezionate è superiore al limite massimo viene deselezionata la checkbox selezionata prima
		 * Viene aggiunto l'elemento nel record sortedCheckboxes per memorizzare le selezioni ordinate temporalmente
		 */
		if (this.limit.type == LimitType.REMOVE) {
			if (this._sortedCheckboxes.length >= this.limit.value) this.deselectOldestSelected();

			this.addsSortedCheckbox(uuid, this.getSortedCheckboxLastSequenceNumber() + 1);
		}

		this._checkboxMap[uuid] = value;
	}

	public get checkboxMap(): Readonly<Record<string, boolean>> {
		return this._checkboxMap;
	}

	//#endregion

	//#region CheckboxMapWithValue Getter e Setter
	public setCheckboxMapWithValue(uuid: string, value: string): void {
		this._checkboxMapWithValue[uuid] = value;
	}

	public get checkboxMapWithValue(): Readonly<Record<string, string>> {
		return this._checkboxMapWithValue;
	}
	//#endregion

	updateSelectAllStatus() {
		// console.log('updateSelectAllStatus', this.totalItems);
		if (this.totalItems === 0) {
			throw new Error('totalItems must be set correctly');
		}

		if (this.selectAllChecked) {
			//caso esclusivo
			const falseValues = Object.entries(this._checkboxMap)
				.filter(([uuid, v]) => this.currentPageItems.includes(uuid) && !v)
				.map(([uuid]) => uuid);

			this.selectAllIndeterminate = !!falseValues.length;

			if (falseValues.length === 0) {
				this.setAll(true);
			} else if (falseValues.length === this.totalItems) {
				this.setAll(false);
			}
		} else {
			//Caso inclusivo
			const trueValues = Object.entries(this._checkboxMap)
				.filter(([uuid, v]) => this.currentPageItems.includes(uuid) && v)
				.map(([uuid]) => uuid);

			this.selectAllIndeterminate = !!trueValues.length;
			// console.log('trueValues', trueValues.length === this.totalItems);

			if (trueValues.length === this.totalItems) {
				this.setAll(true);
			} else {
				// console.log('trueValues', trueValues);
				this.selectAllIndeterminate = !!trueValues.length;
				if (trueValues.length === 0) {
					this.setAll(false);
				}
			}
		}
	}

	setAll(value: boolean, dataset?: string[]) {
		this.selectAllChecked = value;
		this.selectAllIndeterminate = false;
		if (dataset) {
			for (const uuid of dataset) {
				this._checkboxMap[uuid] = value;
			}
		} else {
			for (const uuid of this.currentPageItems) {
				this._checkboxMap[uuid] = value;
			}
		}
	}

	reset() {
		this.setAll(false);
		this._checkboxMap = {};
		this._sortedCheckboxes = [];
	}

	isInclusiveMode() {
		return !this.selectAllChecked;
	}

	/**
	 * questo metodo restituisce l'array di uuid TENENDO CONTO della modalità di selezione
	 * - se la modalità è inclusiva, nell'array avrai tutti gli uuid delle entità che non hai selezionato
	 *  (ESEMPIO) se hai selezionato tutto, si intende MODALITA' esclusiva, alcune api gestiscono questa modalità
	 *  quindi restituirà un array vuoto (poichè chiamerai le api con array vuoro e modalità esclusiva, tale
	 *  filtro verrà poi gestito a BE)
	 * - se la modalità non è esclusiva avrai l'array di uuid delle entity selezionate
	 * @returns array di uuid (con una semantica che varia in base alla modalità di selezione che si sta utilizzando)
	 */
	getUuidArray() {
		return Object.entries(this._checkboxMap).reduce((acc, [uuid, value]) => {
			//se la modalità è inclusiva, prendo tutti quelli dentro checkboxMap con valore true
			//se la modalità è esclusiva, prendo tutti quelli con valore false
			if (value !== this.selectAllChecked) {
				acc.push(uuid);
			}
			return acc;
		}, [] as string[]);
	}

	/**
	 * a differenza della funzione "getUuidArray", questo metodo restituisce sempre
	 * gli uuid delle entità selezionate (non tiene conto della modalità di selezione del servizio)
	 */
	getUuidArraySelected() {
		return Object.entries(this._checkboxMap).reduce((acc, [uuid, value]) => {
			if (value) {
				acc.push(uuid);
			}
			return acc;
		}, [] as string[]);
	}

	/**
	 * Funzione per recuperare il numero totale di checkbox selezionate
	 * Utilizzata per il controllo del limite
	 */
	private numberOfCheckboxMapSelected(): number {
		const checkboxEntries = Object.entries(this._checkboxMap);
		return checkboxEntries.filter(([, value]) => value).length;
	}

	private addsSortedCheckbox(uuid: string, index: number): void {
		this._sortedCheckboxes.push({ sequenceNumber: index, uuid: uuid });
	}

	/**
	 * Funzione per recuperare il più altro sequence number
	 * Utilizzata per settare il valore di sequenceNumber nell'oggetto da aggiungere a sortedCheckboxes
	 */
	private getSortedCheckboxLastSequenceNumber(): number {
		let lastSequenceNumber = 0;

		if (this._sortedCheckboxes.length) {
			lastSequenceNumber = this._sortedCheckboxes.reduce((max, obj) => {
				return obj.sequenceNumber > max ? obj.sequenceNumber : max;
			}, this._sortedCheckboxes[0].sequenceNumber);
		}

		return lastSequenceNumber;
	}

	/**
	 * Funzione per deselezionare la checkbox più vecchia selezionata e rimuoverla dall'array sortedCheckboxes
	 */
	private deselectOldestSelected(): void {
		let minSequenceNumber = Number.MAX_SAFE_INTEGER;
		let minIndex = -1;
		for (let i = 0; i < this._sortedCheckboxes.length; i++) {
			if (this._sortedCheckboxes[i].sequenceNumber < minSequenceNumber) {
				minSequenceNumber = this._sortedCheckboxes[i].sequenceNumber;
				minIndex = i;
			}
		}

		if (minIndex == -1) return;

		const uuidToRemove = this._sortedCheckboxes[minIndex].uuid;
		this._sortedCheckboxes.splice(minIndex, 1);
		this._checkboxMap[uuidToRemove] = false;
	}
}
