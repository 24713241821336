import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { PracticeTemplate } from 'src/app/shared/models/informed-consent/practice-template';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';

export const setForceRefreshPracticeTemplates = createAction('[Practice-template] Set Force Refresh');
export const setTemplatesSuccess = createAction(
	'[Practice-template] Set Practice templates  Success',
	props<{ data: { totalTemplatesCount: number; pages: number[]; templates: PracticeTemplate[][] } }>()
);
export const setTemplatesError = createAction('[Practice-template] Set template  Error', props<{ error: HttpErrorResponse }>());
export const setDeletePracticeTemplate = createAction('[Practice-template] Set Delete template ', props<{ template: PracticeTemplate }>());
export const setDeletePracticeTemplateSuccess = createAction('[Practice-template] Set Delete templates  Success');
export const setDeletePracticeTemplateError = createAction('[Practice-template] Set Delete templates  Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Practice-template] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Practice-template] Set pages', props<{ pages: number[] }>());
export const setCachedPages = createAction('[Practice-template] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Practice-template] reset cache');
export const setSort = createAction('[Practice-template] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Practice-template] Set search', props<{ search: string | null }>());
