import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RolesReducers } from 'src/app/core/state/app.reducers';
import { RolesState } from './roles.state';

const selectRolesState = createFeatureSelector<RolesState>(RolesReducers.TAG);

export const selectRolesFeatures = createSelector(selectRolesState, (state) => state?.features);
export const selectRolesStructures = createSelector(selectRolesState, (state) => state?.structures);

export const selectRolesData = createSelector(selectRolesState, (state: RolesState) => state.data);

export const selectData = createSelector(selectRolesState, (state) => state.data);
export const selectPages = createSelector(selectRolesState, (state) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectRolesState, (state: RolesState) => state.gridSearch);
export const selectStatus = createSelector(selectRolesState, (state) => state.status);
export const selectSort = createSelector(selectRolesState, (state) => state.sort);
export const selectCachedPages = createSelector(selectRolesState, (state) => state.cachedPages);
export const selectProductStructureUUID = createSelector(selectRolesState, (state) => state.structureUUID);
