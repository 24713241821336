import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DataleanSelectOption } from '../../models/select-field';
import { SelectFieldComponent } from '../select-field/select-field.component';

@Component({
	selector: 'addiction-bulk-action-bar',
	standalone: true,
	imports: [CommonModule, SelectFieldComponent, TranslateModule],
	templateUrl: './bulk-action-bar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: ['.counter{margin-right: 10px;	display: flex;	gap: 5px; width: 100%; margin-left: 5px;}'],
})
export class BulkActionBarComponent implements OnInit {
	@Input({ required: true }) options: DataleanSelectOption[] = [];
	@Input() selectedItems: number | null = null;
	@Output() execute = new EventEmitter<string>();
	@Output() cancel = new EventEmitter<void>();

	selectedValue?: string;

	ngOnInit(): void {
		this.selectedValue = this.options[0].value;
	}

	onExecute(value?: string) {
		this.execute.emit(value);
	}
}
