import { Injectable, inject } from '@angular/core';
import { DataleanBaseApiService, Parts, SimpleObject } from 'addiction-components';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TypeAction } from './../../../../shared/models/on-boarding/action.model';
import { ObjectiveGroup } from './../../../../shared/models/on-boarding/objective-group.model';
import { Objective } from './../../../../shared/models/on-boarding/objective.model';

@Injectable({
	providedIn: 'root',
})
export class ObConfiguratorService {
	private baseApi = inject(DataleanBaseApiService);
	private onboardingUrl = environment.onboardingUrl ? environment.onboardingUrl : undefined;
	private readonly NO_ONBOARDING_URL_ERROR = new Error('Please provide a on-boarding url');

	// ---------------------
	//        ACTIONS
	// ---------------------
	getCountActions(): Observable<{ count: number }> {
		if (this.onboardingUrl) {
			const additionalParams: SimpleObject = {};
			return this.baseApi.getEntities<{ count: number }>(this.onboardingUrl + 'action/count', additionalParams, []);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}
	//TODO: Implement ActionType filtering
	getActions(uuids?: string[]): Observable<TypeAction[]> {
		if (this.onboardingUrl) {
			const additionalParams: SimpleObject = {};

			additionalParams['sortBy'] = 'name1#asc';

			if (uuids && uuids.length) additionalParams['uuid'] = uuids.join(',');

			return this.baseApi.getMany<TypeAction>(this.onboardingUrl + 'action', [Parts.PREVIEW_ASSET_INFO], {
				additionalParams,
			});
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	getAction(uuid: string, locale?: string): Observable<TypeAction> {
		if (this.onboardingUrl) {
			if (locale) return this.baseApi.getOne<TypeAction>(this.onboardingUrl + 'action', uuid, [Parts.PREVIEW_ASSET_INFO], { locale });
			return this.baseApi.getOne<TypeAction>(this.onboardingUrl + 'action', uuid, [Parts.PREVIEW_ASSET_INFO]);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	updateAction(action: TypeAction): Observable<TypeAction> {
		if (this.onboardingUrl) {
			const { uuid } = action;
			return this.baseApi.updateOne<TypeAction>(this.onboardingUrl + 'action', uuid, action, [Parts.EMPTY]);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	createAction(action: TypeAction): Observable<TypeAction> {
		if (this.onboardingUrl) {
			return this.baseApi.createOne<TypeAction>(this.onboardingUrl + 'action', action, [Parts.EMPTY]);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	deleteAction(uuid: string): Observable<true> {
		if (this.onboardingUrl) {
			return this.baseApi.deleteOne(this.onboardingUrl + 'action', uuid).pipe(map(() => true));
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	// ---------------------
	//     OBJECTIVES
	// ---------------------
	getCountObjectives(): Observable<{ count: number }> {
		if (this.onboardingUrl) {
			const additionalParams: SimpleObject = {};
			return this.baseApi.getEntities<{ count: number }>(this.onboardingUrl + 'objective/count', additionalParams, []);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}
	getObjectives(uuids?: string[]): Observable<Objective[]> {
		if (this.onboardingUrl) {
			const additionalParams: SimpleObject = {};

			additionalParams['sortBy'] = 'name1#asc';

			if (uuids && uuids.length) additionalParams['uuids'] = uuids.join(',');

			return this.baseApi.getMany<Objective>(this.onboardingUrl + 'objective', [Parts.PREVIEW_ASSET_INFO], {
				additionalParams,
			});
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	getObjective(uuid: string, locale?: string): Observable<Objective> {
		if (this.onboardingUrl) {
			if (locale) return this.baseApi.getOne<Objective>(this.onboardingUrl + 'objective', uuid, [Parts.PREVIEW_ASSET_INFO], { locale });

			return this.baseApi.getOne<Objective>(this.onboardingUrl + 'objective', uuid, [Parts.PREVIEW_ASSET_INFO]);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	updateObjective(objective: Objective): Observable<Objective> {
		if (this.onboardingUrl) {
			const { uuid } = objective;
			return this.baseApi.updateOne<Objective>(this.onboardingUrl + 'objective', uuid, objective, [Parts.EMPTY]);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	createObjective(objective: Objective): Observable<Objective> {
		if (this.onboardingUrl) {
			return this.baseApi.createOne<Objective>(this.onboardingUrl + 'objective', objective, [Parts.EMPTY]);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	deleteObjective(uuid: string): Observable<true> {
		if (this.onboardingUrl) {
			return this.baseApi.deleteOne(this.onboardingUrl + 'objective', uuid).pipe(map(() => true));
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	// ---------------------
	//     OBJECTIVE-LIST
	// ---------------------
	getCountObjectiveGroups(): Observable<{ count: number }> {
		if (this.onboardingUrl) {
			const additionalParams: SimpleObject = {};
			return this.baseApi.getEntities<{ count: number }>(this.onboardingUrl + 'objective-group/count', additionalParams, []);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}
	getObjectiveGroups(uuids?: string[]): Observable<ObjectiveGroup[]> {
		if (this.onboardingUrl) {
			const additionalParams: SimpleObject = {};

			additionalParams['sortBy'] = 'name1#asc';

			if (uuids && uuids.length) additionalParams['uuids'] = uuids.join(',');

			return this.baseApi.getMany<ObjectiveGroup>(this.onboardingUrl + 'objective-group', [Parts.PREVIEW_ASSET_INFO], {
				additionalParams,
			});
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	getObjectiveGroup(uuid: string, locale?: string): Observable<ObjectiveGroup> {
		if (this.onboardingUrl) {
			if (locale) return this.baseApi.getOne<ObjectiveGroup>(this.onboardingUrl + 'objective-group', uuid, [Parts.CHILDREN], { locale });

			return this.baseApi.getOne<ObjectiveGroup>(this.onboardingUrl + 'objective-group', uuid, [Parts.CHILDREN]);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	updateObjectiveGroup(objectiveGroup: ObjectiveGroup): Observable<ObjectiveGroup> {
		if (this.onboardingUrl) {
			const { uuid } = objectiveGroup;
			return this.baseApi.updateOne<ObjectiveGroup>(this.onboardingUrl + 'objective-group', uuid, objectiveGroup, [Parts.EMPTY]);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	createObjectiveGroup(objectiveGroup: ObjectiveGroup): Observable<ObjectiveGroup> {
		if (this.onboardingUrl) {
			return this.baseApi.createOne<ObjectiveGroup>(this.onboardingUrl + 'objective-group', objectiveGroup, [Parts.EMPTY]);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	deleteObjectiveGroup(uuid: string): Observable<true> {
		if (this.onboardingUrl) {
			return this.baseApi.deleteOne(this.onboardingUrl + 'objective-group', uuid).pipe(map(() => true));
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}
}
