import { StructureField } from "../models";

export interface Anchor {
  label: string;
  id?: string;
  structureFields: StructureField[];
}
export interface AnchorWihoutStructure {
  label: string;
  id: string;
}

export function isAnchorWithStructure(obj: Anchor | AnchorWihoutStructure): obj is Anchor {
  return typeof obj === "object" && obj !== null && "structureFields" in obj;
}
