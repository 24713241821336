import { Asset, FeatureValue, ISortInfo, ObjectValuesType, Tag, ThumbnailConfig } from 'addiction-components';
import { AssetSaveData, Conversion, IDataleanErrorManager, STATE_STATUS } from 'src/app/shared/models';

export type MediaLibraryViewMode = 'search' | 'browse';
export type SelectionMap = { [uuid: string]: Asset };
export enum QueueStatus {
	'WAITING' = 'waiting',
	'UPLOADING' = 'uploading',
	'FINISHED' = 'finished',
	'INTERRUPTED' = 'interrupted',
	'ERROR' = 'error',
}

export interface AssetQueue {
	assetInfo: AssetSaveData;
	progress: number;
	status: QueueStatus;
}
export interface MediaLibraryState {
	currentPath: string;
	currentFeatureValuePath: string[];
	data: { assets: Asset[][]; totalAssetCount: number };
	/** Fake root */
	folderRoot: Partial<Asset>;
	featureValuesRoot: Partial<FeatureValue>;
	pagination: number[];
	sort?: ISortInfo;
	filters?: MediaLibraryFilters;
	assetStatusLoading?: STATE_STATUS;
	oldFilters?: MediaLibraryFilters; //non poteno usare il distinctUntilChanged salvo i vecchi filtri per poterli confrontare
	oldData?: { totalAssetCount: number; assets: Asset[][] };
	/** Indica se mostrare la pagina dei risultati di ricerca oppure no */
	viewMode: MediaLibraryViewMode;
	loading: number;
	selectedAsset?: Asset;
	error?: MediaLibraryError; // TODO: definire un modo generico e comune per gestire gli errori
	uploading: boolean;
	uploadProgress?: number;
	thumbnailConfigs: ThumbnailConfig[];
	conversions?: Conversion[];
	queue: AssetQueue[];
	availableFilter?: MediaLibraryAvailableFilters;
	availableFilterStatus: STATE_STATUS;
	interruptUpload?: boolean;
	topScroll?: number;
}

export interface MediaLibraryFilters {
	q?: string;
	searchFields?: 'string';
	assetType?: string[];
	updateFrom?: string;
	updateTo?: string;
	featureValueList?: string;
	tags?: string;
	createFrom?: string;
	createTo?: string;
	format?: string;
  createDate?: string;
	//per gestione stato apertura/chiusura della singola sezione filtro
	showAllFormat?: boolean;
	showAllAssetType?: boolean;
}

export interface MediaLibraryAvailableFilters {
	filterData: FilterData;
	features: FeatureValue[];
}

export interface FilterData {
	format: Record<string, number>;
	assetType: Record<string, number>;
	tags: Tag[];
}

export interface MediaLibraryError {
	errorType: MediaLibErrorType;
	data?: IDataleanErrorManager | unknown;
}

export const MediaLibErrorType = {
	INVALID_FOLDER: 'invalid_folder',
	GENERIC_HTTP: 'generic_http',
	NAME_TAKEN: 'name_taken',
	UPDATE_FOLDER: 'update_folder',
	UPDATE_ASSET: 'update_asset',
};
export type MediaLibErrorType = ObjectValuesType<typeof MediaLibErrorType>;
