import { DataleanEntity, ObjectValuesType } from "addiction-components";

export interface Integration extends DataleanEntity {
  type: IntegrationType;
  name: string;
  appConfig?: Record<string, unknown>;
  [appConfigKey: string]: unknown;
}

export const IntegrationType = {
  CATALEAN: 'catalean',
  SMARTCO: 'smartCo',
} as const;


export type IntegrationType = ObjectValuesType<typeof IntegrationType>;