import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[dataleanRepeat]' })
export class RepeatDirective {
	constructor(private templateRef: TemplateRef<unknown>, private viewContainer: ViewContainerRef) {}

	@Input('dataleanRepeat') set count(c: number | null) {
		if (c === null) return;
		this.viewContainer.clear();
		for (let i = 0; i < c; i++) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		}
	}
}
