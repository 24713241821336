import { createDeepTypeGuard } from '../lib/utils/type-guard';
import { DataleanUser } from './base-user.interface';
import { Progress } from './progress';
import { ApplicationUser } from './users-and-groups/application-user';

export interface BaseUserInfo {
	uuid: string;
	firstName: string;
	lastName: string;
	email: string;
	organizationUUID?: string;
}

export enum SubjectType {
	APPLICATION_GROUP = 'applicationGroup',
	APPLICATION_SMART_GROUP = 'applicationSmartGroup',
	APPLICATION_USER = 'applicationUser',
	DATALEAN_USER = 'user',
}

export interface ApplicationUserGroup {
	uuid: string;
	name: string;
	hiddenProductsByDefault?: boolean;
	admin?: DataleanUser;
	communityUUIDList?: string[];
  communityUUID?: string;
	members?: Partial<ApplicationUser>[];
}

export interface ApplicationUserSmartGroup extends ApplicationUserGroup {
	structureUUID: string;
	smartGroupCriteria: SmartGroupCriteria;
}

export interface UserRecipient {
	items: UserRecipientItem[][];
	pages?: number[];
	totalItems?: number;
}
export interface UserRecipientItem extends ApplicationUser, Partial<Omit<Progress, 'status' | 'structureUUID'>> {
	opened?: boolean;
	read?: boolean;
	liked?: boolean;
	uuid: string;
	responsible: Array<UserResponsabile>;
  score?: number;
}

export interface UserResponsabile {
	openedResp: boolean;
	readResp: boolean;
	likedResp: boolean;
	uuid: string;
}

export interface GroupRecipient {
	items: GroupRecipientItem[][];
	pages?: number[];
	totalItems?: number;
}
export interface GroupRecipientItem extends ApplicationUserGroup {
	openedCount: number;
	readCount: number;
	likesCount: number;
	feedbackUser: Array<unknown>;
	members: Partial<ApplicationUser>[];
	membersCount: number;
	uuid: string;
}

export type SmartGroupCriteria = Record<string, (string | boolean)[] | undefined>;
export const isGroup = createDeepTypeGuard<ApplicationUserGroup>('name', 'string');
