import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PracticeSectionContainerState, SECTIONS_CONTAINER_TAG } from './practice-section-container.reducer';

const selectPracticeSectionsState = createFeatureSelector<PracticeSectionContainerState>(SECTIONS_CONTAINER_TAG);

export const selectData = createSelector(selectPracticeSectionsState, (state: PracticeSectionContainerState) => state.data);
export const selectPages = createSelector(selectPracticeSectionsState, (state: PracticeSectionContainerState) => state.pages);
export const selectCachedPages = createSelector(selectPracticeSectionsState, (state: PracticeSectionContainerState) => state.cachedPages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectPracticeSectionsState, (state: PracticeSectionContainerState) => state.gridSearch);
export const selectStatus = createSelector(selectPracticeSectionsState, (state: PracticeSectionContainerState) => state.status);
export const selectSort = createSelector(selectPracticeSectionsState, (state: PracticeSectionContainerState) => state.sort);
