import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, Inject, Type, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isObservable } from 'rxjs';
import { ModalTargetDirective } from '../../../directives/modal-target.directive';
import { BaseModalContent, HeaderButton } from '../base-modal-content';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';

@Component({
	selector: 'modal-dialog',
	templateUrl: './modal-dialog.component.html',
	styleUrls: ['./modal-dialog.component.scss'],
	standalone: true,
	imports: [CommonModule, ModalHeaderComponent, ModalTargetDirective],
	providers: [ModalTargetDirective],
})

/** Component wrapper per il contenuto di una modal. Ha un template di base con header e elementi comuni a tutti le modal */
export class ModalDialogComponent implements AfterViewInit {
	@ViewChild(ModalTargetDirective) content!: ModalTargetDirective;

	protected destroyRef = inject(DestroyRef);

	title: string = '';
	headerButtons: HeaderButton[] = [];
	instance?: BaseModalContent<unknown>;

	protected dialogId!: string; // assegnata dal ModalService appena dopo apertura

	constructor(
		@Inject(MAT_DIALOG_DATA)
		protected data: { component: Type<BaseModalContent<unknown>>; inputData: { [key: string]: unknown }; title: string },
		protected cdr: ChangeDetectorRef
	) {
		this.title = data.title;
	}

	ngAfterViewInit(): void {
		this.instance = this.renderComponent(this.data.component);
		this.instance.setDialogId(this.dialogId);
		// assign input data
		for (const prop in this.data.inputData) {
			this.instance[prop] = this.data.inputData[prop];
		}

		// ℹ️ Questo metodo è chiamato ogni volta che il componente viene caricato
		// importante per la gestione di attributi dinamici
		if (this.instance?.['ngOnChanges'] && typeof this.instance['ngOnChanges'] === 'function') this.instance['ngOnChanges']();

		this.manageButtons(this.instance);

		// evita problemi legati al change-detection (https://angular.io/errors/NG0100)
		this.cdr.detectChanges();
	}

	setDialogId(id: string) {
		this.dialogId = id;
		this.instance?.setDialogId(id);
	}

	renderComponent(component: Type<BaseModalContent<unknown>>): BaseModalContent<unknown> {
		const { viewContainerRef } = this.content;
		viewContainerRef.clear();
		const compoentRef = viewContainerRef.createComponent<BaseModalContent<unknown>>(component);
		return compoentRef.instance;
	}

	private manageButtons(comp: BaseModalContent) {
		const buttons = comp.getHeaderButtons();
		if (isObservable(buttons)) buttons.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((btns) => (this.headerButtons = btns));
		else this.headerButtons = buttons;
	}
}
