import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { ObConfiguratorActions } from 'src/app/core/state/app.actions';
import { OBObjectiveState } from './ob-configurator.state';

export const TAG_OBOBJECTIVE = 'obObjective';

export const initialState: OBObjectiveState = {
	objectives: [],
	loading: false,
};

export const reducer = createReducer(
	initialState,
	// GENERIC LOADING\ERROR ACTIONS
	on(
		ObConfiguratorActions.createNewObjective,
		ObConfiguratorActions.deleteObjective,
		ObConfiguratorActions.updateObjective,
		ObConfiguratorActions.reloadObjectives,
		(state) => ({
			...state,
			loading: true,
			error: undefined,
		})
	),
	on(
		ObConfiguratorActions.createObjectiveFailure,
		ObConfiguratorActions.deleteObjectiveFailure,
		ObConfiguratorActions.updateObjectiveFailure,
		ObConfiguratorActions.loadObjectivesFailure,
		(state) => ({
			...state,
			loading: false,
			error: true,
		})
	),
	on(ObConfiguratorActions.loadObjectivesSuccess, (state, { objectives }) => ({
		...state,
		loading: false,
		objectives: objectives,
		editingUUID: undefined,
	})),

	on(ObConfiguratorActions.createObjectiveSuccess, (state, { objective }) => ({
		...state,
		loading: false,
		selectedObjective: { ...state.selectedObjective, objective },
		editingUUID: undefined,
		error: false,
	})),
	on(ObConfiguratorActions.deleteObjectiveGroupSuccess, (state, { uuid }) =>
		produce(state, (draft) => {
			draft.loading = false;
			draft.error = false;
			draft.selectedObjective = {
				objective: uuid === draft.selectedObjective?.objective?.uuid ? undefined : draft.selectedObjective?.objective,
			};
		})
	),
	on(ObConfiguratorActions.updateObjectiveSuccess, (state, { objective }) =>
		produce(state, (draft) => {
			draft.loading = true;
			draft.error = false;
			draft.selectedObjective = { ...draft.selectedObjective, objective };
		})
	),
	on(ObConfiguratorActions.selectObjective, (state, { objective }) => ({
		...state,
		selectedPictogram: { objective },
	})),
	on(ObConfiguratorActions.editObjective, (state, { uuid }) => ({
		...state,
		editingUUID: uuid,
	}))
);
