import { SimpleObject } from './datalean-entity';
import { PaginationInfo } from './pagination-info';
import { SearchInfo } from './search-info';
import { ISortInfo, SortInfo } from './sorting-info';
import { Page } from './table.interface';

export interface ApiOptions {
	search?: SearchInfo;
	pagination?: PaginationInfo | Partial<Page>;
	sort?: SortInfo[] | SortInfo | ISortInfo | ISortInfo[];
	additionalParams?: SimpleObject;
}
