import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, FormControl, ReactiveFormsModule, ControlValueAccessor } from '@angular/forms';
import { Observer } from 'rxjs';
import { ToLocalizedValuePipe } from '../../pipes/to-localized-value.pipe';
import { PrivacyConfig } from '../../models/privacy-field';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'addiction-checkbox-privacy',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule],
	templateUrl: './checkbox-privacy.component.html',
	styleUrls: ['./checkbox-privacy.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CheckboxPrivacyComponent, multi: true }],
})
export class CheckboxPrivacyComponent implements ControlValueAccessor, OnInit {
	@Input() value?: boolean;
	@Input({ required: true }) privacyConfig!: PrivacyConfig;
	@Input() disabled = false;

	@Output() valueChange = new EventEmitter<boolean>();

	protected destroyRef = inject(DestroyRef);

	formControl = new FormControl<boolean>(false);
	textParts: string[] = [];
	privacyLabelSections: string[] = [];
	toLocalizedPipe = inject(ToLocalizedValuePipe);

	ngOnInit(): void {
		this.privacyLabelSections = this.preparePrivacyText(this.toLocalizedPipe.transform(this.privacyConfig.label));

		if (typeof this.value === 'boolean') this.formControl.setValue(this.value);
		if (this.disabled) this.formControl.disable();
		this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
			this.valueChange.emit(!!value);
		});
	}

	public onTouched: () => void = () => {};

	writeValue(obj: boolean): void {
		if (typeof obj == 'boolean') {
			this.formControl.setValue(obj);
		}
	}

	registerOnChange(fn: Observer<boolean | null>): void {
		this.formControl.valueChanges.subscribe(fn);
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
		if (disabled) this.formControl.disable({ emitEvent: false });
		else this.formControl.enable({ emitEvent: false });
	}
	preparePrivacyText(privacyLabel: string) {
		const linkStartPosition = privacyLabel.indexOf('<u>');
		const linkEndPosition = privacyLabel.indexOf('</u>');
		if (linkStartPosition !== -1 && linkEndPosition !== -1) {
			return [
				privacyLabel.substring(0, linkStartPosition),
				privacyLabel.substring(linkStartPosition, linkEndPosition),
				privacyLabel.substring(linkEndPosition),
			];
		} else {
			return [privacyLabel];
		}
	}

	openPrivacy(url?: string) {
		if (url) {
			window.open(url, '_blank');
		}
	}
}
