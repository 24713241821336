import { createAction as createAtionNGRX, props } from '@ngrx/store';
import { TypeAction } from './../../../../shared/models/on-boarding/action.model';
import { ObjectiveGroup } from './../../../../shared/models/on-boarding/objective-group.model';
import { Objective } from './../../../../shared/models/on-boarding/objective.model';

/* READ */
export const loadActions = createAtionNGRX('[Actions] Load Actions');
export const reloadActions = createAtionNGRX('[Actions] Reload Actions');
export const loadActionsSuccess = createAtionNGRX('[Actions] Load Actions Success', props<{ actions: TypeAction[] }>());
export const loadActionsFailure = createAtionNGRX('[Actions] Load Actions Failure', props<{ error: unknown }>());

export const loadObjectives = createAtionNGRX('[Objectives] Load Objectives');
export const reloadObjectives = createAtionNGRX('[Objectives] Reload Objectives');
export const loadObjectivesSuccess = createAtionNGRX('[Objectives] Load Objectives Success', props<{ objectives: Objective[] }>());
export const loadObjectivesFailure = createAtionNGRX('[Objectives] Load Objectives Failure', props<{ error: unknown }>());

export const loadObjectiveGroups = createAtionNGRX('[ObjectiveGroups] Load ObjectiveGroups');
export const reloadObjectiveGroups = createAtionNGRX('[ObjectiveGroups] Reload ObjectiveGroups');
export const loadObjectiveGroupsSuccess = createAtionNGRX(
	'[ObjectiveGroups] Load ObjectiveGroups Success',
	props<{ objectiveGroups: ObjectiveGroup[] }>()
);
export const loadObjectiveGroupsFailure = createAtionNGRX('[ObjectiveGroups] Load ObjectiveGroups Failure', props<{ error: unknown }>());

/* CREATE */
export const createAction = createAtionNGRX('[Actions] Create Action', props<{ action: TypeAction }>());
export const createActionSuccess = createAtionNGRX('[Actions] Create Action Success', props<{ action: TypeAction }>());
export const createActionFailure = createAtionNGRX('[Actions] Create Action Failure', props<{ error: unknown }>());

export const createObjective = createAtionNGRX('[Objective] Create Objective', props<{ objective: Objective }>());
export const createObjectiveSuccess = createAtionNGRX('[Objective] Create Objective Success', props<{ objective: Objective }>());
export const createObjectiveFailure = createAtionNGRX('[Objective] Create Objective Failure', props<{ error: unknown }>());

export const createObjectiveGroup = createAtionNGRX('[ObjectiveGroup] Create ObjectiveGroup', props<{ objectiveGroup: ObjectiveGroup }>());
export const createObjectiveGroupSuccess = createAtionNGRX(
	'[ObjectiveGroup] Create ObjectiveGroup Success',
	props<{ objectiveGroup: ObjectiveGroup }>()
);
export const createObjectiveGroupFailure = createAtionNGRX('[ObjectiveGroup] Create ObjectiveGroup Failure', props<{ error: unknown }>());

/* UPDATE */
export const updateAction = createAtionNGRX('[Actions] Update Action', props<{ action: TypeAction; uuid: string }>());
export const updateActionSuccess = createAtionNGRX('[Actions] Update Action Success', props<{ action: TypeAction }>());
export const updateActionFailure = createAtionNGRX('[Actions] Update Action Failure', props<{ error: unknown }>());

export const updateObjective = createAtionNGRX('[Objective] Update Objective', props<{ objective: Objective; uuid: string }>());
export const updateObjectiveSuccess = createAtionNGRX('[Objective] Update Objective Success', props<{ objective: Objective }>());
export const updateObjectiveFailure = createAtionNGRX('[Objective] Update Objective Failure', props<{ error: unknown }>());

export const updateObjectiveGroup = createAtionNGRX(
	'[ObjectiveGroup] Update ObjectiveGroup',
	props<{ objectiveGroup: ObjectiveGroup; uuid: string }>()
);
export const updateObjectiveGroupSuccess = createAtionNGRX(
	'[ObjectiveGroup] Update ObjectiveGroup Success',
	props<{ objectiveGroup: ObjectiveGroup }>()
);
export const updateObjectiveGroupFailure = createAtionNGRX('[ObjectiveGroup] Update ObjectiveGroup Failure', props<{ error: unknown }>());

/* DELETE */
export const deleteAction = createAtionNGRX('[Actions] Delete Action', props<{ uuid: string }>());
export const deleteActionSuccess = createAtionNGRX('[Actions] Delete Action Success', props<{ uuid: string }>());
export const deleteActionFailure = createAtionNGRX('[Actions] Delete Action Failure', props<{ error: unknown }>());

export const deleteObjective = createAtionNGRX('[Objective] Delete Objective', props<{ uuid: string }>());
export const deleteObjectiveSuccess = createAtionNGRX('[Objective] Delete Objective Success', props<{ uuid: string }>());
export const deleteObjectiveFailure = createAtionNGRX('[Objective] Delete Objective Failure', props<{ error: unknown }>());

export const deleteObjectiveGroup = createAtionNGRX('[ObjectiveGroup] Delete ObjectiveGroup', props<{ uuid: string }>());
export const deleteObjectiveGroupSuccess = createAtionNGRX('[ObjectiveGroup] Delete ObjectiveGroup Success', props<{ uuid: string }>());
export const deleteObjectiveGroupFailure = createAtionNGRX('[ObjectiveGroup] Delete ObjectiveGroup Failure', props<{ error: unknown }>());

/* SELECTION */
export const selectAction = createAtionNGRX('[Actions] Select Action', props<{ action?: TypeAction }>());
export const changeActionSelected = createAtionNGRX('[Actions] Change Action Selected', props<{ action: TypeAction }>());
export const editAction = createAtionNGRX('[Actions] Edit Action', props<{ uuid: string }>());
export const createNewAction = createAtionNGRX('[Actions] Create New Action', props<{ action: TypeAction }>());

export const selectObjective = createAtionNGRX('[Objective] Select Objective', props<{ objective?: Objective }>());
export const changeObjectiveSelected = createAtionNGRX('[Objective] Change Objective Selected', props<{ objective: Objective }>());
export const editObjective = createAtionNGRX('[Objective] Edit Objective', props<{ uuid: string }>());
export const createNewObjective = createAtionNGRX('[Objective] Create New Objective', props<{ objective: Objective }>());

export const selectObjectiveGroup = createAtionNGRX('[ObjectiveGroup] Select ObjectiveGroup', props<{ objectiveGroup?: ObjectiveGroup }>());
export const changeObjectiveGroupSelected = createAtionNGRX('[Actions] Change ObjectiveGroup Selected', props<{ objectiveGroup: ObjectiveGroup }>());
export const editObjectiveGroup = createAtionNGRX('[ObjectiveGroup] Edit ObjectiveGroup', props<{ uuid: string }>());
export const createNewObjectiveGroup = createAtionNGRX('[ObjectiveGroup] Create New ObjectiveGroup', props<{ objectiveGroup: ObjectiveGroup }>());
