import { Action, createReducer, on } from '@ngrx/store';
import * as GroupsActions from './groups.actions';
import { ApplicationUserGroup } from 'src/app/shared/models';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { Sort } from '@angular/material/sort';

export const GROUPS_TAG = 'groups';

export interface GroupsState {
	gridSearch: string | null;
	sort?: Sort;
	data: { totalGroupsCount: number; groups: ApplicationUserGroup[][] };
	pages: number[];
	status: STATE_STATUS;
	deleteStatus: STATE_STATUS;
	cachedPages: number[];
}

const initialState: GroupsState = {
	status: STATE_STATUS.UNKNOWN,
	deleteStatus: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	pages: [],
	data: { totalGroupsCount: 0, groups: [] },
	cachedPages: [],
	sort: { active: 'updateDate', direction: 'desc' },
};

export const reducers = createReducer(
	initialState,
	on(GroupsActions.setSort, (state: GroupsState, { sort }) => ({
		...state,
		sort,
	})),
	on(GroupsActions.setPages, (state: GroupsState, { pages }) => ({
		...state,
		pages,
	})),
	on(GroupsActions.setGroupsSuccess, (state: GroupsState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				groups: data.groups,
				totalGroupsCount: data.totalGroupsCount,
			},
			pages: [...state.cachedPages, ...data.pages],
		};
	}),
	on(GroupsActions.setGroupsError, (state: GroupsState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(GroupsActions.setStatus, (state: GroupsState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(GroupsActions.setGridSearch, (state: GroupsState, { search }) => {
		return {
			...state,
			gridSearch: search,
      cachedPages: [],
      data: { totalGroupsCount: 0, groups: [] },
		};
	}),
	on(GroupsActions.setDeleteDataleanGroup, (state: GroupsState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(GroupsActions.setDeleteDataleanGroupSuccess, (state: GroupsState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(GroupsActions.setDeleteDataleanGroupError, (state: GroupsState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
  on(GroupsActions.setCachedPages, (state: GroupsState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(GroupsActions.resetCache, (state: GroupsState) => ({
		...state,
		cachedPages: [],
    status: STATE_STATUS.READY,
		pages: [0],
		data: { totalGroupsCount: 0, groups: [] },
	}))
);

export const DataleanGroupReducer = (state: GroupsState | undefined, action: Action) => {
	return reducers(state, action);
};
