<div class="preview-container">
	<div class="top-container" *ngIf="topTextArray.length > 0">
		<div class="top">
			<span *ngFor="let text of topTextArray">
				{{ text }}
			</span>
		</div>
	</div>

	<div class="image-container">
		<img [src]="pictogram.simbol?.url" [alt]="pictogram.simbol?.name" />
		<div class="side" *ngIf="centerSideText.length > 0 || topSideText.length > 0">
			<div class="top-side">
				<ng-container *ngIf="topSideText.length > 0">
					<span *ngFor="let text of topSideText">
						{{ text }}
					</span>
				</ng-container>
			</div>
			<div class="center-side">
				<ng-container *ngIf="centerSideText.length > 0">
					<span *ngFor="let text of centerSideText">
						{{ text }}
					</span>
				</ng-container>
			</div>
			<div class="bottom-side"></div>
		</div>
	</div>

	<div class="bottom-container" *ngIf="bottomAdjacenArray.length > 0 || bottomText.length > 0">
		<div class="bottom-start">
			<ng-container *ngIf="bottomAdjacenArray.length > 0">
				<span *ngFor="let text of bottomAdjacenArray">
					{{ text }}
				</span>
			</ng-container>
		</div>
		<div class="bottom-center">
			<ng-container *ngIf="bottomText.length > 0">
				<span *ngFor="let text of bottomText">
					{{ text }}
				</span>
			</ng-container>
		</div>
	</div>
</div>
