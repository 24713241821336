import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductReducers } from 'src/app/core/state/app.reducers';
import { ProductState } from './product.state';

const selectProductsState = createFeatureSelector<ProductState>(ProductReducers.TAG);

export const selectProductsFilters = createSelector(selectProductsState, (state) => state?.filters);
export const selectProductsFeatures = createSelector(selectProductsState, (state) => state?.features);
export const selectProductsStructures = createSelector(selectProductsState, (state) => state?.structures);

export const selectData = createSelector(selectProductsState, (state) => state.data);
export const selectPages = createSelector(selectProductsState, (state) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectProductsState, (state) => state.gridSearch);
export const selectStatus = createSelector(selectProductsState, (state) => state.status);
export const selectSort = createSelector(selectProductsState, (state) => state.sort);
export const selectCachedPages = createSelector(selectProductsState, (state) => state.cachedPages);
export const selectProductStructureUUID = createSelector(selectProductsState, (state) => state.structureUUID);
