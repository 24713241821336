import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { ApplicationUsersActions, CommunitySelectActions } from 'src/app/core/state/app.actions';
import { ApplicationUsersSelectors } from 'src/app/core/state/app.selectors';
import { STATE_STATUS } from 'src/app/shared/models';
import { selectLastCommunitySelectedForApiRest } from 'src/app/shared/state/community-select/community-select.selectors';
import { ApplicationUsersService } from '../services/application-users.service';

@Injectable()
export class ApplicationUserEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private applicationUserService = inject(ApplicationUsersService);
	private activeRoute = inject(Router);

	fetchApplicationUsers$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(ApplicationUsersActions.setPages, ApplicationUsersActions.setForceRefreshApplicationUsers),
			concatLatestFrom(() => [
				this.store.select(ApplicationUsersSelectors.selectPages),
				this.store.select(ApplicationUsersSelectors.selectSort),
				this.store.select(ApplicationUsersSelectors.selectGridSearch),
				this.store.select(ApplicationUsersSelectors.selectStructureUUID),
				this.store.select(selectLastCommunitySelectedForApiRest),
				this.store.select(ApplicationUsersSelectors.selectCachedPages),
			]),
			debounce(() => this.store.select(ApplicationUsersSelectors.selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(ApplicationUsersActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, structureUUID, communityUUID, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;
				if (!structureUUID) return EMPTY;
				// const filterWithCommunity = structuredClone(filters);
				// if (communityUUID) filterWithCommunity.communityUUID = communityUUID;
				return this.applicationUserService.fetchApplicationUsers(actualPages, structureUUID, sort, gridSearch, communityUUID).pipe(
					combineLatestWith(this.store.select(ApplicationUsersSelectors.selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						// console.log(startingResult);
						startingResult.pages = cachedPages;
						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.numberOfPage);
								acc.totalUsersCount = item.paginationInfo.totalNumberOfElements ?? 0;
								if (item.result) {
									acc.users[item.paginationInfo.numberOfPage] = item.result.map((applicationUser) => ({
										...applicationUser,
									}));
								}
							}
							return acc;
						}, structuredClone(startingResult));

						// console.log(result);

						return ApplicationUsersActions.setApplicationUsersSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						return of(ApplicationUsersActions.setApplicationUsersError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ApplicationUsersActions.setGridSearch, ApplicationUsersActions.setSort, ApplicationUsersActions.setStructureUUID),
			concatLatestFrom(() => [
				this.store.select(ApplicationUsersSelectors.selectPages),
				this.store.select(ApplicationUsersSelectors.selectStructureUUID),
				this.store.select(ApplicationUsersSelectors.selectSort),
				this.store.select(ApplicationUsersSelectors.selectGridSearch),
				this.store.select(ApplicationUsersSelectors.selectCachedPages),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(([, pagesPrev, structureUUIDPrev, sortPrev, gridSearchPrev], [, pagesCurr, structureUUIDCurr, sortCurr, gridSearchCurr, cachedPages]) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						structureUUIDPrev === structureUUIDCurr &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}),
			map(() => ApplicationUsersActions.resetCache())
		)
	);

	deleteApplicationUsers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ApplicationUsersActions.setDeleteApplicationUser),
			// invoke API
			switchMap(({ user }) => {
				return this.applicationUserService.deleteUser(user).pipe(
					map(() => {
						return ApplicationUsersActions.setDeleteApplicationUserSuccess();
					}),
					catchError((error: HttpErrorResponse) => of(ApplicationUsersActions.setDeleteApplicationUserError({ error })))
				);
			})
		)
	);

	applicationUserSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ApplicationUsersActions.setApplicationUsersSuccess),
			map(({ data: { pages } }) => ApplicationUsersActions.setCachedPages({ pages }))
		)
	);

	deleteAndDisableApplicationUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ApplicationUsersActions.setDeleteApplicationUserSuccess, ApplicationUsersActions.setDeleteApplicationUserError),
			map(() => ApplicationUsersActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ApplicationUsersActions.resetCache),
			map(() => ApplicationUsersActions.setForceRefreshApplicationUsers())
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url === '/applicationUsers'),
			map(() => ApplicationUsersActions.resetCache())
		)
	);

	fetchUserStructures$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ApplicationUsersActions.setStructures, ApplicationUsersActions.setForceRefreshApplicationUsers),
			switchMap(() =>
				this.applicationUserService.fetchUserStructures().pipe(
					map((userStructures) => {
						return ApplicationUsersActions.setStructuresSuccess({ userStructures });
					}),
					catchError((error: HttpErrorResponse) => {
						return of(ApplicationUsersActions.setApplicationUsersError({ error }));
					})
				)
			)
		)
	);

	constructor() {}
}
