import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PracticeTemplateState, PRACTICE_TEMAPLATES_TAG } from './practice-template.reducer';

const selectPracticeTemplatesState = createFeatureSelector<PracticeTemplateState>(PRACTICE_TEMAPLATES_TAG);

export const selectData = createSelector(selectPracticeTemplatesState, (state: PracticeTemplateState) => state.data);
export const selectPages = createSelector(selectPracticeTemplatesState, (state: PracticeTemplateState) => state.pages);
export const selectCachedPages = createSelector(selectPracticeTemplatesState, (state: PracticeTemplateState) => state.cachedPages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectPracticeTemplatesState, (state: PracticeTemplateState) => state.gridSearch);
export const selectStatus = createSelector(selectPracticeTemplatesState, (state: PracticeTemplateState) => state.status);
export const selectSort = createSelector(selectPracticeTemplatesState, (state: PracticeTemplateState) => state.sort);
