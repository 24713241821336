import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, concatMap, debounce, debounceTime, distinctUntilChanged, first, map, mergeMap, tap } from 'rxjs/operators';
import { CommunitySelectActions, WorkFlowActions } from 'src/app/core/state/app.actions';
import { CommunitySelectSelectors, UserSelector, WorkFlowSelector } from 'src/app/core/state/app.selectors';
import { STATE_STATUS } from 'src/app/shared/models';
import { TasksTabs } from '../../models/workFlow';
import { WorkFlowService } from '../../services/workflow.service';

@Injectable()
export class WorkFlowEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private workFlowService = inject(WorkFlowService);
	private translateService = inject(TranslateService);
	private activeRoute = inject(Router);

	fetchWorkFlows$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(WorkFlowActions.setPages, WorkFlowActions.setForceRefreshWorkFlow),
			concatLatestFrom(() => [
				this.store.select(WorkFlowSelector.selectPages),
				this.store.select(WorkFlowSelector.selectSort),
				this.store.select(WorkFlowSelector.selectGridSearch),
				this.store.select(WorkFlowSelector.selectWorkFlowsFilters),
				this.store.select(WorkFlowSelector.selectWorkFlowType),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				this.store.select(WorkFlowSelector.selectCachedPages),
				this.store.select(UserSelector.selectUserData),
			]),
			debounce(() => this.store.select(WorkFlowSelector.selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			tap(() => {
				this.store.dispatch(WorkFlowActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			filter(([, pages]) => !!pages?.length),
			switchMap(([, pages, sort, gridSearch, filters, workFlowType, communityUUID, cachedPages, userData]) => {
				// console.log(filters);

				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) {
					this.store.dispatch(WorkFlowActions.setStatus({ status: STATE_STATUS.READY }));
					return EMPTY;
				}
				const filterWithCommunity = structuredClone(filters);
				if (communityUUID) filterWithCommunity.communityUUID = communityUUID;
				if (userData?.roles)
					filterWithCommunity['rolesUUID'] = userData.roles.map((x) => {
						return x.uuid;
					});

				return this.workFlowService
					.fetchWorkFlow(
						workFlowType,
						actualPages,
						filters.tasksTab || TasksTabs[0],
						sort,
						this.translateService.currentLang ?? this.translateService.defaultLang,
						gridSearch,
						filterWithCommunity,
					)
					.pipe(
						combineLatestWith(this.store.select(WorkFlowSelector.selectPagedData)),
						first(),
						map(([data, startingResult]) => {
							startingResult.pages = cachedPages;
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.pages.push(item.paginationInfo.pageNumber);
									acc.totalWorkFlowCount = item.paginationInfo.totalElements ?? 0;
									if (item.result) {
										acc.workFlows[item.paginationInfo.pageNumber] = item.result.map((workFlow) => ({
											...workFlow,
										}));
									}
								}
								return acc;
							}, structuredClone(startingResult));

							return WorkFlowActions.setWorkFlowSuccess({ data: result });
						}),
						catchError((error: HttpErrorResponse) => {
							console.error(error);
							return of(WorkFlowActions.setWorkFlowError({ error }));
						}),
					);
			}),
		),
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(WorkFlowActions.setGridSearch, WorkFlowActions.setSort, WorkFlowActions.updateFilters),
			concatLatestFrom(() => [
				this.store.select(WorkFlowSelector.selectPages),
				this.store.select(WorkFlowSelector.selectSort),
				this.store.select(WorkFlowSelector.selectGridSearch),
				this.store.select(WorkFlowSelector.selectWorkFlowsFilters),
				this.store.select(WorkFlowSelector.selectWorkFlowType),
				this.store.select(WorkFlowSelector.selectCachedPages),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				([, pagesPrev, sortPrev, gridSearchPrev, filterPrev], [, pagesCurr, sortCurr, gridSearchCurr, filterCurr, , cachedPages]) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						Object.values(filterPrev).every((f) => Object.values(filterCurr).includes(f)) &&
						Object.values(filterCurr).every((f) => Object.values(filterPrev).includes(f)) &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				},
			),
			map(() => WorkFlowActions.resetCache()),
		),
	);

	workFlowTypeChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(WorkFlowActions.workflowTypeChanged),
			switchMap((action) =>
				this.workFlowService
					.getStructure(action.workflowType)
					.pipe(
						concatMap((structures) => [
							WorkFlowActions.setStructures({ structures }),
							WorkFlowActions.setWorkFlowList({ workflowType: action.workflowType }),
						]),
					),
			),
		),
	);

	workFlowSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(WorkFlowActions.setWorkFlowSuccess),
			map(({ data: { pages } }) => WorkFlowActions.setCachedPages({ pages })),
		),
	);

	resetPage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(WorkFlowActions.setGridSearch),
			map(() => WorkFlowActions.resetCache()),
		),
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(WorkFlowActions.resetCache),
			tap(() => {
				this.store.dispatch(WorkFlowActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			debounceTime(500),
			map(() => WorkFlowActions.setForceRefreshWorkFlow()),
			tap(() => {
				this.store.dispatch(WorkFlowActions.setStatus({ status: STATE_STATUS.READY }));
			}),
		),
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url.includes('/workflow')),
			map(() => WorkFlowActions.resetCache()),
		),
	);

	fetchTasksTabsCount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(WorkFlowActions.loadTasksTabsCounts, WorkFlowActions.setForceRefreshWorkFlow),
			concatLatestFrom(() => [this.store.select(WorkFlowSelector.selectWorkFlowType), this.store.select(UserSelector.selectUserData)]),
			mergeMap(([, workflowType, userData]) =>
				this.workFlowService
					.getTasksTabCounts(
						workflowType,
						userData?.roles.map((x) => {
							return x.uuid;
						}),
					)
					.pipe(map((counts) => WorkFlowActions.setTasksTabsCounts({ counts }))),
			),
		),
	);

	constructor() {}
}
