import { Action, createReducer, on } from '@ngrx/store';
import * as TemplateActions from './practice-template.actions';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { Sort } from '@angular/material/sort';
import { PracticeTemplate } from 'src/app/shared/models/informed-consent/practice-template';

export const PRACTICE_TEMAPLATES_TAG = 'practiceTemplates';

export interface PracticeTemplateState {
	gridSearch: string | null;
	sort?: Sort;
	data: { totalTemplatesCount: number; templates: PracticeTemplate[][] };
	pages: number[];
	status: STATE_STATUS;
	deleteStatus: STATE_STATUS;
	cachedPages: number[];
}

const initialState: PracticeTemplateState = {
	status: STATE_STATUS.UNKNOWN,
	deleteStatus: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	pages: [],
	data: { totalTemplatesCount: 0, templates: [] },
	cachedPages: [],
};

export const reducers = createReducer(
	initialState,
	on(TemplateActions.setSort, (state: PracticeTemplateState, { sort }) => ({
		...state,
		sort,
	})),
	on(TemplateActions.setPages, (state: PracticeTemplateState, { pages }) => ({
		...state,
		pages,
	})),
	on(TemplateActions.setTemplatesSuccess, (state: PracticeTemplateState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				templates: data.templates,
				totalTemplatesCount: data.totalTemplatesCount,
			},
			pages: [...state.cachedPages, ...data.pages],
		};
	}),
	on(TemplateActions.setTemplatesError, (state: PracticeTemplateState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(TemplateActions.setStatus, (state: PracticeTemplateState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(TemplateActions.setGridSearch, (state: PracticeTemplateState, { search }) => {
		return {
			...state,
			gridSearch: search,
      cachedPages: [],
      data: { totalTemplatesCount: 0, templates: [] },
		};
	}),
	on(TemplateActions.setDeletePracticeTemplate, (state: PracticeTemplateState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(TemplateActions.setDeletePracticeTemplateSuccess, (state: PracticeTemplateState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(TemplateActions.setDeletePracticeTemplateError, (state: PracticeTemplateState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
  on(TemplateActions.setCachedPages, (state: PracticeTemplateState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(TemplateActions.resetCache, (state: PracticeTemplateState) => ({
		...state,
		cachedPages: [],
    status: STATE_STATUS.READY,
		pages: [0],
		data: { totalTemplatesCount: 0, templates: [] },
	}))
);

export const PracticeTemplatesReducer = (state: PracticeTemplateState | undefined = initialState, action: Action) => {
	return reducers(state, action);
};
