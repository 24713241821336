import { createDeepTypeGuard } from "../lib/utils/type-guard";
import { IPermission } from "./permission.interface";

export interface Role {
  uuid: string;
  name: string;
  permissions: IPermission[];
  availableLanguages: Array<{ uuid: string; name: string }>;
}
export const isRole = createDeepTypeGuard<Role>('name', 'string');
