<div class="html-editor-container">
  <div class="html-editor-menu" [ariaDisabled]="readonly" [ngClass]="{ 'readonly': readonly }">
    <button id="bold"
      [ngClass]="{ 'active': editor.isActive('bold') }"
      (click)="editor.chain().focus().toggleBold().run()">
    </button>
    <button id="italic"
      [ngClass]="{ 'active': editor.isActive('italic') }"
      (click)="editor.chain().focus().toggleItalic().run()">
    </button>
    <button id="underline"
      [ngClass]="{ 'active': editor.isActive('underline') }"
      (click)="editor.chain().focus().toggleUnderline().run()">
    </button>
    <button id="strike"
      [ngClass]="{ 'active': editor.isActive('strike') }"
      (click)="editor.chain().focus().toggleStrike().run()">
    </button>
    <button id="orderedList"
      [ngClass]="{ 'active': editor.isActive('orderedList') }"
      (click)="editor.chain().focus().toggleOrderedList().run()">
    </button>
    <button id="bulletList"
      [ngClass]="{ 'active': editor.isActive('bulletList') }"
      (click)="editor.chain().focus().toggleBulletList().run()">
    </button>

    <div class="heading-container">
      <button
        id="heading-button"
        (click)="isHeadingMenuOpen = !isHeadingMenuOpen">
        <span>
          {{ editor.getAttributes('heading')['level'] ? ("STRUCTURE.FIELD.HTML_HEADER" | translate) + ' ' + editor.getAttributes('heading')['level'] : ("STRUCTURE.FIELD.HTML_HEADING" | translate) }}
        </span>
      </button>

      <div
        id="heading-dropdown"
        *ngIf="isHeadingMenuOpen"
        (click)="isHeadingMenuOpen = false">
        <button
          id="header-1"
          [ngClass]="{ 'active': editor.isActive('heading', { level: 1 }) }"
          (click)="this.editor.chain().focus().toggleHeading({ level: 1 }).run()">
          {{ "STRUCTURE.FIELD.HTML_HEADER" | translate }} 1
        </button>
        <button
          id="header-2"
          [ngClass]="{ 'active': editor.isActive('heading', { level: 2 }) }"
          (click)="this.editor.chain().focus().toggleHeading({ level: 2 }).run()">
          {{ "STRUCTURE.FIELD.HTML_HEADER" | translate }} 2
        </button>
        <button
          id="header-3"
          [ngClass]="{ 'active': editor.isActive('heading', { level: 3 }) }"
          (click)="this.editor.chain().focus().toggleHeading({ level: 3 }).run()">
          {{ "STRUCTURE.FIELD.HTML_HEADER" | translate }} 3
        </button>
        <button
          id="header-4"
          [ngClass]="{ 'active': editor.isActive('heading', { level: 4 }) }"
          (click)="this.editor.chain().focus().toggleHeading({ level: 4 }).run()">
          {{ "STRUCTURE.FIELD.HTML_HEADER" | translate }} 4
        </button>
        <button
          id="header-5"
          [ngClass]="{ 'active': editor.isActive('heading', { level: 5 }) }"
          (click)="this.editor.chain().focus().toggleHeading({ level: 5 }).run()">
          {{ "STRUCTURE.FIELD.HTML_HEADER" | translate }} 5
        </button>
        <button
          id="header-6"
          [ngClass]="{ 'active': editor.isActive('heading', { level: 6 }) }"
          (click)="this.editor.chain().focus().toggleHeading({ level: 6 }).run()">
          {{ "STRUCTURE.FIELD.HTML_HEADER" | translate }} 6
        </button>
      </div>
    </div>

    <div class="link-container">
      <button
        id="link-button"
        [ngClass]="{ 'active': editor.isActive('link') }"
        (click)="isLinkMenuOpen = !isLinkMenuOpen">
      </button>
      <div
        id="link-dropdown"
        *ngIf="isLinkMenuOpen">
        <label for="url">{{ "STRUCTURE.FIELD.HTML_URL" | translate }}</label>
        <input
          type="url"
          id="url"
          #linkUrl />
        <button (click)="setLink(linkUrl.value)">{{ "STRUCTURE.FIELD.HTML_SET_LINK" | translate }}</button>
      </div>
    </div>

    <button
      id="text-color"
      (click)="openTextColorPicker()"
      [ngClass]="{ 'active': editor.isActive('textStyle') }">
      <input #textColorInput type="color" (input)="onTextColorChange($event)" />
    </button>

    <button
      id="bg-color"
      (click)="openHighlightColorPicker()"
      [ngClass]="{ 'active': editor.isActive('highlight') }">
      <input #highlightColorInput type="color" (input)="onHighlightColorChange($event)" />
    </button>

    <button
      id="text-left"
      [ngClass]="{ 'active': editor.isActive({ textAlign: 'left' }) }"
      (click)="editor.chain().focus().setTextAlign('left').run()">
    </button>

    <button
      id="text-center"
      [ngClass]="{ 'active': editor.isActive({ textAlign: 'center' }) }"
      (click)="editor.chain().focus().setTextAlign('center').run()">
    </button>

    <button
      id="text-right"
      [ngClass]="{ 'active': editor.isActive({ textAlign: 'right' }) }"
      (click)="editor.chain().focus().setTextAlign('right').run()">
    </button>

    <button
      id="text-justify"
      [ngClass]="{ 'active': editor.isActive({ textAlign: 'justify' }) }"
      (click)="editor.chain().focus().setTextAlign('justify').run()">
    </button>

    <button
      id="superscript"
      [ngClass]="{ 'active': editor.isActive('superscript') }"
      (click)="editor.chain().focus().toggleSuperscript().run()">
    </button>

    <button
      id="subscript"
      [ngClass]="{ 'active': editor.isActive('subscript') }"
      (click)="editor.chain().focus().toggleSubscript().run()">
    </button>

    <button
      id="add-table"
      [ngClass]="{ 'active': editor.isActive('table') }"
      (click)="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()">
    </button>

    @if (editor.isActive('table')) {
      <button id="add-row" (click)="editor.chain().focus().addRowAfter().run()"></button>
      <button id="add-column" (click)="editor.chain().focus().addColumnAfter().run()"></button>
      <button id="delete-row" (click)="editor.chain().focus().deleteRow().run()"></button>
      <button id="delete-column" (click)="editor.chain().focus().deleteColumn().run()"></button>
      <button id="delete-table" (click)="editor.chain().focus().deleteTable().run()"></button>
    }
  </div>
  <tiptap-editor [editor]="editor" [formControl]="formControl" [ariaDisabled]="readonly"></tiptap-editor>
</div>
