<ng-container *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate"></ng-container>
<ng-container *ngIf="tableHeaderTemplate && _itemsRows.length" [ngTemplateOutlet]="tableHeaderTemplate"></ng-container>
<div
	class="list-viewport"
	[class.hidden]="!_itemsRows.length"
	[ngClass]="{
		'with-header': headerTemplate,
		'with-header-template': tableHeaderTemplate && _itemsRows.length
	}"
	cdkDropList
	[cdkDropListDisabled]="!draggable"
	(cdkDropListDropped)="drop($event)">
	<div class="list-row" *ngFor="let item of _itemsRows; let i = index" cdkDrag (click)="rowClick.emit(item)">
		<ng-container *ngIf="rowTemplate" [ngTemplateOutlet]="rowTemplate" [ngTemplateOutletContext]="{item}"></ng-container>
	</div>
</div>
<ng-container *ngIf="noItemsTemplate && !_itemsRows.length" [ngTemplateOutlet]="noItemsTemplate"> </ng-container>
