import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs';
import { AuthService } from '../../../../shared/services/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard {
	constructor(private authService: AuthService) {}

	canActivate() {
		return this.authService.loaded$.pipe(
			switchMap(() => this.authService.canActivateProtectedRoutes$),
			tap((allowed) => {
				if (!allowed) this.authService.login();
			}),
		);
	}
}
