import { createAction, props } from '@ngrx/store';
import { FeatureValue, FeatureValueType } from 'addiction-components';

/* READ */
export const loadFeatures = createAction('[Features] Load Features', props<{ featureType: FeatureValueType[] }>());
export const reloadFeatures = createAction('[Features] Reload Features');
export const loadFeaturesSuccess = createAction('[Features] Load Features Success', props<{ features: FeatureValue[] }>());
export const loadFeaturesFailure = createAction('[Features] Load Features Failure', props<{ error: unknown }>());

/* CREATE */
export const createFeature = createAction('[Features] Create Feature', props<{ feature: FeatureValue }>());
export const createFeatureSuccess = createAction('[Features] Create Feature Success', props<{ feature: FeatureValue }>());
export const createFeatureFailure = createAction('[Features] Create Feature Failure', props<{ error: unknown }>());

/* UPDATE */
export const updateFeature = createAction('[Features] Update Feature', props<{ feature: FeatureValue; uuid: string }>());
export const updateFeatureSuccess = createAction('[Features] Update Feature Success', props<{ feature: FeatureValue }>());
export const updateFeatureFailure = createAction('[Features] Update Feature Failure', props<{ error: unknown }>());

/* CLONE */
export const cloneFeature = createAction('[Features] Clone Feature', props<{ feature: FeatureValue }>());
export const cloneFeatureSuccess = createAction('[Features] Clone Feature Success', props<{ feature: FeatureValue }>());
export const cloneFeatureFailure = createAction('[Features] Clone Feature Failure', props<{ error: unknown }>());

export const changeFeatureOrder = createAction('[Features] Change Feature Order', props<{ featuresToUpdate: FeatureValue[] }>());

/* DELETE */
export const deleteFeature = createAction('[Features] Delete Feature', props<{ uuid: string }>());
export const deleteFeatureSuccess = createAction('[Features] Delete Feature Success', props<{ uuid: string }>());
export const deleteFeatureFailure = createAction('[Features] Delete Feature Failure', props<{ error: unknown }>());

/* SELECTION */
export const selectFeature = createAction('[Features] Select Feature', props<{ uuid?: string }>());
export const editFeature = createAction('[Features] Edit Feature', props<{ uuid: string }>());

export const featureTypeChanged = createAction('[Features] Feature Type Changed', props<{ featureType: FeatureValueType[] }>());
export const changeFeatureSelected = createAction('[Features] Change Feature Selected', props<{ uuid: string }>());
export const createNewFeature = createAction('[Features] Create New Feature', props<{ parentUUID?: string }>());
export const resetCache = createAction('[Features] reset cache');
