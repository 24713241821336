import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, combineLatestWith, debounce, distinctUntilChanged, filter, first, map, of, switchMap, tap } from 'rxjs';
import { CommunitySelectActions, SmartGroupsActions } from 'src/app/core/state/app.actions';
import { CommunitySelectSelectors, SmartGroupsSelectors } from 'src/app/core/state/app.selectors';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { DataleanSmartGroupService } from '../services/smart-groups.service';
import { setDeleteDataleanSmartGroupError, setSmartGroupsSuccess } from './smart-groups.actions';
import { selectCachedPages, selectGridSearch, selectPagedData, selectPages, selectSort } from './smart-groups.selectors';

@Injectable()
export class SmartGroupsEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private groupService = inject(DataleanSmartGroupService);
	private activeRoute = inject(Router);

	constructor() {}

	fetchSmartGroups$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(SmartGroupsActions.setPages, SmartGroupsActions.setForceRefreshSmartGroups),
			concatLatestFrom(() => [
				this.store.select(SmartGroupsSelectors.selectPages),
				this.store.select(SmartGroupsSelectors.selectSort),
				this.store.select(SmartGroupsSelectors.selectGridSearch),
				this.store.select(SmartGroupsSelectors.selectCachedPages),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
			]),
			debounce(() =>
				this.store.select(SmartGroupsSelectors.selectStatus).pipe(
					filter((stat) => {
						return !stat || stat !== 'loading';
					})
				)
			),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(SmartGroupsActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, cachedPages, communityUUID]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;
				return this.groupService.fetchSmartGroups(actualPages, sort, gridSearch, communityUUID).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						startingResult.pages = cachedPages;

						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.numberOfPage);
								acc.totalSmartGroupsCount = item.paginationInfo.totalNumberOfElements;
								if (item.result) {
									acc.groups[item.paginationInfo.numberOfPage] = item.result;
								}
							}
							return acc;
						}, structuredClone(startingResult));

						return SmartGroupsActions.setSmartGroupsSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						return of(SmartGroupsActions.setSmartGroupsError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SmartGroupsActions.setGridSearch, SmartGroupsActions.setSort, CommunitySelectActions.setLastCommunitySelected),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCachedPages),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(([, pagesPrev, sortPrev, gridSearchPrev], [, pagesCurr, sortCurr, gridSearchCurr, cachedPages]) => {
				return (
					pagesPrev.length === pagesCurr.length &&
					pagesPrev.every((f) => pagesCurr.includes(f)) &&
					sortCurr?.active === sortPrev?.active &&
					sortCurr?.direction === sortPrev?.direction &&
					gridSearchCurr === gridSearchPrev &&
					pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
				);
			}),
			map(() => SmartGroupsActions.resetCache())
		)
	);
	deleteSmartGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SmartGroupsActions.setDeleteDataleanSmartGroup),
			switchMap(({ group }) => {
				return this.groupService.deleteSmartGroup(group).pipe(
					map(() => {
						return SmartGroupsActions.setDeleteDataleanSmartGroupSuccess();
					}),
					catchError((error: HttpErrorResponse) => of(SmartGroupsActions.setSmartGroupsError({ error })))
				);
			})
		)
	);

	deleteSmartGroups$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SmartGroupsActions.setDeleteDataleanSmartGroupSuccess, setDeleteDataleanSmartGroupError),
			map(() => SmartGroupsActions.resetCache())
		)
	);

	groupSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setSmartGroupsSuccess),
			map(({ data: { pages } }) => SmartGroupsActions.setCachedPages({ pages }))
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SmartGroupsActions.resetCache),
			map(() => SmartGroupsActions.setForceRefreshSmartGroups())
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url.toString().includes('smart-groups')),
			map(() => SmartGroupsActions.resetCache())
		)
	);
}
