import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { Observer } from 'rxjs';
import { SharedModule } from '../../modules/shared.module';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
	selector: 'addiction-radio',
	templateUrl: './radio-field.component.html',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, MatRadioModule, SharedModule, MatTooltipModule],
	providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: RadioFieldComponent }],
})
export class RadioFieldComponent<T> implements ControlValueAccessor {
	@Input() value?: T;
	@Input() label: string = '';
	@Input() options: Array<{ value: T; label?: string }> | undefined;
	@Input() readonly: boolean = false;
	@Input() hint?: string;

	@Output() valueChange = new EventEmitter<T>();

	protected destroyRef = inject(DestroyRef);

	formControl = new FormControl();

	constructor() {
		this.formControl.setValue(this.value);
		this.formControl.valueChanges.pipe(takeUntilDestroyed()).subscribe({
			next: (value) => this.valueChange.emit(value),
		});
	}

	public onTouched: () => void = () => {};

	writeValue(obj: string): void {
		if (typeof obj == 'string') {
			this.formControl.setValue(obj);
		}
	}

	registerOnChange(fn: Observer<string>): void {
		this.formControl.valueChanges.subscribe(fn);
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
		if (disabled) this.formControl.disable({ emitEvent: false });
		else this.formControl.enable({ emitEvent: false });
	}
}
