import { DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';

@Component({
	selector: 'addiction-list-draggable',
	standalone: true,
	imports: [CommonModule, DragDropModule],
	templateUrl: './list-draggable.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListDraggableComponent<T> implements OnChanges {
	_itemsRows: T[] = [];
	isItemsRowsEmpty: boolean = false;

	@Input() itemSize: number = 50;
	@Input() headerTemplate?: TemplateRef<unknown>;
	@Input() tableHeaderTemplate?: TemplateRef<unknown>;
	@Input() rowTemplate?: TemplateRef<unknown>;
	@Input() noItemsTemplate?: TemplateRef<unknown>;
	@Input({ required: true }) pageSize: number = 20;
	@Input({ required: true }) data?: { items: T[][]; pages?: number[]; totalItems?: number };
	@Input() draggable = false;
	@Output() rowClick = new EventEmitter<T>();
	@Output() move = new EventEmitter<{ prevPosition: number; currentPosition: number }>();

	_availablePages: number[] = [];

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['data'] && changes['data'].currentValue) {
			this.data = changes['data'].currentValue;
			if (!this.data) return;
			this._availablePages = this.data.pages ? [...this.data.pages] : [...(this.data.items?.length ? this.data.items.map((_, index) => index) : [])];
			let arrayLength = this.data.totalItems;
			if (!arrayLength) {
				if (arrayLength === 0) {
					this.isItemsRowsEmpty = true;
				} else {
					arrayLength = this.data.items?.length > 0 ? this.data.items[0].length : 20;
				}
			}
			const tmpArray = Array.from<T>({ length: arrayLength });
			if (tmpArray.length > 0) {
				for (const page of this._availablePages) {
					const dataPage = this.data.items[page];
					if (dataPage) {
						tmpArray.splice(page * this.pageSize, this.data.items[page].length, ...this.data.items[page]);
					}
				}
			}
			this._itemsRows = [...tmpArray];
		}
	}

	drop(event: any): void {
		const previousIndex = event.previousIndex;
		const currentIndex = event.currentIndex;
		moveItemInArray(this._itemsRows, previousIndex, currentIndex);
		this.move.emit({ prevPosition: previousIndex, currentPosition: currentIndex });
	}
}
