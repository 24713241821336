import { Reservation, ReservationStatus } from './../../../../shared/models/reservations/reservation.interface';
import { Injectable, inject } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DataleanBaseApiService, DataleanPagedResult, PaginationInfo, Parts, SimpleObject, SortInfo } from 'addiction-components';
import { Observable, Subject, forkJoin, tap } from 'rxjs';
import { HeaderLocaleService } from 'src/app/components';
import { CalendarActions } from 'src/app/core/state/app.actions';
import { environment } from 'src/environments/environment';
import { FiltersType } from '../state/calendar.reducers';

@Injectable({ providedIn: 'root' })
export class CalendarService {
	private store = inject(Store);
	private translateService = inject(TranslateService);
	private headerSrv = inject(HeaderLocaleService);
	private baseApi = inject(DataleanBaseApiService);
	private destroyed$ = new Subject();
	private reservationUrl = `${environment.reservationUrl}`;

	constructor() {}

	destroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	getReservations(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: { uuids?: string[]; structureUUID?: string; query?: string };
	}) {
		const { filters, parts, locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name']; // parametrizzare i campi di ricerca??
		}

		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		return this.baseApi.getManyPaged<Reservation>(this.reservationUrl, parts ?? [Parts.ALL], {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	countReservations(filters: { structureUUID?: string }) {
		return this.baseApi.getOne<{ count: number }>(this.reservationUrl + 'count', '', [], {
			organizationUUID: environment.organizationUUID,
			...filters,
			active: true,
		});
	}

	fetchReservations(
		pages: number[],
		sort?: Sort,
		gridSearch?: string | null,
		filters?: Partial<FiltersType>,
		communityUUID?: string
	): Observable<DataleanPagedResult<Reservation>[]> {
		const obs: Observable<DataleanPagedResult<Reservation>>[] = [];

		const params: {
			sortBy?: string;
			q?: string;
			searchFields?: string;
			locale?: string;
			//TODO: Da reimplementare una volta compreso come filtrare le reservation per data
			/* 			'date.from.value'?: string | Date;
			'date.to.value'?: string | Date; */
			status?: ReservationStatus;
			productId?: string;
			communityUUID?: string;
		} = {};

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'product.name,user.email,uuid';
		}

		//TODO: Da reimplementare una volta compreso come filtrare le reservation per data
		/* 		if(filters?.dateFrom) {
			params['date.from.value'] = filters.dateFrom;
		}
		if(filters?.dateTo) {
			params['date.to.value'] = filters.dateTo;
		} */
		if (filters?.status) {
			params.status = filters.status;
		}
		if (filters?.productUUID) {
			params.productId = filters.productUUID;
		}

		if (communityUUID) params.communityUUID = communityUUID;

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Reservation>(this.reservationUrl, [Parts.FEATURE_VALUE_LIST, Parts.LESSON_CONTENT_LIST, Parts.UPDATE_DATE], {
					pagination,
					additionalParams: params,
				})
			);
		}
		return forkJoin(obs);
	}

	deleteReservation(uuid: string) {
		return this.baseApi.deleteOne(this.reservationUrl, uuid);
	}

	updateReservation(reservation: Reservation) {
		return this.baseApi
			.updateEntity(this.reservationUrl, reservation, [Parts.ALL])
			.pipe(tap(() => this.store.dispatch(CalendarActions.setForceRefreshCalendars())));
	}

	getReservation(uuid: string) {
		return this.baseApi.getEntity<Reservation>(this.reservationUrl, uuid, [Parts.ALL]);
	}
}
