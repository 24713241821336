export interface Thumbnail {
	configUUID: string;
	configName: string;
	url: string;
	bytes?: number;
	format?: string;
	width: string; // IN px OR 'auto'
	height: string; // IN px OR 'auto'
}

export interface ThumbnailConfig {
	uuid: string;
	name: string;
	width: string; // IN px OR 'auto'
	height: string; // IN px OR 'auto'
	horizontalPosition?: 'left' | 'center' | 'right';
	verticalPosition?: 'top' | 'center' | 'bottom';
	method?: 'crop' | 'fill';
	localizedValues?: { locale: string }[];
	structureUUID?: string;
	schemaVersion?: string;
	organizationUUID?: string;
	createDate?: string | Date;
	updateDate?: string | Date;
}

export type ThumbnailConfigFE = ThumbnailConfig & Omit<Thumbnail, 'configUUID' | 'configName'>;

export const mergeThumbnailConfigAndThumbnail = (thumbnail?: Thumbnail, config?: ThumbnailConfig): ThumbnailConfigFE | undefined => {
	if (thumbnail === undefined || config === undefined || thumbnail.configUUID !== config.uuid) return undefined;

	return {
		uuid: config.uuid,
		name: config.name,
		width: config.width,
		height: config.height,
		horizontalPosition: config.horizontalPosition,
		verticalPosition: config.verticalPosition,
		method: config.method,
		localizedValues: config.localizedValues,
		structureUUID: config.structureUUID,
		schemaVersion: config.schemaVersion,
		organizationUUID: config.organizationUUID,
		updateDate: config.updateDate,

		url: thumbnail.url,
		bytes: thumbnail.bytes,
		format: thumbnail.format,
	};
};
