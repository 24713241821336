import { DataleanEntity, ObjectValuesType } from 'addiction-components';

export const PRACTICE_SECTION_STATUS = {
	PUBLISHED: 'PUBLISHED',
	DRAFT: 'DRAFT',
	ARCHIVED: 'ARCHIVED',
} as const;

export type PracticeSectionStatus = ObjectValuesType<typeof PRACTICE_SECTION_STATUS>;

export interface PracticeSection extends DataleanEntity {
	name: string;
	value: string;
	version: number;
	sectionStatus: PracticeSectionStatus;
}

export const STANDARD_PRACTICE_SECTION_TYPE = {
	TEXT: 'text',
	Q_AND_A: 'question_and_answer',
} as const;

export type StandardPracticeSectionType = ObjectValuesType<typeof STANDARD_PRACTICE_SECTION_TYPE>;

export type StandardPracticeSection = StandardPracticeSectionText | StandardPracticeSectionQAndA;

export interface StandardPracticeSectionText {
	type: (typeof STANDARD_PRACTICE_SECTION_TYPE)['TEXT'];
	value: string;
	name: string;
}

export interface StandardPracticeSectionQAndA {
	type: (typeof STANDARD_PRACTICE_SECTION_TYPE)['Q_AND_A'];
	value: { question: string; answer: string };
	name: string;
}

export interface PracticeSectionObject {
	sectionRef: {
		uuid: string;
		version: number;
	};
	value: string;
}

export type PracticeSectionRef = PracticeSectionObject | StandardPracticeSection;
