import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import {
	ConfigurationValue,
	DataleanPagedResult,
	FeatureValue,
	PaginationInfo,
	Parts,
	SimpleObject,
	SortInfo,
	Structure,
	StructureType,
} from 'addiction-components';
import { Observable, forkJoin } from 'rxjs';
import { ProductActions } from 'src/app/core/state/app.actions';
import { Product } from 'src/app/shared/models';
import { BaseListService } from 'src/app/shared/services/base-list.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ProductService extends BaseListService {
	constructor() {
		super();
		this.endpoint = environment.productUrl;
		this.structureType = StructureType.PRODUCT;
	}

	override dispatchFeatures(features: FeatureValue[]): void {
		this.store.dispatch(ProductActions.setFeatures({ features }));
	}

	override dispatchStructures(structures: Structure[]): void {
		this.store.dispatch(ProductActions.setStructures({ structures }));
	}

	getProducts(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: { uuids?: string[]; structureUUID?: string; query?: string; communityUUID?: string };
	}) {
		const { filters, parts, locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name']; // parametrizzare i campi di ricerca??
		}
		if (filters?.communityUUID) params['communityUUID'] = filters.communityUUID;

		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		return this.baseApi.getManyPaged<Product>(this.endpoint, parts ?? [Parts.ALL], {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	countProducts(filters: { structureUUID?: string; communityUUID?: string; q?: string; searchFields?: string }) {
		return this.baseApi.getOne<{ count: number }>(this.endpoint, 'count', [], {
			organizationUUID: environment.organizationUUID,
			...filters,
		});
	}

	getProductVersioning(productUUID: string, pages: number[], locale: string, sort?: Sort): Observable<DataleanPagedResult<Product>[]> {
		const obs: Observable<DataleanPagedResult<Product>>[] = [];

		const params: {
			productUUID: string;
			locale: string;
			sortBy?: string;
		} = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			productUUID: productUUID,
		};

		if (productUUID) params.productUUID = productUUID;
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Product>(`${this.endpoint}${productUUID}/versions`, [Parts.STATUS, Parts.CREATE_DATE, Parts.ENTITY_VERSION], {
					pagination,
					additionalParams: params,
				}),
			);
		}
		return forkJoin(obs);
	}

	fetchProducts(
		pages: number[],
		sort?: Sort,
		locale?: string,
		gridSearch?: string | null,
		filters?: { featureValue?: string[]; structureUUID?: string; communityUUID?: string; active?: boolean; status?: string | undefined },
		hasVersioning?: ConfigurationValue | null,
	): Observable<DataleanPagedResult<Product>[]> {
		const obs: Observable<DataleanPagedResult<Product>>[] = [];

		const params: {
			sortBy?: string;
			q?: string;
			searchFields?: string;
			locale?: string;
			active?: boolean;
			structureUUID?: string;
			featureValueList?: string;
			communityUUID?: string;
			status?: string;
		} = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;

		if (filters?.featureValue?.length) params['featureValueList'] = filters.featureValue.join(',') + '#and';

		if (filters?.active !== undefined) params['active'] = filters.active;

		if (filters?.communityUUID) params['communityUUID'] = filters.communityUUID;

		if (filters?.status) params['status'] = filters.status;

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name,description';
		}

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Product>(
					this.endpoint,
					[Parts.FEATURE_VALUE_LIST, Parts.LESSON_CONTENT_LIST, Parts.UPDATE_DATE, ...(hasVersioning ? [Parts.ENTITY_VERSION] : [])],
					{
						pagination,
						additionalParams: params,
					},
				),
			);
		}
		return forkJoin(obs);
	}

	public checkIfUserIsEditor(structureUUID: string): Observable<any> {
		return this.baseApi.getEntity(environment.productUrl + 'checkIfUserIsEditor', '', [], { structureUUID });
	}
}
