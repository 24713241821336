import { Asset } from "../models";

export interface SmartWidget {
  uuid?: string,
  schemaVersion?: string,
  organizationUUID?: string,
  updateDate?: string | Date,
  active?: boolean;

  name: string;
  userUUID: string;

  smartWidgetCriteria: SmartWidgetCriteria;
  assets: Asset[];
  assetsCount?: number;
}

export interface SmartWidgetCriteria {
  createDate?: string,
  updateDate?: string,
  tags?: string,
  featureValueList?: string
  assetType?: string
  format?: string
}
