// Type to transform class into interface
export type transformTypeClassIntoInterface<T> = {
	[P in keyof T as T[P] extends (...params: unknown[]) => unknown ? never : P]: T[P];
};

type IfEquals<X, Y, A, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

type WithoutGetters<T> = {
	[P in keyof T]: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
};

export type InterfaceWithoutGetters<T> = Pick<T, WithoutGetters<T>[keyof T]>;
