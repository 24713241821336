import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { ObBuilderActions } from 'src/app/core/state/app.actions';
import { OBTailState } from './ob-builder.state';

export const TAG_OBTAIL = 'obTail';

export const initialState: OBTailState = {
	tails: [],
	loading: false,
	tailType: 'QUARTER',
};

export const reducer = createReducer(
	initialState,
	// GENERIC LOADING\ERROR TAILS
	on(ObBuilderActions.createTail, ObBuilderActions.deleteTail, ObBuilderActions.updateTail, ObBuilderActions.reloadTails, (state) => ({
		...state,
		loading: true,
		error: undefined,
	})),
	on(
		ObBuilderActions.createTailFailure,
		ObBuilderActions.deleteTailFailure,
		ObBuilderActions.updateTailFailure,
		ObBuilderActions.loadTailsFailure,
		(state) => ({
			...state,
			loading: false,
			error: true,
		})
	),
	on(ObBuilderActions.loadTailsSuccess, (state, { tails }) => ({
		...state,
		loading: false,
		tails: tails,
		editingUUID: undefined,
	})),
	on(ObBuilderActions.createTailSuccess, (state, { tail }) => ({
		...state,
		loading: false,
		selectedTail: { ...state.selectedTail, tail },
		editingUUID: undefined,
		error: false,
	})),
	on(ObBuilderActions.deleteTailSuccess, (state, { uuid }) =>
		produce(state, (draft) => {
			draft.loading = false;
			draft.error = false;
			draft.selectedTail = {
				tail: uuid === draft.selectedTail?.tail?.uuid ? undefined : draft.selectedTail?.tail,
			};
		})
	),
	on(ObBuilderActions.updateTail, (state, { tail }) =>
		produce(state, (draft) => {
			draft.loading = true;
			draft.error = false;
			draft.selectedTail = { ...draft.selectedTail, tail };
		})
	),
	on(ObBuilderActions.selectTail, (state, { tail }) => ({
		...state,
		selectedTail: { tail },
	})),
	on(ObBuilderActions.editTail, (state, { uuid }) => ({
		...state,
		editingUUID: uuid,
	})),
	on(ObBuilderActions.setActiveType, (state, { tailType }) => ({
		...state,
		tailType: tailType,
	}))
);
