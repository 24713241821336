/** Classe "helper" che descrive un token di sessione JWT */
export class AuthenticationToken {
	constructor(private token: string, private expirationTimestamp?: string) {}

	getToken() {
		return this.token;
	}

	/** Estrae un parametro codificato nel token JWT (se esiste)  */
	getTokenParam<T>(parameterKey: string): T | undefined {
		const payload = this.getTokenPayload();
		if (!payload) return undefined;
		return this.decodePayload(payload)[parameterKey] as T;
	}

	private getTokenPayload() {
		// token è una stringa in formato JWT
		if (this.token) {
			const tokenComponents = this.token.split('.');
			if (tokenComponents.length === 3) {
				return tokenComponents[1];
			}
		}
		return undefined;
	}

	private decodePayload(payload: string): Record<string, unknown> {
		try {
			return JSON.parse(atob(payload));
		} catch (e) {
      console.error('Errore durante il parsing del payload del token:', e);
			return {};
		}
	}
}
