import { FormControl } from '@angular/forms';

export interface IPermission {
	uuid?: string;
	name?: string;
}

export interface PermissionFormGroup {
	[key: string]: FormControl<boolean>;
}
