import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageHandler } from 'addiction-components';
import { catchError, map, of, switchMap } from 'rxjs';
import { ObConfiguratorActions } from 'src/app/core/state/app.actions';
import { ObConfiguratorService } from '../services/ob-configurator.service';

@Injectable()
export class OBObjectiveEffect {
	private actions$ = inject(Actions);
	private obConfiguratorSrv = inject(ObConfiguratorService);
	private store = inject(Store);
	private messageHandler = inject(MessageHandler);
	private translateService = inject(TranslateService);

	loadObjective$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				ObConfiguratorActions.loadObjectives,
				ObConfiguratorActions.reloadObjectives,
				ObConfiguratorActions.createObjectiveSuccess,
				ObConfiguratorActions.updateObjectiveSuccess,
				ObConfiguratorActions.deleteObjectiveSuccess
			),
			switchMap(() =>
				this.obConfiguratorSrv.getObjectives().pipe(
					map((objectives) => ObConfiguratorActions.loadObjectivesSuccess({ objectives })),
					catchError((error) => of(ObConfiguratorActions.loadObjectivesFailure({ error })))
				)
			)
		)
	);
	// UPDATE ONE
	updateObjective$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.updateObjective),
			switchMap(({ objective }) =>
				this.obConfiguratorSrv.updateObjective(objective).pipe(
					map((objective) => {
						this.messageHandler.createToast('ALERT.SUCCESS_SAVE_MESSAGE');
						return ObConfiguratorActions.updateObjectiveSuccess({ objective });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('ALERT.ERROR_SAVE_MESSAGE');
						return of(ObConfiguratorActions.updateObjectiveFailure({ error }));
					})
				)
			)
		)
	);

	// CREATE ONE
	createObjective$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.createObjective),
			switchMap(({ objective }) =>
				this.obConfiguratorSrv.createObjective(objective).pipe(
					map((objective) => {
						this.messageHandler.createToast('ALERT.SUCCESS_SAVE_MESSAGE');
						return ObConfiguratorActions.createObjectiveSuccess({ objective });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('ALERT.ERROR_SAVE_MESSAGE');
						return of(ObConfiguratorActions.createObjectiveFailure({ error }));
					})
				)
			)
		)
	);

	// DELETE ONE
	deleteObjective$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.deleteObjective),
			switchMap(({ uuid }) =>
				this.obConfiguratorSrv.deleteObjective(uuid).pipe(
					map(() => ObConfiguratorActions.deleteObjectiveSuccess({ uuid })),
					catchError((error) => of(ObConfiguratorActions.deleteObjectiveFailure({ error })))
				)
			)
		)
	);

	selectedObjective$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.changeObjectiveSelected),
			map(({ objective }) => ObConfiguratorActions.selectObjective({ objective }))
		)
	);

	selectedEditableObjective$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.changeObjectiveSelected),
			map(({ objective }) => ObConfiguratorActions.editObjective({ uuid: objective.uuid }))
		)
	);

	editNewObjective$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.createNewObjective),
			map(() => ObConfiguratorActions.editObjective({ uuid: 'new' }))
		)
	);
}
