import { LocalizableField } from '../localizable-field';
import { ExtraStructureFieldType } from './structure-field-types.enum';

export interface StructureField {
	uuid: string;
	isUnique?: boolean;
	child?: boolean;
	isVisibleInApp?: boolean;
	name: string;
	type: ExtraStructureFieldType;
	repeatable?: boolean;
	extra?: string;
	label?: LocalizableField | string;
	predefinedValue?: LocalizableField | string | boolean | number | unknown[] | null;
	hint?: LocalizableField | string;
	parsedExtra?:
		| Array<{ value: string | number; label: string }>
		| { url: string; label: { [key: string]: string } }
		| { [key: string]: string | boolean }
		| string;
	value?: string;
	order?: number;
	isRequired: boolean;
	inputTypeRequired?: string;
	formatRequired?: string;
	enableOn?: string;
	fixed?: boolean;
	relatedStructureType?: string;
	isVariant?: boolean;
	readOnly?: boolean;
	showCollapsed?: boolean;
	mobileRatio?: number;
	tabletRatio?: number;
	desktopRatio?: number;
	clonable?: boolean;
	editable?: boolean;
	fields?: StructureField[];
	localizable?: boolean;
	viewInCard?: string;
	structureConnected?: string;
	parentValue?: string;
	rolesCantSee?: { uuid: string; name: string }[] | null;
	rolesReadonly?: { uuid: string; name: string }[] | null;
	hidden?: boolean;
}

export interface UserStructureField extends Omit<StructureField, 'fields'> {
	fields?: UserStructureField[];
}
