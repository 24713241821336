import { FeatureValue } from "../feature/feature-value.model";
import { UnknownObject } from "../unkownkObject";

export interface Product {
	uuid: string;
	order: number;
	locale?: string;
	active: boolean;
	structure?: { name: string }[];
	featureValueList: Array<FeatureValue>;
	related?: Array<Product>;
	entityVersion?: number;
	status?: string;
	localizedValues?: { locale: string; [key: string]: unknown }[];
	updateDate?: string;
	[key: string]: unknown | UnknownObject;
}