import { NgModule } from '@angular/core';
import { AspectRatioDirective, AssetImageDirective, RepeatDirective } from '../directives';
import { ForbidCharsDirective, ToLocalizedValuePipe } from 'addiction-components';
import { BytesPipe } from '../pipes/bytes.pipe';

@NgModule({
	declarations: [AssetImageDirective, AspectRatioDirective, BytesPipe, RepeatDirective],
	imports: [ForbidCharsDirective],
	exports: [AssetImageDirective, ForbidCharsDirective, BytesPipe, RepeatDirective],
	providers: [ToLocalizedValuePipe],
})
export class DataleanDirectiveModule {}
