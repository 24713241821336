<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
	<span *ngFor="let child of items">
		<!-- Handle branch node menu items -->
		<span mat-menu-item [matMenuTriggerFor]="menu.childMenu" *ngIf="child.featureValues && child.featureValues.length > 0">
			<mat-checkbox (change)="featureValueChanged($event, child.uuid)" [checked]="featureValueAlreadySelected(child.uuid)">
				{{ child.name }}
			</mat-checkbox>
			<datalean-feature-value-mat-menu (featureValueChange)="featureValueChanged($event.event, $event.featureValueUUID)" #menu [items]="child.featureValues" [itemsSelected]="this.itemsSelected"></datalean-feature-value-mat-menu>
		</span>
		<!-- Handle leaf node men u items -->
		<span
			mat-menu-item
			*ngIf="!child.featureValues || child.featureValues.length === 0"
			(mousedown)="$event.stopPropagation()"
			(click)="$event.stopPropagation()">
			<mat-checkbox (change)="featureValueChanged($event, child.uuid)" [checked]="featureValueAlreadySelected(child.uuid)">
				{{ child.name }}
			</mat-checkbox>
		</span>
	</span>
</mat-menu>
