<form class="form-group-container" [formGroup]="formGroup">
	@if (field) {
		<addiction-autocomplete
			[readonly]="!!field.readOnly"
			[formControlName]="'substructureSelector'"
			[options]="(options$ | async) ?? []"
			[enableCalculateOptionWhenEmpty]="true"
			(valueSelected)="valueChangedSelectAutocomplete($event)">
		</addiction-autocomplete>

    @if (selectedStructure$ | async; as selectedStructure) {
      <div class="substructure-container">
        <datalean-container [structureFields]="selectedStructure.structureFields" [defaultValues]="dataset" [readonly]="!!field.readOnly">
        </datalean-container>
      </div>
    }
  }
</form>
