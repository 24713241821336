/* eslint-disable @typescript-eslint/no-non-null-assertion */

/** Manager generico di una struttura ad albero, configurabile */
export class TreeManager<T> {
	constructor(private config: TreeManagerConfig<T>) {}

	findNodes(root: Partial<T>, predicate: (node: T) => boolean): T[] {
		if (!this.hasChildren(root)) return [];

		const result: T[] = [];
		for (const child of this.getChildren(root)!) {
			if (predicate(child)) result.push(child);

			result.push(...this.findNodes(child, predicate));
		}

		return result;
	}
	findNodesByID(root: Partial<T>, ids: string[]): T[] {
		return this.findNodes(root, (n) => ids.includes(this.getID(n)));
	}

	findNode(root: Partial<T>, predicate: (node: T) => boolean): T | undefined {
		return this.findNodes(root, predicate)[0];
	}
	findNodeByID(root: Partial<T>, id: string): T | undefined {
		return this.findNode(root, (n) => this.getID(n) == id);
	}

	/** Sposta dei nodi ad un nuovo parent */
	moveNodesTo(root: Partial<T>, ids: string[], newParent: string | undefined): void {
		const destination = this.extractNodes(root, (n) => ids.includes(this.getID(n)));

		for (const dest of destination) this.setParent(dest, newParent ?? null);

		this.addNodes(root, destination);
	}

	/** Rimuove (e restituisce) i nodi specificati dalla struttura ad albero */
	extractNodes(root: Partial<T>, predicate: (node: T) => boolean): T[] {
		let destinations: T[] = [];
		const remaining: T[] = [];

		if (!this.hasChildren(root)) return destinations;

		for (const child of this.getChildren(root)!) {
			if (predicate(child)) destinations.push(child);
			else remaining.push(child);

			destinations = [...destinations, ...this.extractNodes(child, predicate)];
		}
		this.setChildren(root, remaining);
		return destinations;
	}
	/** Rimuove (e restituisce) i nodi specificati dalla struttura ad albero */
	extractNodesByID(root: Partial<T>, ids: string[]): T[] {
		return this.extractNodes(root, (n) => ids.includes(this.getID(n)));
	}

	/** Aggiunge una serie di nodi alla struttura ad albero */
	addNodes(root: Partial<T>, nodes: T[]): void {
		for (const child of this.getChildren(root) ?? []) this.addNodes(child, nodes);

		const newChildren = nodes.filter((f) => this.getParent(f) == this.getID(root));

		let children = [...(this.getChildren(root) ?? []), ...newChildren];
		if (this.config.sortByKey) children = children.sortBy((s) => this.getSortProp(s));

		this.setChildren(root, children);
	}

	/** Rimuove dei nodi dall'albero selezionato */
	removeNodes(root: Partial<T>, predicate: (node: T) => boolean): void {
		if (!this.hasChildren(root)) return;

		const children = this.getChildren(root)!;
		this.setChildren(
			root,
			children.filter((child) => !predicate(child))
		);

		for (const child of this.getChildren(root)!) this.removeNodes(child, predicate);
	}
	/** Rimuove dei nodi dall'albero selezionato */
	removeNodesByID(root: Partial<T>, ids: string[]): void {
		this.removeNodes(root, (n) => ids.includes(this.getID(n)));
	}

	/** Replace nodes */
	replaceNodes(root: Partial<T>, nodes: Partial<T>[], preserveHierarchy: boolean): void {
		const ids = nodes.map((n) => this.getID(n));
		const oldNodes = this.extractNodes(root, (n) => ids.includes(this.getID(n)));

		if (preserveHierarchy) {
			// must ensure the node is not moved to a different place
			this.addNodes(
				root,
				oldNodes.map((oldNode) => {
					const newNode = { ...nodes.find((n) => this.getID(n) == this.getID(oldNode))! };
					// remove hierarchy properties so it is still in its original place
					delete newNode[this.config.childrenProp];
					delete newNode[this.config.parentProp];
					return { ...oldNode, ...newNode } as T;
				})
			);
		} else {
			this.addNodes(root, nodes as T[]);
		}
	}

	sort(root: Partial<T>, predicate?: (node: T) => number) {
		if (!predicate) predicate = (node) => this.getSortProp(node);

		if (!this.hasChildren(root)) return;

		this.setChildren(root, this.getChildren(root)!.sortBy(predicate));

		for (const child of this.getChildren(root)!) this.sort(child, predicate);
	}

	getPathToNode(root: Partial<T>, nodeKey: string, includeNode: boolean = false): T[] | undefined {
		const node = this.findNodeByID(root, nodeKey);
		if (!node) return undefined;

		const path: T[] = includeNode ? [node] : [];
		let current = this.findNode(root, (n) => this.getID(n) == this.getParent(node));
		while (current && this.getID(current) != this.getID(root)) {
			path.push(current);
			current = this.findNode(root, (n) => this.getID(n) == this.getParent(current!));
		}

		return path.reverse();
	}

	protected hasChildren(node: Partial<T>): boolean {
		return Array.isArray(node[this.config.childrenProp]);
	}
	protected getChildren(node: Partial<T>): T[] | undefined {
		if (this.hasChildren(node)) return node[this.config.childrenProp] as unknown as Array<T>;
		return undefined;
	}

	protected setChildren(node: Partial<T>, children: T[]) {
		(node[this.config.childrenProp] as unknown) = children;
	}
	protected getID(node: Partial<T>): string {
		return node[this.config.keyProp] as unknown as string;
	}

	protected getParent(node: Partial<T>): string | undefined {
		return node[this.config.parentProp] as unknown as string | undefined;
	}
	protected setParent(node: Partial<T>, parent: string | null) {
		if (this.config.parentProp) (node[this.config.parentProp] as unknown) = parent;
	}
	protected getSortProp(node: Partial<T>): number {
		if (this.config.sortByKey) return node[this.config.sortByKey] as unknown as number;
		return 0;
	}
}
export interface TreeManagerConfig<T> {
	keyProp: keyof T;
	childrenProp: keyof T;
	parentProp: keyof T;
	sortByKey?: keyof T;
}
