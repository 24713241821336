import { createReducer, on } from '@ngrx/store';
import { ContestActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { ContestState } from './contest.state';

export const TAG = 'contests';

const initialState: ContestState = {
	sort: { active: 'updateDate', direction: 'desc' },
	pages: [0],
	data: { totalContestsCount: 0, contests: [] },
	status: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	deleteStatus: 'unknown',
	cachedPages: [],
};

export const counterReducer = createReducer(
	initialState,
	/**
	 * Parte standard di un reducer
	 */
	on(ContestActions.setSort, (state: ContestState, { sort }) => ({
		...state,
		sort,
	})),
	on(ContestActions.setPages, (state: ContestState, { pages }) => ({
		...state,
		pages,
	})),
	on(ContestActions.setContestsSuccess, (state: ContestState, { data }) => ({
		...state,
		status: STATE_STATUS.READY,
		data: {
			contests: data.contests,
			totalContestsCount: data.totalContestsCount,
		},
		pages: [...state.cachedPages, ...data.pages],
	})),
	on(ContestActions.setContestsError, (state: ContestState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(ContestActions.setStatus, (state: ContestState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(ContestActions.setGridSearch, (state: ContestState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(ContestActions.setDeleteContests, (state: ContestState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(ContestActions.setDeleteContestsSuccess, (state: ContestState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(ContestActions.setDeleteContestsError, (state: ContestState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
	on(ContestActions.setCachedPages, (state: ContestState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(ContestActions.resetCache, (state: ContestState) => ({
		...state,
		cachedPages: [],
		status: STATE_STATUS.READY,
		pages: [0],
		data: { totalContestsCount: 0, contests: [] },
	}))
);
