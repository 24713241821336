import { UserStructureField } from '../../models';

export class UserValue {
  static readonly UUID_FIELD_KEY = 'uuid';
  static readonly STRUCTURE_FIELD_UUID_FIELD_KEY = 'structureFieldUUID';
  static readonly STRUCTURE_FIELD_NAME_FIELD_KEY = 'structureFieldName';
  static readonly TYPE_FIELD_KEY = 'type';
  static readonly VALUE_FIELD_KEY = 'value';

  id: string | undefined;
  structureFieldUUID: string | undefined;
  structureFieldName: string | undefined;
  type: string | undefined;
  value: string | undefined;

  //TODO remove when migrate to new be
  public userStructureField: UserStructureField | undefined;

}
