<ng-container *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate"></ng-container>
<cdk-virtual-scroll-viewport [appendOnly]="false" [itemSize]="approxCardHeight" class="grid-viewport" (scrolledIndexChange)="onScroll()">
	<div class="grid-row" *cdkVirtualFor="let group of _itemsRows; let i = index; trackBy: trackBy">
		<div *ngFor="let item of group; let j = index" class="grid-card" (click)="cardClick.emit(item)" [ngStyle]="{ maxWidth: 100 / _columns + '%' }">
			<ng-container *ngIf="cardTemplate" [ngTemplateOutlet]="cardTemplate" [ngTemplateOutletContext]="{item, index: (i)*_columns+j+1}">
			</ng-container>
		</div>
	</div>
</cdk-virtual-scroll-viewport>
<ng-container *ngIf="noItemsTemplate && !_itemsRows.length" [ngTemplateOutlet]="noItemsTemplate"> </ng-container>
