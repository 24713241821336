import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	ValidationErrors,
	Validator,
	Validators,
} from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Phone } from '../../models';
import { InputComponent } from '../input/input.component';

@Component({
	selector: 'addiction-phone',
	standalone: true,
	imports: [CommonModule, InputComponent, TranslateModule, ReactiveFormsModule, MatTooltipModule],
	templateUrl: './phone.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PhoneComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => PhoneComponent),
			multi: true,
		},
	],
})
export class PhoneComponent implements ControlValueAccessor, Validator {
	@Input() label: string = '';
	@Input() readonly: boolean = false;
	@Input() hint?: string;

	formGroup = new FormGroup({
		phoneNumber: new FormControl<string | null>(null, [Validators.pattern('^\\+?(?:[0-9] ?){6,14}[0-9]$')]),
		phoneText: new FormControl<string | null>(null),
	});

	constructor() {}

	public onTouched: () => void = () => {};

	writeValue(value: Phone): void {
		if (value && this.isValuePhone(value)) {
			this.formGroup.patchValue(value);
		}
	}
	registerOnChange(fn: any): void {
		this.formGroup.valueChanges.subscribe(fn);
	}
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
	setDisabledState(disabled: boolean) {
		if (disabled) {
			this.formGroup.disable({ emitEvent: false });
		} else {
			this.formGroup.enable({ emitEvent: false });
		}
	}
	validate(c: AbstractControl<any, any>): ValidationErrors | null {
		if (this.formGroup.invalid) {
			return { invalidForm: { valid: false, message: 'Phone form fields are invalid' } };
		}
		return null;
	}
	registerOnValidatorChange(fn: () => void): void {
		this.formGroup.statusChanges.subscribe(fn);
	}
	/**
	 * Metodo per veriface se il valore che viene passato nel writeValue è effettivamente del tipo necessario per patchare la form
	 */
	private isValuePhone(obj: Phone): boolean {
		return 'phoneNumber' in obj && 'phoneText' in obj;
	}
}
