import { LimitType } from "./checkbox-multiple.enum";

export interface Limit {
	value: number;
	type: LimitType;
}

export interface SortedCheckboxes {
	sequenceNumber: number;
	uuid: string;
}
