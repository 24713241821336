import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { DataleanBaseApiService, DataleanPagedResult, PaginationInfo, Parts, SimpleObject, SortInfo } from 'addiction-components';
import { Observable, forkJoin, map, tap } from 'rxjs';
import { HeaderLocaleService } from 'src/app/core/components/header/service/header-locale.service';
import { BEQuiz, Lesson, Quiz } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';
import { resetCache } from '../state/quiz.actions';

@Injectable({ providedIn: 'root' })
export class QuizService {
	private lessonsURL = environment.lmsUrl + 'lesson';
	private quizURL = environment.surveyUrl;

	constructor(private headerSrv: HeaderLocaleService, private baseApi: DataleanBaseApiService, private store: Store) {}

	fetchQuiz(
		pages: number[],
		sort?: Sort,
		locale?: string,
		gridSearch?: string | null,
		communityUUID?: string
	): Observable<DataleanPagedResult<Quiz>[]> {
		const obs: Observable<DataleanPagedResult<Quiz>>[] = [];

		const params: { sortBy?: string; q?: string; searchFields?: string; locale?: string; type: string; communityUUID?: string } = { type: 'quiz' };

		//LOCALE
		if (locale) params.locale = locale ?? this.headerSrv.getActiveLocale();

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'localizedValues.name';
		}
		if (communityUUID) params.communityUUID = communityUUID;

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Quiz>(this.quizURL, [Parts.UPDATE_DATE], {
					pagination,
					additionalParams: params,
				})
			);
		}
		return forkJoin(obs);
	}

	fetchAllLessons(locale?: string, communityUUID?: string): Observable<Lesson[]> {
		const params: { locale?: string; communityUUID?: string } = {};
		if (communityUUID) params.communityUUID = communityUUID;
		if (locale) params.locale = locale;
		return this.baseApi.getEntities<Lesson[]>(this.lessonsURL, params, [Parts.FEATURE_VALUE_LIST, Parts.LESSON_CONTENT_LIST]);
	}

	findQuizzesInLessons(quizUUID: string): Observable<boolean> {
		return this.fetchAllLessons().pipe(
			map((allLessons) => {
				return allLessons.some((lesson) =>
					lesson.contentList?.some(
						(content) => Array.isArray(content.lessonAttachment) && content.lessonAttachment.some((att) => att.uuid === quizUUID)
					)
				);
			})
		);
	}

	getQuizes(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: { uuids?: string[]; query?: string };
	}) {
		const { filters, parts = [], locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name']; // parametrizzare i campi di ricerca??
		}

		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		return this.baseApi.getManyPaged<Quiz>(this.quizURL, parts, {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	deleteQuiz(quiz: Quiz) {
		return this.baseApi.deleteOne(this.quizURL, quiz.uuid);
	}

	getQuiz<T extends BEQuiz | Quiz>(uuid: string, locale?: string) {
		return this.baseApi.getOne<T>(this.quizURL, uuid, [Parts.ALL], { locale });
	}

	createQuiz(quiz: BEQuiz) {
		return this.baseApi.createOne<BEQuiz>(this.quizURL, quiz, [Parts.UPDATE_DATE]).pipe(tap(() => this.store.dispatch(resetCache())));
	}

	updateQuiz(quiz: BEQuiz) {
		return this.baseApi.updateOne<BEQuiz>(this.quizURL, quiz.uuid, quiz, [Parts.UPDATE_DATE]).pipe(tap(() => this.store.dispatch(resetCache())));
	}
}
