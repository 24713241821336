import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TAG_OBTAIL } from './ob-builder-tail.reducers';
import { OBTailState } from './ob-builder.state';

const selectTailsState = createFeatureSelector<OBTailState>(TAG_OBTAIL);

export const selectTails = createSelector(selectTailsState, (state) => state?.tails ?? []);
export const selectTailType = createSelector(selectTailsState, (state) => state?.tailType);
export const selectError = createSelector(selectTailsState, (state) => state.error);
export const selectSelectedTail = createSelector(selectTailsState, (state) => state.selectedTail);
