import { createReducer, on } from '@ngrx/store';
import { WorkFlowConfig, WorkFlowEntity, WorkFlowList, TasksTabs, TASKS_TYPE } from '../../models/workFlow';
import { WorkFlowActions } from '../../../core/state/app.actions';
import { Product, ProductUpdate, STATE_STATUS, TabOption } from '../../models';
import { Sort } from '@angular/material/sort';
import { Structure, WorkFlowType } from 'addiction-components';

export const TAG = 'workflow';

export interface FiltersType {
	structureUUID?: string;
	structuresUUID?: string[];
	userRolesUUIDs?: string[];
	communityUUID?: string;
	active?: boolean;
	updateDate?: string;
	communityEntityUUID?: string;
	rolesUUID?: string[];
	tasksTab?: TabOption;
	[key: string]: unknown;
}
export interface WorkFlowState {
	workFlowType: WorkFlowType;
	filters: FiltersType;
	workFlowConfig: WorkFlowConfig[];
	workFlowInstance?: WorkFlowEntity[];
	structures: Array<Structure>;
	data: { totalWorkFlowCount: number; workFlows: WorkFlowList[][] };
	pages: number[];
	status: STATE_STATUS;
	cachedPages: number[];
	gridSearch: string | null;
	sort?: Sort;
	tasksTabs: TabOption[];
	//prodotto in stato draft che deve essere approvato/rifiutato dal workflow
	draftProduct?: ProductUpdate | Product;
}

const initialState: WorkFlowState = {
	filters: {
		rolesUUID: [],
		structureUUID: '',
		tasksTab: TasksTabs[0],
	},
	workFlowConfig: [],
	workFlowInstance: undefined,
	structures: [],
	data: { totalWorkFlowCount: 0, workFlows: [] },
	pages: [0],
	status: STATE_STATUS.UNKNOWN,
	cachedPages: [],
	gridSearch: null,
	sort: { active: 'updateDate', direction: 'desc' },
	workFlowType: WorkFlowType.PRODUCT,
	tasksTabs: TasksTabs,
	draftProduct: undefined,
};

export const reducer = createReducer(
	initialState,
	on(WorkFlowActions.setSort, (state: WorkFlowState, { sort }) => ({
		...state,
		sort,
	})),
	on(WorkFlowActions.setGridSearch, (state: WorkFlowState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(WorkFlowActions.setStructures, (state: WorkFlowState, { structures }) => ({
		...state,
		structures,
	})),
	on(WorkFlowActions.setFilters, (state: WorkFlowState, { filters }) => ({
		...state,
		filters: {
			...filters,
			structureUUID: filters.structureUUID !== 'allStructures' ? filters.structureUUID : undefined,
			communityUUID: filters.communityEntityUUID,
		},
	})),
	on(WorkFlowActions.updateFilters, (state: WorkFlowState, { updatedFilters }) => ({
		...state,
		filters: {
			...state.filters,
			...updatedFilters,
		},
	})),
	on(WorkFlowActions.setWorkFlowConfig, (state: WorkFlowState, { workFlowConfig = [] }) => ({
		...state,
		workFlowConfig,
	})),
	on(WorkFlowActions.setWorkFlowInstance, (state: WorkFlowState, { workFlowInstance = [] }) => ({
		...state,
		workFlowInstance,
	})),
	on(WorkFlowActions.resetWorkFlow, () => structuredClone({ ...initialState })),
	on(WorkFlowActions.setSort, (state: WorkFlowState, { sort }) => ({
		...state,
		sort,
	})),
	on(WorkFlowActions.setPages, (state: WorkFlowState, { pages }) => ({
		...state,
		pages,
	})),
	on(WorkFlowActions.setWorkFlowSuccess, (state: WorkFlowState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				workFlows: data.workFlows,
				totalWorkFlowCount: data.totalWorkFlowCount,
			},
			pages: [...state.cachedPages, ...data.pages],
		};
	}),
	on(WorkFlowActions.setWorkFlowError, (state: WorkFlowState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(WorkFlowActions.setStatus, (state: WorkFlowState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(WorkFlowActions.setCachedPages, (state: WorkFlowState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(WorkFlowActions.resetCache, (state: WorkFlowState) => ({
		...state,
		status: STATE_STATUS.READY,
		cachedPages: [],
		pages: [0],
		data: { totalWorkFlowCount: 0, workFlows: [] },
	})),
	on(WorkFlowActions.setTasksTabsCounts, (state: WorkFlowState, { counts }) => ({
		...state,
		tasksTabs: state.tasksTabs.map((task) => {
			if (task.uuid === TASKS_TYPE.ASSIGNED_TASKS) return { ...task, count: counts?.assignedTasks };
			else return { ...task, count: counts?.tasksToApprove };
		}),
	})),
	on(WorkFlowActions.setDraftProducts, (state: WorkFlowState, { draftProduct }) => ({
		...state,
		draftProduct,
	})),
	//  on(AuthActions.logout, () => structuredClone({ ...initialState })),
);
