import { Anchor, Structure, StructureField } from '../models';

export const GetAnchorsFromStructure = (
	structure: Structure,
	config?: {
		label: string;
		value: string;
		order?: number;
	}[]
): Array<Anchor> => {
	const structureFields = structuredClone(structure.structureFields).sort(
		(a, b) => (a.order ?? Number.MAX_SAFE_INTEGER) - (b.order ?? Number.MAX_SAFE_INTEGER)
	);
	// console.log(structureFields);
	const anchors: Array<Anchor> = getAnchorLabels(structureFields).map((label) => ({
		label,
		structureFields: [],
	}));
	for (const sf of structureFields) {
		for (const anchor of anchors) {
			if (anchor.structureFields) {
				if (anchor.label === '' && (!sf.viewInCard || sf.viewInCard === '')) {
					anchor.structureFields.push(sf);
				}
				if (anchor.label === sf.viewInCard && sf.viewInCard !== '') {
					anchor.structureFields.push(sf);
				}
			}
		}
	}
	return (
		config
			?.map((c) => {
				const anchor = anchors.find((a) => a.label === c.label);
				return anchor;
			})
			.filter((a): a is Anchor => !!a) ?? anchors
	);
};

const getAnchorLabels = (sfs: StructureField[]): Array<string> => {
	return Array.from(new Set(sfs.map((sf) => sf.viewInCard ?? '')));
};
