import { Action, createReducer, on } from '@ngrx/store';
import * as SectionContainerActions from './practice-section-container.actions';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { Sort } from '@angular/material/sort';
import { PracticeSectionContainer } from 'src/app/shared/models/informed-consent/practice-section-container';

export const SECTIONS_CONTAINER_TAG = 'practiceSectionsContainer';

export interface PracticeSectionContainerState {
	gridSearch: string | null;
	sort?: Sort;
	data: { totalSectionsCount: number; sectionsContainer: PracticeSectionContainer[][] };
	pages: number[];
	status: STATE_STATUS;
	deleteStatus: STATE_STATUS;
	cachedPages: number[];
}

const initialState: PracticeSectionContainerState = {
	status: STATE_STATUS.UNKNOWN,
	deleteStatus: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	pages: [],
	data: { totalSectionsCount: 0, sectionsContainer: [] },
	cachedPages: [],
};

export const reducers = createReducer(
	initialState,
	on(SectionContainerActions.setSort, (state: PracticeSectionContainerState, { sort }) => ({
		...state,
		sort,
	})),
	on(SectionContainerActions.setPages, (state: PracticeSectionContainerState, { pages }) => ({
		...state,
		pages,
	})),
	on(SectionContainerActions.setSectionsContainerSuccess, (state: PracticeSectionContainerState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				sectionsContainer: data.sectionsContainer,
				totalSectionsCount: data.totalSectionsCount,
			},
			pages: [...state.cachedPages, ...data.pages],
		};
	}),
	on(SectionContainerActions.setSectionsContainerError, (state: PracticeSectionContainerState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(SectionContainerActions.setStatus, (state: PracticeSectionContainerState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(SectionContainerActions.setGridSearch, (state: PracticeSectionContainerState, { search }) => {
		return {
			...state,
			gridSearch: search,
      cachedPages: [],
      data: { totalSectionsCount: 0, sectionsContainer: [] },
		};
	}),
	on(SectionContainerActions.setDeletePracticeSectionContainer, (state: PracticeSectionContainerState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(SectionContainerActions.setDeletePracticeSectionContainerSuccess, (state: PracticeSectionContainerState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(SectionContainerActions.setDeletePracticeSectionContainerError, (state: PracticeSectionContainerState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
  on(SectionContainerActions.setCachedPages, (state: PracticeSectionContainerState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(SectionContainerActions.resetCache, (state: PracticeSectionContainerState) => ({
		...state,
		cachedPages: [],
    status: STATE_STATUS.READY,
		pages: [0],
		data: { totalSectionsCount: 0, sectionsContainer: [] },
	}))
);

export const PracticeSectionsContainerReducer = (state: PracticeSectionContainerState | undefined = initialState, action: Action) => {
	return reducers(state, action);
};
