import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Observer } from 'rxjs';

@Component({
	selector: 'addiction-checkbox',
	templateUrl: './checkbox.component.html',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, MatTooltipModule],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CheckboxComponent, multi: true }],
})
/** Renderizza dinamicamente una checkbox in due modalità di visualizzazione: check classico o switch */
export class CheckboxComponent implements ControlValueAccessor, OnInit, OnChanges {
	@Input() value?: boolean;
	/** Label da mostrare */
	@Input() label: string = '';
	@Input() disabled = false;
	@Input() readonly = false;
	@Input() hint?: string;

	@Output() valueChange = new EventEmitter<boolean>();

	protected destroyRef = inject(DestroyRef);

	formControl = new FormControl<boolean>(false);

	constructor() {}

	ngOnInit(): void {
		if (typeof this.value === 'boolean') this.formControl.setValue(this.value);
		if (this.disabled || this.readonly) this.formControl.disable();
		this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
			this.valueChange.emit(!!value);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('value' in changes) this.formControl.setValue(changes['value'].currentValue);
	}

	public onTouched: () => void = () => {};

	writeValue(obj: boolean): void {
		if (typeof obj == 'boolean') {
			this.formControl.setValue(obj);
		}
	}

	registerOnChange(fn: Observer<boolean | null>): void {
		this.formControl.valueChanges.subscribe(fn);
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
		if (disabled) this.formControl.disable({ emitEvent: false });
		else this.formControl.enable({ emitEvent: false });
	}
}
