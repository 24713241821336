import { Pipe, PipeTransform, inject } from '@angular/core';
import { AssetIdentifierService } from '../services/asset-identifier.service';

@Pipe({
	name: 'toFormat',
	standalone: true,
})
export class ToFormatPipe implements PipeTransform {
	private assetIdentifier = inject(AssetIdentifierService);

	transform(format?: string) {
		if (format) {
			return this.assetIdentifier.getFormatLabel(format.toLowerCase());
		}

        return format;
	}
}
