<div class="left-container">
	<label>{{ 'BULK_ACTIONS_BAR.ACTION' | translate }}</label>
	<addiction-select [options]="options" [(value)]="selectedValue"> </addiction-select>
	<div class="counter" *ngIf="selectedItems">
		<span>{{ selectedItems }}</span>
		<span>{{ 'BULK_ACTIONS_BAR.SELECTED_ELEMENTS' | translate }}</span>
	</div>
</div>
<div class="right-container">
	<div class="buttons-container">
		<button class="execute" (click)="onExecute(selectedValue)">
			{{ 'BULK_ACTIONS_BAR.EXECUTE' | translate }}
		</button>
		<button class="cancel" (click)="cancel.emit()">
			{{ 'BULK_ACTIONS_BAR.CANCEL' | translate }}
		</button>
	</div>
</div>
