import { BehaviorSubject } from "rxjs";
import { UnknownObject } from "./unkownkObject";
import { TreeBrowserNode } from "../components";

export type TreeNode<T> = T & UnknownObject;

export interface TreeKeyOptions {
  /** attributo che rappresenta la chiave dell'entità */
  key: string;
  /** attributo che rappresenta la label dell'entità */
  title: string;
  /** attributo che rappresenta l'array dei figli */
  children: string;
  /** attributo che la chiave del padre */
  parentKey?: string;
}

export interface NodeMovement<T> {
  /** nodo spostato */
  node: TreeNode<T>;
  /** padre destinazione */
  newParent?: TreeNode<T>;
  /** padre precedente */
  oldParent?: TreeNode<T>;
  /** indice nel nuovo padre */
  newIndex: number;
  currentNode?: TreeBrowserNode<T>;
}

export interface TreeConfiguration {
  /** oggetto con le configurazioni del tipo di dato */
  keys: TreeKeyOptions;
  /**show checkboxes*/
  multiple?: boolean;
  /**editable false overrides all other options*/
  editable: BehaviorSubject<boolean> | boolean;
  /**show delete button*/
  removable?: boolean;
  /**allow drag*/
  draggable?: boolean;
  /**show duplicate button*/
  duplicable?: boolean;
  /** If true, collapses the tree structure when an item is moved around successfully */
  collapseOnMoved?: boolean;
}
