import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToLocalizedValuePipe } from 'addiction-components';
import { catchError, filter, of, switchMap } from 'rxjs';
import { debounce, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { CommunitySelectActions, ProductVariantsActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { selectLastCommunitySelectedForApiRest } from 'src/app/shared/state/community-select/community-select.selectors';
import { Product } from '../../../shared/models/product.interface';
import { ProductVariantsService } from '../services/product-variants.service';
import { setProductsSuccess } from './product-variants.actions';
import {
	selectCachedPages,
	selectGridSearch,
	selectPages,
	selectProductsFilters,
	selectSort,
	selectStatus,
} from './product-variants.selectors';
import { Router } from '@angular/router';

@Injectable()
export class ProductVariantsEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private productService = inject(ProductVariantsService);
	private translateService = inject(TranslateService);
	private localizablePipe = inject(ToLocalizedValuePipe);
	private activeRoute = inject(Router);

	fetchProducts$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(
				ProductVariantsActions.setPages,
				ProductVariantsActions.setSort,
				ProductVariantsActions.setForceRefreshProducts,
				ProductVariantsActions.resetCache
			),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectProductsFilters),
				this.store.select(selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				(
					[, pagesPrev, sortPrev, gridSearchPrev, { featureValue, active, structureUUID }],
					[
						action,
						pagesCurr,
						sortCurr,
						gridSearchCurr,
						{ featureValue: featureValueCurr, active: activeCurr, structureUUID: structureUUIDCurr },
						,
						cachedPages,
					]
				) => {
					return (
						action.type !== '[ProductsVariants] Set Force Refresh' &&
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						gridSearchCurr === gridSearchPrev &&
						featureValueCurr?.length === featureValue?.length &&
						activeCurr === active &&
						structureUUIDCurr === structureUUID &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			tap(() => {
				this.store.dispatch(ProductVariantsActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, filters, communityUUID, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));

				if (!actualPages.length) return of(setProductsSuccess({ data: { pages: [], products: [], totalProductsCount: 0 } }));

				const filterWithCommunity = structuredClone(filters);
				if (communityUUID) filterWithCommunity.communityUUID = communityUUID;
				return this.productService.fetchProducts(actualPages, sort, this.translateService.currentLang, gridSearch, filterWithCommunity).pipe(
					map((data) => {
						const startingResult: { pages: number[]; products: Product[][]; totalProductsCount: number } = {
							pages: [],
							products: [],
							totalProductsCount: 0,
						};
						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.pageNumber);
								acc.totalProductsCount = item.paginationInfo.totalElements ?? 0;
								if (item.result) {
									acc.products[item.paginationInfo.pageNumber] = item.result.map((product) => ({
										...product,
										features: product.featureValueList.map((f) => (typeof f === 'string' ? '' : this.localizablePipe.transform(f.label || f.name))),
										// contents: product.contentList?.length ?? 0,
									}));
								}
							}
							return acc;
						}, startingResult);
						return ProductVariantsActions.setProductsSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						return of(ProductVariantsActions.setProductsError({ error }));
					})
				);
			})
		)
	);

	// additional operation when update filters
	resetPage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				ProductVariantsActions.updateFilters,
				ProductVariantsActions.setGridSearch,
				ProductVariantsActions.addFeatureValueToFilters,
				ProductVariantsActions.removeFeatureValueFromFilters,
				CommunitySelectActions.setLastCommunitySelected
			),
			map(() => ProductVariantsActions.resetCache())
		)
	);

	deleteProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductVariantsActions.deleteProducts),
			// invoke API
			switchMap(({ uuids }) =>
				this.productService.multipleUpdate({
					uuidList: uuids,
					delete: true,
				})
			),
			// must re-fetch to repaginate
			switchMap(() => [ProductVariantsActions.updatePage({ updatedPage: { pageNumber: 0 } }), ProductVariantsActions.setForceRefreshProducts()]), // CHANGE IN deleteItemsSuccess
			// error handling
			catchError((error: unknown) => of(ProductVariantsActions.error({ error: { errorType: 'generic_http', data: error } })))
		)
	);

	disableProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductVariantsActions.disableProducts),
			// invoke API
			switchMap(({ uuids }) =>
				this.productService.multipleUpdate({
					uuidList: uuids,
					active: false,
				})
			),
			// must re-fetch to repaginate
			switchMap(() => [ProductVariantsActions.updatePage({ updatedPage: { pageNumber: 0 } }), ProductVariantsActions.setForceRefreshProducts()]), // CHANGE IN disableItemsSuccess
			// error handling
			catchError((error: unknown) => of(ProductVariantsActions.error({ error: { errorType: 'generic_http', data: error } }))),
			tap(() => this.productService.getStructureAndFeatures())
		)
	);

	productSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setProductsSuccess),
			map(({ data: { pages } }) => ProductVariantsActions.setCachedPages({ pages }))
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url.includes('/products-variants')),
			map(() => ProductVariantsActions.resetCache())
		)
	);

	constructor() {}
}
