import { Component, Input } from '@angular/core';
import { DataleanTableColumn, PageChangeEvent, PaginationInfo, TableRow } from 'addiction-components';
import { isRole } from '../../models';
import { EntitySelectorDialogInfinityComponent } from '../entity-selector-dialog-infinity/entity-selector-dialog-infinity.component';

@Component({
	selector: 'datalean-role-selector-dialog',
	templateUrl: './role-selector-dialog.component.html',
	styleUrls: ['./role-selector-dialog.component.scss'],
})
export class RoleSelectorDialogComponent<
	T extends { uuid: string; [key: string]: unknown } = { uuid: string; [key: string]: unknown },
	K extends Record<string, unknown> = { selected: Array<T> },
> extends EntitySelectorDialogInfinityComponent<T, K> {
	@Input() override selectedUUIDs: string[] = [];
	@Input() override searchFields: string = 'name';
	@Input() override searchParamName: string = 'q';

	public columns: Array<DataleanTableColumn> = [
		{
			columnDef: 'check',
			header: '',
			thClass: 'checkbox',
			tdClass: 'checkbox',
			template: 0,
		},
		{
			columnDef: 'name',
			header: 'LIST_HEADER.NAME',
			thClass: 'double',
			tdClass: 'double',
			template: 1,
		},
	];

	selectedRows?: TableRow[];

	constructor() {
		super();
		this.pageSize = 200;
		this.currentPage$.next([new PaginationInfo(this.pageSize, 0)]);
	}

	override mapData(data: T[]): TableRow[] {
		const result: TableRow[] = [];

		for (const item of data) {
			if (isRole(item)) {
				result.push({
					uuid: item.uuid,
					name: item.name,
				});
			}
		}
		return result;
	}

	onSelectionChange(checked: boolean, entityUUID?: string): void {
		if (entityUUID) {
			if (entityUUID === 'all') {
				for (const uuid of this._currentPageUUIDs) {
					this.gcs.setCheckboxMap(uuid, !!checked);
				}
			} else {
				this.gcs.setCheckboxMap(entityUUID, !!checked);
				this.gcs.updateSelectAllStatus();
			}
		}

		this.selectionChanged(
			Object.entries(this.gcs.checkboxMap).reduce((acc, [uuid, value]) => {
				if (value) {
					acc.push({ uuid });
				}
				return acc;
			}, new Array<TableRow>()),
		);
	}

	onPageChanged(pages: PageChangeEvent) {
		this.currentPage$.next([pages.pageSize].map((p) => new PaginationInfo(this.pageSize, p)));
	}

	onSortChange() {}

	override close() {
		const selectedUUIDs = Object.keys(this.gcs.checkboxMap).filter((uuid) => this.gcs.checkboxMap[uuid]);

		const selectedData: { uuids: string[] } = { uuids: selectedUUIDs };
		const data = {
			selected: selectedData.uuids.map((uuid) => ({ uuid, name: this.gcs.checkboxMapWithValue[uuid] })),
		};

		this.closeDialog(data as K & { selected: { uuid: string; name: string }[] });
	}

	public isItemChecked(uuid: string): boolean {
		return this.gcs.checkboxMap[uuid] === undefined ? this.gcs.selectAllChecked : this.gcs.checkboxMap[uuid];
	}
}
