import { Injectable } from '@angular/core';

//NB: a diffrenza del servizio checkbox-multiple, qusto servizio è pensato appositamente per situazioni non paginate

/**
 * Utility che permette di gestire multiple checkbox
 * utilizza la modalità di 'filtri' per capire quali checkbox sono state
 * selezionate, questo perchè in pagine con infiniteScroll se si vuole selezionare
 * tutto non è possibile indicare gli elementi selezionati in un altro modo (poichè non conosciamo
 * tutti gli elementi che sono stati selezionati)
 *
 * il filtro può essere inclusivo o esclusivo es.
 * checboxMap = {ID} & modalità inclusiva ==> ho selezionato solo ID
 * checboxMap = {ID} & modalità esclusiva ==> ho selezionato tutte le checkbox ad esclusione della checkbox ID
 *
 *
 * NB. occorre settare il numero totale di items per far funzionare correttamente il servizio
 * ossia settare la variabile 'totalItems'
 */
@Injectable()
export class VirtualCheckboxMultipleService {
  checkboxMap: Record<string, boolean> = {};  //ci salvo gli uuid
  selectAllChecked: boolean = false;  //modalità: se true => 'exclusive', se false => 'inclusive'
  selectAllIndeterminate: boolean = false;
  //necessario settare questo valore per far funzionare correttamente il servizio
  totalItems: number = 0;
  updateSelectAllStatus() {
    if (this.totalItems === 0) {
      throw new Error('totalItems must be set correctly');
    }

    if (this.selectAllChecked) {  //caso esclusivo
      const falseValues = Object.values(this.checkboxMap).filter((v) => !v);
      this.selectAllIndeterminate = false;
      if (falseValues.length === 0) {
        this.setAll(true);
      } else if (falseValues.length === this.totalItems) {
        this.setAll(false);
      } else {
        this.selectAllIndeterminate = !!falseValues.length;
      }
    } else {  //Caso inclusivo
      const trueValues = Object.values(this.checkboxMap).filter((v) => v);
      this.selectAllIndeterminate = false;

      if (trueValues.length === this.totalItems) {
        this.setAll(true);
      } else {
        this.selectAllIndeterminate = !!trueValues.length;
      }
    }
  }

  setAll(value: boolean) {
    this.selectAllChecked = value;
    this.selectAllIndeterminate = false;
    this.checkboxMap = {};
  }

  reset() {
    this.setAll(false);
  }

  isInclusiveMode(){
    return !this.selectAllChecked
  }

  getUuidArray(){
    return  Object.entries(this.checkboxMap).reduce((acc, [uuid, value]) => {
      //se la modalità è inclusiva, prendo tutti quelli dentro checkboxMap con valore true
      //se la modalità è esclusiva, prendo tutti quelli con valore false
      if (value !== this.selectAllChecked) {
        acc.push(uuid);
      }
      return acc;
    }, [] as string[]);
  }
}
