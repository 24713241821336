import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { Page } from 'addiction-components';
import { Integration, STATE_STATUS } from 'src/app/shared/models';
import { IntegrationError } from './integration.state';

export const updatePage = createAction('[Integrations] Update Page', props<{ updatedPage: Partial<Page> }>());
export const deleteIntegration = createAction('[Integrations] Delete Integration', props<{ uuid: string }>());

export const setForceRefreshIntegrations = createAction('[Integrations] Set Force Refresh');
export const setIntegrations = createAction('[Integrations] Set Integrations');
export const setIntegrationsSuccess = createAction(
	'[Integrations] Set Integrations Success',
	props<{ data: { totalIntegrationsCount: number; pages: number[]; integrations: Integration[][] } }>()
);
export const setIntegrationsError = createAction('[Integrations] Set Integrations Error', props<{ error: HttpErrorResponse }>());
export const setDeleteIntegrations = createAction('[Integrations] Set Delete Integrations', props<{ Integration: Integration }>());
export const setDeleteIntegrationsSuccess = createAction('[Integrations] Set Delete Integrations Success');
export const setDeleteIntegrationsError = createAction('[Integrations] Set Delete Integrations Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Integrations] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Integrations] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Integrations] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Integrations] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[Integrations] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Integrations] reset cache');
export const setConsolidationDate = createAction('[Integrations] Set Integration Consolidation Date', props<{ uuid: string }>());
export const setConsolidationSuccess = createAction('[Integrations] Set Integration Consolidation Date Success');
export const setConsolidationError = createAction('[Integrations] SetIntegration Consolidation Date Error', props<{ error: HttpErrorResponse }>());

// Error
export const error = createAction('[Integrations] Error', props<{ error: IntegrationError }>());

