import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageHandler } from 'addiction-components';
import { catchError, map, of, switchMap } from 'rxjs';
import { ObConfiguratorActions } from 'src/app/core/state/app.actions';
import { ObConfiguratorService } from '../services/ob-configurator.service';

@Injectable()
export class OBActionEffect {
	private actions$ = inject(Actions);
	private obConfiguratorSrv = inject(ObConfiguratorService);
	private store = inject(Store);
	private messageHandler = inject(MessageHandler);
	private translateService = inject(TranslateService);

	loadActions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				ObConfiguratorActions.loadActions,
				ObConfiguratorActions.reloadActions,
				ObConfiguratorActions.createActionSuccess,
				ObConfiguratorActions.updateActionSuccess,
				ObConfiguratorActions.deleteActionSuccess
			),
			switchMap(() =>
				this.obConfiguratorSrv.getActions().pipe(
					map((actions) => {
						return ObConfiguratorActions.loadActionsSuccess({ actions });
					}),
					catchError((error) => of(ObConfiguratorActions.loadActionsFailure({ error })))
				)
			)
		)
	);

	// UPDATE ONE
	updateAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.updateAction),
			switchMap(({ action }) =>
				this.obConfiguratorSrv.updateAction(action).pipe(
					map((action) => {
						this.messageHandler.createToast('ALERT.SUCCESS_SAVE_MESSAGE');
						return ObConfiguratorActions.updateActionSuccess({ action });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('ALERT.ERROR_SAVE_MESSAGE');
						return of(ObConfiguratorActions.updateActionFailure({ error }));
					})
				)
			)
		)
	);

	// CREATE ONE
	createAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.createAction),
			switchMap(({ action }) =>
				this.obConfiguratorSrv.createAction(action).pipe(
					map((action) => {
						this.messageHandler.createToast('ALERT.SUCCESS_SAVE_MESSAGE');
						return ObConfiguratorActions.createActionSuccess({ action });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('ALERT.ERROR_SAVE_MESSAGE');
						return of(ObConfiguratorActions.createActionFailure({ error }));
					})
				)
			)
		)
	);

	// DELETE ONE
	deleteAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.deleteAction),
			switchMap(({ uuid }) =>
				this.obConfiguratorSrv.deleteAction(uuid).pipe(
					map(() => ObConfiguratorActions.deleteActionSuccess({ uuid })),
					catchError((error) => of(ObConfiguratorActions.deleteActionFailure({ error })))
				)
			)
		)
	);

	selectedAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.changeActionSelected),
			map(({ action }) => ObConfiguratorActions.selectAction({ action }))
		)
	);

	selectedEditableAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.changeActionSelected),
			map(({ action }) => ObConfiguratorActions.editAction({ uuid: action.uuid }))
		)
	);

	editNewAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ObConfiguratorActions.createNewAction),
			map(() => ObConfiguratorActions.editAction({ uuid: 'new' }))
		)
	);
}
