import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { PracticeSectionContainer } from 'src/app/shared/models/informed-consent/practice-section-container';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';

export const setForceRefreshPracticeSectionsContainer = createAction('[Practice-section-container] Set Force Refresh');
export const setSectionsContainerSuccess = createAction(
	'[Practice-section-container] Set Practice sections container Success',
	props<{ data: { totalSectionsCount: number; pages: number[]; sectionsContainer: PracticeSectionContainer[][] } }>()
);
export const setSectionsContainerError = createAction('[Practice-section-container] Set section container Error', props<{ error: HttpErrorResponse }>());
export const setDeletePracticeSectionContainer = createAction('[Practice-section-container] Set Delete section container', props<{ sectionContainer: PracticeSectionContainer }>());
export const setDeletePracticeSectionContainerSuccess = createAction('[Practice-section-container] Set Delete sections container Success');
export const setDeletePracticeSectionContainerError = createAction('[Practice-section-container] Set Delete sections container Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Practice-section-container] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Practice-section-container] Set pages', props<{ pages: number[] }>());
export const setCachedPages = createAction('[Practice-section-container] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Practice-section-container] reset cache');
export const setSort = createAction('[Practice-section-container] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Practice-section-container] Set search', props<{ search: string | null }>());
