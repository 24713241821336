import { ObjectValuesType } from "../object-values-type";

export const StructureType = {
	PRODUCT: 'product',
	PRODUCTVARIANTS: 'product_variants',
	COMMUNICATION: 'communication',
	COCKPIT: 'cockpit',
	BOOKING: 'booking',
	CONTENT: 'content',
	BOOKABLE_PRODUCT: 'bookable_product',
	RESOURCE: 'resource',
	RESERVATION: 'reservation',
	USER: 'user',
} as const;

export type StructureType = ObjectValuesType<typeof StructureType>;
