<div class="datalean-modal-conversion-container">
	<form [formGroup]="formModalConversion">
		<div class="field-container ratio-100">
			<addiction-input formControlName="name" [label]="'SETTINGS.MEDIA.CONVERSIONS.NAME' | translate"></addiction-input>
		</div>
		<div class="field-container ratio-50">
			<label for="from">{{ 'SETTINGS.MEDIA.CONVERSIONS.FROM' | translate }}</label>
			<addiction-select [value]="defaultValueFrom.value" [options]="supportedFormats" formControlName="from"></addiction-select>
		</div>
		<div class="field-container ratio-50">
			<label for="to">{{ 'SETTINGS.MEDIA.CONVERSIONS.TO' | translate }}</label>
			<addiction-select [value]="defaultValueTo.value" [options]="supportedFormats" formControlName="to"></addiction-select>
		</div>
	</form>
</div>
<div mat-dialog-actions class="buttons-container">
	<button (click)="saveConversion()" class="save" [disabled]="formModalConversion.invalid">
    <span>{{ 'SETTINGS.MEDIA.CONVERSIONS.ADD' | translate }}</span>
	</button>
</div>

<div class="errors-container" *ngIf="(showErrorMandatoryField && formModalConversion.invalid) || showErrorHttp">
  <div class="single-error mandatory-field" *ngIf="showErrorMandatoryField && formModalConversion.invalid">{{ 'ERRORS.MANDATORY_FIELDS' | translate }}</div>
  <div class="single-error generical" *ngIf="showErrorHttp">{{ 'ERRORS.GENERIC' | translate }}</div>
</div>
