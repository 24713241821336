import { Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedModule } from '../../modules/shared.module';
import { InputComponent } from '../input/input.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'addiction-search',
  templateUrl: './search.component.html',
  standalone: true,
  imports: [InputComponent, SharedModule],
})
export class SearchComponent {
  @Output() search = new EventEmitter<string | null>();

  @Input() placeholder = 'SEARCH.PLACEHOLDER';
  @Input() instantSearch = false;
  @Input() resettable = false;
  @Input() set value(v: string | undefined | null) {
    this.formControl.setValue(v ?? null);
  }

  formControl = new FormControl<string | null>('', {nonNullable:true});

  constructor() {
    this.formControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((val) => {
      if (this.instantSearch) {
        this.search.emit(val);
      }
    });
  }

  emit() {
    this.search.emit(this.formControl.value?.trim() ?? null);
  }

  reset() {
    this.formControl.reset();
    if (!this.instantSearch) {
      this.search.emit(null);
    }
  }
}
