import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { CommunityActions, CommunitySelectActions } from 'src/app/core/state/app.actions';
import { CommunitySelectors } from 'src/app/core/state/app.selectors';
import { STATE_STATUS } from 'src/app/shared/models';
import { CommunityService } from '../services/community.service';
import { deleteCommunities, setCommunitiesSuccess } from './community.actions';
import { selectCachedPages, selectGridSearch, selectPagedData, selectPages, selectSort, selectStatus } from './community.selectors';
import { Router } from '@angular/router';

@Injectable()
export class CommunityEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private communityService = inject(CommunityService);
	private translateService = inject(TranslateService);
	private activeRoute = inject(Router)

	fetchCommunities$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(CommunityActions.setPages, CommunityActions.setForceRefreshCommunities),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(CommunitySelectors.selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => this.store.dispatch(CommunityActions.setStatus({ status: STATE_STATUS.LOADING }))),
			switchMap(([, pages, sort, gridSearch, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));

				if (!actualPages.length) return EMPTY;

				return this.communityService.fetchCommunities(actualPages, sort, this.translateService.currentLang, gridSearch).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						startingResult.pages = cachedPages;

						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.pageNumber);
								acc.totalCommunitiesCount = item.paginationInfo.totalElements ?? 0;
								if (item.result) {
									acc.communities[item.paginationInfo.pageNumber] = item.result.map((community) => ({
										...community,
										// contents: community.contentList?.length ?? 0,
									}));
								}
							}
							return acc;
						}, structuredClone(startingResult));

						return CommunityActions.setCommunitiesSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						return of(CommunityActions.setCommunitiesError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunityActions.setGridSearch, CommunityActions.setSort),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCachedPages),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(([, pagesPrev, sortPrev, gridSearchPrev], [, pagesCurr, sortCurr, gridSearchCurr, cachedPages]) => {
				return (
					pagesPrev.length === pagesCurr.length &&
					pagesPrev.every((f) => pagesCurr.includes(f)) &&
					sortCurr?.active === sortPrev?.active &&
					sortCurr?.direction === sortPrev?.direction &&
					gridSearchCurr === gridSearchPrev &&
					pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
				);
			}),
			map(() => CommunityActions.resetCache())
		)
	);

	deleteCommunities$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteCommunities),
			// invoke API
			switchMap(({ uuids }) => {
				return this.communityService
					.multipleUpdate({
						uuidList: uuids,
						deleteAll: true,
					})
					.pipe(
						map(() => {
							return CommunityActions.setDeleteCommunitiesSuccess();
						}),
						catchError((error: HttpErrorResponse) => of(CommunityActions.error({ error: { errorType: 'generic_http', data: error } })))
					);
			})
		)
	);

	communitySuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setCommunitiesSuccess),
			map(({ data: { pages } }) => CommunityActions.setCachedPages({ pages }))
		)
	);

	afterDeleteCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunityActions.setDeleteCommunitiesSuccess, CommunityActions.setDeleteCommunitiesError),
			map(() => CommunityActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunityActions.resetCache),
			map(() => CommunityActions.setForceRefreshCommunities())
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url.includes('/communities')),
			map(() => CommunityActions.resetCache())
		)
	);
	constructor() {}
}
