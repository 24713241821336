import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DataleanBaseApiService, DataleanPagedResult, PaginationInfo, Parts, SimpleObject, SortInfo } from 'addiction-components';
import { Observable, forkJoin, tap } from 'rxjs';
import { HeaderLocaleService } from 'src/app/core/components/header/service/header-locale.service';
import { Lesson, LessonWithLocalizedValues } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';
import { resetCache } from '../state/lessons.actions';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class LessonsService {
	private lessonsURL = environment.lmsUrl + 'lesson';

	constructor(private headerSrv: HeaderLocaleService, private baseApi: DataleanBaseApiService, private store: Store) {}

	fetchLessons(
		pages: number[],
		sort?: Sort,
		locale?: string,
		gridSearch?: string | null,
		communityUUID?: string
	): Observable<DataleanPagedResult<Lesson>[]> {
		const obs: Observable<DataleanPagedResult<Lesson>>[] = [];

		const params: { sortBy?: string; q?: string; searchFields?: string; locale?: string; communityUUID?: string } = { locale };

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'localizedValues.name,description';
		}
		if (communityUUID) params.communityUUID = communityUUID;

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Lesson>(this.lessonsURL, [Parts.FEATURE_VALUE_LIST, Parts.LESSON_CONTENT_LIST], {
					pagination,
					additionalParams: params,
				})
			);
		}
		return forkJoin(obs);
	}

	getLessons(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: { uuids?: string[]; query?: string; communityUUID?: string };
	}) {
		const { filters, parts = [], locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name']; // parametrizzare i campi di ricerca??
		}
		if (filters?.communityUUID) params['communityUUID'] = filters.communityUUID;
		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		return this.baseApi.getManyPaged<LessonWithLocalizedValues>(this.lessonsURL, parts, {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	delete(uuidList: string[]) {
		return this.baseApi.deleteMany(this.lessonsURL, { uuidList: uuidList.join(',') });
	}

	deleteLesson(lesson: Lesson) {
		return this.baseApi.deleteEntity(this.lessonsURL, lesson);
	}

	getLesson(uuid: string, locale?: string) {
		return this.baseApi.getOne<Lesson>(this.lessonsURL, uuid, [Parts.FEATURE_VALUE_LIST, Parts.LESSON_CONTENT_LIST], { locale });
	}

	createLesson(lesson: Lesson) {
		return this.baseApi
			.createOne<Lesson>(this.lessonsURL, lesson, [Parts.FEATURE_VALUE_LIST, Parts.LESSON_CONTENT_LIST])
			.pipe(tap(() => this.store.dispatch(resetCache())));
	}

	updateLesson(lesson: Lesson) {
		return this.baseApi
			.updateOne<Lesson>(this.lessonsURL, lesson.uuid, lesson, [Parts.FEATURE_VALUE_LIST, Parts.LESSON_CONTENT_LIST])
			.pipe(tap(() => this.store.dispatch(resetCache())));
	}
	updateLessons(body: Record<string, unknown>) {
		return this.baseApi.updateLessonsFeatures<Lesson>(`${this.lessonsURL}/updateLessonsFeatures`, body);
	}
}
