import { UnknownObject } from '../unkownkObject';
import { UserValue } from './UserValue';

export interface User {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  authenticationMethods: UnknownObject[];
  values: UserValue[];
  structureUUID: string;
  status: 'ENABLED' | 'DISABLED' | 'REGISTERED' | 'PASSWORD_RESTORED' | 'CREATED' | 'CHANGE_PASSWORD';
  score?: Score[];
  id?: string;
  smartCoUser: boolean
  [key:string]: unknown
}

export interface Score {
  totalScore: number;
  limitScore: number;
}
