import { createReducer, on } from '@ngrx/store';
import { IntegrationActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { IntegrationState } from './integration.state';

export const TAG = 'integrations';

const initialState: IntegrationState = {
	sort: { active: 'updateDate', direction: 'desc' },
	pages: [0],
	data: { totalIntegrationsCount: 0, integrations: [] },
	status: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	deleteStatus: 'unknown',
	cachedPages: [],
};

export const counterReducer = createReducer(
	initialState,
	/**
	 * Parte standard di un reducer
	 */
	on(IntegrationActions.setSort, (state: IntegrationState, { sort }) => ({
		...state,
		sort,
	})),
	on(IntegrationActions.setPages, (state: IntegrationState, { pages }) => ({
		...state,
		pages,
	})),
	on(IntegrationActions.setIntegrationsSuccess, (state: IntegrationState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				integrations: data.integrations,
				totalIntegrationsCount: data.totalIntegrationsCount,
			},
			pages: [...state.cachedPages, ...data.pages],
		};
	}),
	on(IntegrationActions.setIntegrationsError, (state: IntegrationState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(IntegrationActions.setStatus, (state: IntegrationState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(IntegrationActions.setGridSearch, (state: IntegrationState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(IntegrationActions.setDeleteIntegrations, (state: IntegrationState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(IntegrationActions.setDeleteIntegrationsSuccess, (state: IntegrationState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(IntegrationActions.setDeleteIntegrationsError, (state: IntegrationState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
	/**
	 * PARTE SPECIFICA DEL REDUCER
	 */
	on(IntegrationActions.setCachedPages, (state: IntegrationState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(IntegrationActions.resetCache, (state: IntegrationState) => ({
		...state,
		status: STATE_STATUS.READY,
		cachedPages: [],
		pages: [0],
		data: { totalIntegrationsCount: 0, integrations: [] },
	}))
);
