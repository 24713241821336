import { Permission } from './permission.enum';

export interface NavLink {
	label: string;
	path?: string;
	children?: NavLink[];
	requiredPermissions?: Permission[];
	checkVersioning?: boolean;
	hidden?: boolean;
}
