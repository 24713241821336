import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'datalean-paginator-counter',
	templateUrl: './paginator-counter.component.html',
	styleUrls: ['./paginator-counter.component.scss'],
	standalone: true,
	imports: [TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorCounterComponent {
	@Input() currentPage: number = 0;
	@Input() pageSize: number = 0;
	@Input() totalItems: number = 0;
	@Input() lockInfiniteScroll: boolean = false;
}
