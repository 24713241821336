import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Sort } from '@angular/material/sort';
import { DataleanPagedResult, PaginationInfo, Parts, SearchType, Structure, WorkFlowType } from 'addiction-components';
import { Observable, combineLatest, forkJoin, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { WorkFlowActions } from '../../core/state/app.actions';
import { UserSelector, WorkFlowSelector } from '../../core/state/app.selectors';
import { DataleanUser, Product, TabOption } from '../models';
import { FlowNodeStatus } from '../models/flowNodeStatus';
import { FlowNodeType } from '../models/flowNodeType';
import { WorkFlowConfig, WorkFlowEntity, WorkFlowList } from '../models/workFlow';
import { BaseListService } from './base-list.service';

@Injectable({
	providedIn: 'root',
})
export class WorkFlowService extends BaseListService {
	permissionUuids?: string[];
	user: DataleanUser | null = null;

	protected override structureType = WorkFlowType.PRODUCT;

	constructor() {
		super();
		this.endpoint = environment.productUrl;
	}

	isWorkFlowEnabled$(structureUUID?: string, type: FlowNodeType = FlowNodeType.PRODUCT): Observable<boolean> {
		return combineLatest([
			this.store.select(WorkFlowSelector.selectWorkFlowConfigurations),
			this.store.select(UserSelector.selectUserData).pipe(
				map((user) => {
					if (user) {
						this.user = user;
						return user.roles.map((role) => role.uuid);
					}
					return [];
				}),
			),
		]).pipe(
			map(([configs, permissionUuids]) => {
				if (!configs?.length || !structureUUID) {
					return false;
				}
				this.permissionUuids = permissionUuids;
				const configFound = configs.filter(
					(c) => c.type === type && c.nodeStatus === FlowNodeStatus.START_STATE && c.structuresUUID?.includes(structureUUID),
				);
				return configFound.length > 0;
			}),
			takeUntilDestroyed(this.destroyRef),
		);
	}

	getWorkflowStep(instance: WorkFlowEntity[], product: Product): WorkFlowEntity[] | null {
		// console.log('product', product);
		const workflowStep = product.workflowStep as string[] | undefined;
		if (!instance?.length || !workflowStep?.length) {
			return null;
		}

		return instance.filter((x) => {
			return workflowStep.includes(x.uuid);
		});
	}

	getAllowedSteps(step?: WorkFlowEntity[]): null | WorkFlowEntity[] {
		if (!step?.length) {
			return null;
		}
		const allowedSteps = step.filter((config) => config.allowedRoles.some((role) => this.permissionUuids?.includes(role)));
		if (!allowedSteps.length) {
			return null;
		}
		return allowedSteps;
	}

	isEditable(step: WorkFlowEntity[] | null): boolean {
		if (step === null) {
			// NULL means that the product is in the first step of the workflow or doesn't have a workflow
			return true;
		}
		if (!step?.length) {
			return false;
		}
		const editableSteps = this.getAllowedSteps(step);
		if (!editableSteps) {
			return false;
		}
		return editableSteps.every((step) => step.editable);
	}

	isStepApprovedByUser(step: WorkFlowEntity[] | null): boolean {
		if (step === null) {
			// NULL means that the product is in the first step of the workflow or doesn't have a workflow
			return false;
		}
		if (!step?.length) {
			return false;
		}

		const userRoleUuids = this.user?.roles.map((role) => role.uuid) || [];

		for (const s of step) {
			if (s.allowedRoles.some((role) => this.permissionUuids?.includes(role))) {
				const userApproved = s.approvedBy?.some((approver) => userRoleUuids.includes(approver));
				const otherRolesExist =
					s.allowedRoles.length > 1 && s.approvedBy?.some((approver) => userRoleUuids.includes(approver) && approver !== this.user?.uuid);

				if (userApproved && otherRolesExist) {
					return true;
				}
			}
		}
		return false;
	}

	getWorkFlowInstance(entityUUID: string, version?: number): Observable<WorkFlowEntity[]> {
		const params = version ? { type: FlowNodeType.PRODUCT, entityUUID, version } : { type: FlowNodeType.PRODUCT, entityUUID };
		return this.baseApi.getEntities<WorkFlowEntity[]>(environment.workflowEntityUrl, params, [Parts.EMPTY]).pipe(
			map((workFlowInstance) => {
				this.store.dispatch(WorkFlowActions.setWorkFlowInstance({ workFlowInstance }));
				return workFlowInstance;
			}),
		);
	}

	getWorkFlowConfigurations(): Observable<WorkFlowConfig[]> {
		return this.baseApi.getEntities<WorkFlowConfig[]>(environment.workflowUrl, { type: FlowNodeType.PRODUCT }, [Parts.EMPTY]);
	}

	fetchWorkFlow(
		type: WorkFlowType,
		pages: number[],
		tasksType: TabOption,
		sort?: Sort,
		locale?: string,
		gridSearch?: string | null,
		filters?: {
			structureUUID?: string;
			communityUUID?: string;
			active?: boolean;
			status?: string | undefined;
			rolesUUID?: string[];
			elementName?: string;
			type?: string;
			dateFrom?: string;
			dateTo?: string;
		},
	): Observable<DataleanPagedResult<WorkFlowList>[]> {
		const obs: Observable<DataleanPagedResult<WorkFlowList>>[] = [];

		const params: {
			sortBy?: string;
			q?: string;
			searchFields?: string;
			locale?: string;
			active?: boolean;
			structureUUID?: string;
			featureValueList?: string;
			communityUUID?: string;
			status?: string;
			type?: WorkFlowType;
			rolesUUID?: string[];
			elementName?: string;
			dateFrom?: string;
			dateTo?: string;
		} = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;

		if (filters?.active !== undefined) params['active'] = filters.active;

		if (filters?.communityUUID) params['communityUUID'] = filters.communityUUID;

		if (filters?.status) params['status'] = filters.status;

		if (filters?.rolesUUID) params['rolesUUID'] = [filters.rolesUUID.join(',')];
		if (type) params['type'] = type;

		if (filters?.elementName) params['elementName'] = filters.elementName;
		if (filters?.type) params['type'] = filters.type as WorkFlowType;
		if (filters?.dateFrom) params['dateFrom'] = filters.dateFrom;
		if (filters?.dateTo) params['dateTo'] = filters.dateTo;

		//SORT di default per name
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name';
		}

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<WorkFlowList>(environment.workflowUrl + tasksType.uuid, [Parts.ALL], {
					pagination,
					additionalParams: params,
				}),
			);
		}
		return forkJoin(obs);
	}

	getStructure(type: WorkFlowType): Observable<Structure[]> {
		const structureAdditionalParams: { type?: WorkFlowType } = { type: type };

		return this.baseApi.getEntities<Structure[]>(
			environment.structureUrl + 'workflow',
			{
				...structureAdditionalParams,
			},
			[Parts.STRUCTURE_FIELDS],
		);
	}

	override getStructureAndFeatures(type: WorkFlowType): Observable<Structure[]> {
		const structureAdditionalParams: { type?: WorkFlowType } = { type: type };

		return this.baseApi.getEntities<Structure[]>(environment.structureUrl + 'workflow', { ...structureAdditionalParams }, [Parts.STRUCTURE_FIELDS]);
	}

	countWorkFlows(filters: { structuresUUID?: string; allowedRoles: string[] }) {
		const params: { structuresUUID?: string; allowedRoles?: string } = {};
		if (filters?.structuresUUID) params['structuresUUID'] = filters.structuresUUID + SearchType.AND;
		if (filters?.allowedRoles.length) params['allowedRoles'] = filters.allowedRoles.join(',');

		return this.baseApi.getOne<{ count: number }>(environment.workflowEntityUrl + 'count', '', [], {
			organizationUUID: environment.organizationUUID,
			...params,
			active: true,
		});
	}

	public getTasksTabCounts(type: WorkFlowType, rolesUUID?: string[]) {
		const params: {
			type?: WorkFlowType;
			rolesUUID?: string[];
		} = { type };

		if (rolesUUID) params.rolesUUID = [rolesUUID.join(',')];

		return this.baseApi.getOne<{
			assignedTasks: number;
			tasksToApprove: number;
		}>(environment.workflowEntityUrl + 'count', '', [], {
			organizationUUID: environment.organizationUUID,
			...params,
		});
	}

	getPublishedVersion(uuid: string): Observable<{ version: number }> {
		return this.baseApi.getOne(environment.productUrl + `${uuid}/` + 'published-version', '', [], {
			organizationUUID: environment.organizationUUID,
		});
	}
}
