import * as HeaderSelector from '../../core/components/header/state/header.selectors';
import * as CommunicationSelector from '../../pages/communications/state/communication.selectors';
import * as ContentsSelectors from '../../pages/contents/state/content.selectors';
import * as ChallengeSelectors from '../../pages/contest/challenges/challenge-list/state/challenge.selectors';
import * as ContestSelectors from '../../pages/contest/contests/contest-list/state/contest.selectors';
import * as ApplicationUsersSelectors from '../../pages/idm/app-users/state/application-users.selectors';
import * as DataleanUsersSelectors from '../../pages/idm/datalean-users/state/datalean-users.selectors';
import * as GroupsSelectors from '../../pages/idm/groups/state/groups.selectors';
import * as SmartGroupsSelectors from '../../pages/idm/smart-groups/state/smart-groups.selectors';
import * as CoursesSelectors from '../../pages/lms/courses/state/courses.selectors';
import * as LessonsSelectors from '../../pages/lms/lessons/state/lessons.selectors';
import * as QuizSelectors from '../../pages/lms/quiz/state/quiz.selectors';
import * as MediaLibrarySelectors from '../../pages/media-library/state/media-library.selectors';
import * as OBTailSelectors from '../../pages/on-boarding/ob-city-builder/state/ob-builder-tail.selectors';
import * as OBActionSelectors from '../../pages/on-boarding/ob-configurator/state/ob-configurator-action.selectors';
import * as OBObjectiveGroupSelectors from '../../pages/on-boarding/ob-configurator/state/ob-configurator-objective-group.selectors';
import * as OBObjectiveSelectors from '../../pages/on-boarding/ob-configurator/state/ob-configurator-objective.selectors';
import * as PictogramStructureSelectors from '../../pages/pictogram/pictogram-structures/state/pictogram-structure.selectors';
import * as PictogramSelectors from '../../pages/pictogram/pictograms/state/pictogram.selectors';
import * as ProductVariantsSelector from '../../pages/products-variants/state/product-variants.selectors';
import * as ProductSelector from '../../pages/products/state/product.selectors';
import * as ProductVersioningSelector from '../../pages/products/state/product-versioning/product-versioning.selectors';
import * as CalendarSelectors from '../../pages/reservations/calendar/state/calendar.selectors';
import * as BookableProductSelector from '../../pages/reservations/products/state/bookable-product.selectors';
import * as ResourceSelectors from '../../pages/reservations/resources/state/resource.selectors';
import * as CommunitySelectors from '../../pages/settings/communities/state/community.selectors';
import * as SurveyCommunicationsSelectors from '../../pages/surveys/state/survey-communication.selectors';
import * as SurveysSelectors from '../../pages/surveys/state/surveys.selectors';
import * as UsefulLinksSelectors from '../../pages/useful-links/state/useful-links.selectors';
import * as CommunitySelectSelectors from '../../shared/state/community-select/community-select.selectors';
import * as ConfigSelector from '../../shared/state/config/config.selectors';
import * as FeaturesSelectors from '../../shared/state/features/features.selectors';
import * as NavigationSelector from '../../shared/state/navigation/navigation.selectors';
import * as RecipientsSelectors from '../../shared/state/recipients/recipients.selectors';
import * as StructuresSelectors from '../../shared/state/structures/structures.selectors';
import * as TagsSelectors from '../../shared/state/tags/tags.selectors';
import * as UserSelector from '../../shared/state/user/user.selectors';
import * as ApplicationSelector from './application/application.selectors';
import * as WorkFlowSelector from '../../shared/state/workflow/workflow.selectors';
import * as RolesSelectors from '../../pages/roles/store/roles.selectors';
import * as IntegrationSelector from '../../pages/settings/integrations/state/integration.selectors';
import * as PracticeSectionsSelector from '../../pages/informed-consent/state/section/practice-section.selectors';
import * as PracticeSectionsContainerSelector from '../../pages/informed-consent/state/section-container/practice-section-container.selectors';
import * as PracticeTemplatesSelector from '../../pages/informed-consent/state/practice-template/practice-template.selectors';

export {
	ApplicationSelector,
	ApplicationUsersSelectors,
	BookableProductSelector,
	CalendarSelectors,
	ChallengeSelectors,
	CommunicationSelector,
	CommunitySelectSelectors,
	CommunitySelectors,
	ConfigSelector,
	ContentsSelectors,
	ContestSelectors,
	CoursesSelectors,
	DataleanUsersSelectors,
	FeaturesSelectors,
	GroupsSelectors,
	HeaderSelector,
	LessonsSelectors,
	MediaLibrarySelectors,
	NavigationSelector,
	OBActionSelectors,
	OBObjectiveGroupSelectors,
	OBObjectiveSelectors,
	OBTailSelectors,
	PictogramSelectors,
	PictogramStructureSelectors,
	ProductSelector,
	ProductVariantsSelector,
	QuizSelectors,
	RecipientsSelectors,
	ResourceSelectors,
	SmartGroupsSelectors,
	StructuresSelectors,
	SurveyCommunicationsSelectors,
	SurveysSelectors,
	TagsSelectors,
	UsefulLinksSelectors,
	UserSelector,
	ProductVersioningSelector,
	WorkFlowSelector,
	RolesSelectors,
	IntegrationSelector,
	PracticeSectionsSelector,
	PracticeSectionsContainerSelector,
	PracticeTemplatesSelector,
};
