import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, combineLatestWith, debounce, distinctUntilChanged, filter, first, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { CommunitySelectActions, StructuresActions } from '../../../core/state/app.actions';
import { STATE_STATUS } from '../../models';
import { StructuresService } from '../../services/structures.service';
import {
	resetCache,
	setDeleteStructures,
	setDeleteStructuresError,
	setDeleteStructuresSuccess,
	setGridSearch,
	setPages,
	setSort,
	setStatus,
	setStructures,
	setStructuresError,
	setStructuresSuccess,
} from './structure.actions';
import {
	selectCachedPages,
	selectGridSearch,
	selectPagedData,
	selectPages,
	selectSort,
	selectStatus,
	selectStructureType,
} from './structures.selectors';

@Injectable()
export class StructuresEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private translateService = inject(TranslateService);
	private structureService = inject(StructuresService);
	private activeRoute = inject(Router);

	constructor() {}

	fetchStructures$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setPages, resetCache),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectStructureType),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => this.store.dispatch(setStatus({ status: STATE_STATUS.LOADING }))),
			switchMap(([, pages, sort, gridSearch, structureType, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				return this.structureService
					.fetchStructures(structureType, actualPages, sort, this.translateService.currentLang ?? this.translateService.defaultLang, gridSearch)
					.pipe(
						combineLatestWith(this.store.select(selectPagedData)),
						first(),
						map(([data, startingResult]) => {
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.pages.push(item.paginationInfo.pageNumber);
									acc.totalStructuresCount = item.paginationInfo.totalElements ?? 0;
									if (item.result) {
										acc.structures[item.paginationInfo.pageNumber] = item.result.map((structure) => ({
											...structure,
										}));
									}
								}
								return acc;
							}, structuredClone(startingResult));

							result.pages = [...new Set([...result.pages])];

							if (result.pages.length && !result.totalStructuresCount) {
								result.totalStructuresCount = result.structures.reduce((acc, structure) => acc + structure.length, 0);
							}

							return setStructuresSuccess({ data: result });
						}),
						catchError((error: HttpErrorResponse) => {
							console.error(error);
							return of(setStructuresError({ error }));
						})
					);
			})
		)
	);

	structureTypeChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StructuresActions.structureTypeChanged),
			map(({ structureType }) => StructuresActions.setStructures({ structureType }))
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setSort, setGridSearch, setStructures),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectStructureType),
				this.store.select(selectCachedPages),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(([, pagesPrev, sortPrev, gridSearchPrev], [action, pagesCurr, sortCurr, gridSearchCurr, , cachedPages]) => {
				return (
					action.type !== '[Structures] Set Force Refresh' &&
					pagesPrev.length === pagesCurr.length &&
					pagesPrev.every((f) => pagesCurr.includes(f)) &&
					sortCurr?.active === sortPrev?.active &&
					sortCurr?.direction === sortPrev?.direction &&
					gridSearchCurr === gridSearchPrev &&
					pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
				);
			}),
			map(() => StructuresActions.resetCache())
		)
	);

	deleteStructures$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeleteStructures),
			switchMap(({ structures }) => {
				return this.structureService.deleteStructure(structures.uuid).pipe(
					map(() => {
						return setDeleteStructuresSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setDeleteStructuresError({ error }));
					})
				);
			})
		)
	);

	structureSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setStructuresSuccess),
			map(({ data: { pages } }) => StructuresActions.setCachedPages({ pages }))
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeleteStructuresSuccess, setDeleteStructuresError),
			switchMap(() => {
				return this.store.select(selectStructureType).pipe(
					mergeMap((structureType) => {
						const setStructuresAction = StructuresActions.setStructures({ structureType });
						const setCachedPagesAction = StructuresActions.resetCache();
						return [setStructuresAction, setCachedPagesAction];
					})
				);
			})
		)
	);

	resetPage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StructuresActions.setGridSearch),
			map(() => StructuresActions.resetCache())
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url.includes('structure')),
			map(() => StructuresActions.resetCache())
		)
	);
}
