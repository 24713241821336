import { Injectable, inject } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DataleanBaseApiService, DataleanPagedResult, PaginationInfo, Parts, SimpleObject, SortInfo } from 'addiction-components';
import { Observable, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HeaderLocaleService } from 'src/app/components';
import { CommunityActions } from 'src/app/core/state/app.actions';
import { Community } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CommunityService {
	private store = inject(Store);
	private translateService = inject(TranslateService);
	private headerSrv = inject(HeaderLocaleService);
	private baseApi = inject(DataleanBaseApiService);

	constructor() {}

	getCommunities(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: { uuids?: string[]; query?: string };
	}) {
		const { filters, parts, locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name']; // parametrizzare i campi di ricerca??
		}

		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		return this.baseApi.getManyPaged<Community>(environment.communityUrl, parts ?? [Parts.ALL], {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	deleteCommunity(uuid: string) {
		return this.baseApi
			.deleteEntity(environment.communityUrl, { uuid })
			.pipe(tap(() => this.store.dispatch(CommunityActions.setForceRefreshCommunities())));
	}

	public multipleUpdate(bulkPayload: { uuidList: string[]; deleteAll?: boolean; active?: boolean }) {
		return this.baseApi.updateBulk(environment.communityUrl + 'multipleUpdate', bulkPayload).pipe(map(() => true));
	}

	fetchCommunities(pages: number[], sort?: Sort, locale?: string, gridSearch?: string | null): Observable<DataleanPagedResult<Community>[]> {
		const obs: Observable<DataleanPagedResult<Community>>[] = [];

		const params: {
			sortBy?: string;
			q?: string;
			searchFields?: string;
			locale?: string;
			active: boolean;
		} = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
			sortBy: 'updateDate#desc',
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name,description';
		}

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Community>(environment.communityUrl, [Parts.STRUCTURE, Parts.UPDATE_DATE], {
					pagination,
					additionalParams: params,
				})
			);
		}
		return forkJoin(obs);
	}
}
