import { ConfigurationType } from './configuration-type.enum';


export type ConfigurationValue = string | string[] | boolean | {[key: string]: unknown} | {[key: string]: unknown}[];

export interface Configuration {
	uuid: string;
	sensitive: boolean;
	key: string;
	type: ConfigurationType;
	value: ConfigurationValue;
	updateDate?: string;
}
