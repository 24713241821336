import { CommonModule } from '@angular/common';
import {
	Component,
	DestroyRef,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	Output,
	ViewChild,
	ViewEncapsulation,
	forwardRef,
	inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { Editor } from '@tiptap/core';
import BulletList from '@tiptap/extension-bullet-list';
import Color from '@tiptap/extension-color';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import { NgxTiptapModule } from 'ngx-tiptap';
import { Observer } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'datalean-html-editor',
	templateUrl: './datalean-html-editor.component.html',
	styleUrls: ['./datalean-html-editor.component.scss'],
	// encapsulation: ViewEncapsulation.ShadowDom,
	standalone: true,
	imports: [
	  CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxTiptapModule,
    TranslateModule
  ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DataleanHtmlEditorComponent),
			multi: true,
		},
	],
})
export class DataleanHtmlEditorComponent implements OnDestroy, ControlValueAccessor {
	@ViewChild('textColorInput') textColorInput!: ElementRef<HTMLInputElement>;
	@ViewChild('highlightColorInput') highlightColorInput!: ElementRef<HTMLInputElement>;

	@Input() readonly: boolean = false;
	@Output() valueChange = new EventEmitter<string | null>();

	public editor = new Editor({
		extensions: [
			StarterKit,
			Document,
			Paragraph,
			Text,
			Underline,
			Strike,
			OrderedList,
			BulletList,
			Heading,
			Link.configure({
				openOnClick: true,
				defaultProtocol: 'https',
			}),
			TextStyle,
			Color.configure({
				types: ['textStyle'],
			}),
			Highlight.configure({ multicolor: true }),
			TextAlign.configure({
				types: ['heading', 'paragraph'],
			}),
			Superscript,
			Subscript,
			Table.configure({
				resizable: true,
			}),
			TableRow,
			TableHeader,
			TableCell,
		],
		editable: !this.readonly,
	});

	protected destroyRef = inject(DestroyRef);

	formControl = new FormControl('', { validators: [Validators.required] });

	public isHeadingMenuOpen = false;
	public isLinkMenuOpen = false;

	/**
	 * Funzione per gestire la chiusura automatica delle dropdown che vengono aperte alla selezione dell'heading e del nuovo link quando si clicca al di fuori di esse.
	 */
	@HostListener('document:click', ['$event'])
	handleClickOutside(event: MouseEvent) {
		const target = event.target as HTMLElement;
		const headingDropdown = document.getElementById('heading-dropdown');
		const headingButton = document.getElementById('heading-button');

		const linkDropdown = document.getElementById('link-dropdown');
		const linkButton = document.getElementById('link-button');

		if (headingDropdown && !headingDropdown.contains(target) && headingButton && !headingButton.contains(target)) {
			this.isHeadingMenuOpen = false;
		}

		if (linkDropdown && !linkDropdown.contains(target) && linkButton && !linkButton.contains(target)) {
			this.isLinkMenuOpen = false;
		}
	}

	constructor() {
		this.formControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((val) => {
			this.valueChange.emit(val);
		});
	}

	public onTouched: () => void = () => {};

	writeValue(value: string | null): void {
		this.formControl.patchValue(value);
	}

	registerOnChange(fn: Partial<Observer<string | null>>): void {
		this.formControl.valueChanges.subscribe(fn);
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean) {
		if (disabled) {
			this.formControl.disable({ emitEvent: false });
		} else {
			this.formControl.enable({ emitEvent: false });
		}
		this.readonly = disabled;
	}

	registerOnValidatorChange(fn: () => void): void {
		this.formControl.statusChanges.subscribe(fn);
	}

	ngOnDestroy(): void {
		this.editor.destroy();
	}

	/**
	 * Funzione per gestire il set di un link sul testo selezionato.
	 * Se il valore passato è una stringa vuota il link viene rimosso.
	 */
	public setLink(url: string): void {
		this.isLinkMenuOpen = false;
		if (url === '') {
			this.editor.commands.unsetLink();
			return;
		}

		this.editor.commands.setLink({ href: url });
	}

	/**
	 * Apre il selezionatore di colori per la selezione del colore del testo.
	 */
	public openTextColorPicker() {
		this.textColorInput.nativeElement.click();
	}

	/**
	 * Apre il selezionatore di colori per l'evidenziazione del testo.
	 * Se l'evidenziazione è già attiva, disattiva l'evidenziazione.
	 */
	public openHighlightColorPicker() {
		if (this.editor.isActive('highlight')) {
			this.editor.chain().focus().toggleHighlight().run();
			return;
		}
		this.highlightColorInput.nativeElement.click();
	}

	/**
	 * Gestisce la modifica del colore del testo nell'editor.
	 */
	public onTextColorChange(event: Event) {
		const selectedColor = (event.target as HTMLInputElement).value;
		this.editor.chain().focus().setColor(selectedColor).run();
	}

	/**
	 * Gestisce la modifica del colore di evidenziazione nell'editor.
	 */
	public onHighlightColorChange(event: Event) {
		const selectedColor = (event.target as HTMLInputElement).value;
		this.editor.chain().focus().toggleHighlight({ color: selectedColor }).run();
	}
}
