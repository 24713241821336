import { DataleanEntity } from '../datalean-entity';
import { LocalizableField } from '../localizable-field';
import { FeatureValueType } from './feature-value-type.enum';

export class FeatureValue extends DataleanEntity {
	name: string = '';
	label?: string;
	order: number = 0;
	featureValues?: FeatureValue[];
	codeName: string = '';
	onlyAdminEditable?: boolean;
	parentUUID?: string;
	key?: string; //todo: togli dopo merda di MCF, Merlo e Selini
	clonable?: boolean;
	editable?: boolean;
	global?: boolean;
	imageURL?: AssetReference;
	iconURL?: AssetReference;
	localizationEnabled?: LocalizableField;
	type: FeatureValueType[] = [];
	filterOptions?: FilterOption;
	locale?: string;
	path?: string;

	declare localizedValues?: FeatureValueLocalizedValues[];

	constructor() {
		super();
	}
}

export interface FeatureValueLocalizedValues {
	locale: string;
	imageURL?: AssetReference;
	iconURL?: AssetReference;
	label?: string;
}

export interface FeatureFormValue {
	name: string;
	label: string;
	codeName: string;
	imageURL: AssetReference;
	iconURL: AssetReference;
}

export interface FilterOption {
	align: 'horizontal' | 'vertical' | 'metro';
	operator: 'or' | 'or_equal';
	collapsed: boolean;
	collapsable: boolean;
	enabled: boolean;
	isRoot: boolean;
	defaultValue: string;
	filters?: Array<ExtraFilter>;
	extra: string;
}

export interface ExtraFilter {
	feature: FeatureValue;
	between: {
		field: string;
		min: number;
		max: number;
		step: number;
		collapsed: boolean;
		collapsable: boolean;
		enabled: boolean;
	};
}

/** Rappresenta il collegamento tra un prodotto\comunicazione ed un asset, specificando eventuali parametri aggiuntivi */
export interface AssetReference {
	uuid: string;
	altText?: string;
}

/** Rappresenta il collegamento di un asset con un prodotto\comunicazione, scaricato con i dati dell'asset aggiuntivi */
export interface ResolvedAssetReference extends AssetReference {
	url: string;
	format: string;
	name?: string;
	preview?: {
		url: string;
		format?: string;
	};
}
