import { CommonModule } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { Observer } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'datalean-color-picker',
	templateUrl: './color-picker.component.html',
	styleUrls: ['./color-picker.component.scss'],
	encapsulation: ViewEncapsulation.ShadowDom,
	standalone: true,
	imports: [
	  CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ColorPickerComponent),
			multi: true,
		},
	],
})
export class ColorPickerComponent implements ControlValueAccessor {
	@Input() defaultValue: string = '#ffffff';
	@Input() readonly: boolean = false;
	@Output() colorPicked = new EventEmitter<string | null>();

	colorPickerFormControl = new FormControl(this.defaultValue, { validators: [Validators.required] });
	public onTouched: () => void = () => {};

	writeValue(value: string | null): void {
		if (!value) value = this.defaultValue;
		this.colorPickerFormControl.patchValue(value);
	}

	registerOnChange(fn: Partial<Observer<string | null>>): void {
		this.colorPickerFormControl.valueChanges.subscribe(fn);
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	public onColorPicked(event: Event): void {
		const pickedColor = (event.target as HTMLInputElement).value;
		this.colorPicked.emit(pickedColor);
		this.colorPickerFormControl.patchValue(pickedColor);
	}

	public handleInputColorClick(event: Event): void {
		if(this.readonly) return event.preventDefault();
	}
}
