import { v4 } from 'uuid';
import { StructureField, UserStructureField } from './structure-field.interface';
import { StructureType } from './structure-type.enum';

export class Structure {
	uuid: string = v4();
	name: string = '';
	localizedValues?: StructureLocalizedValues[];
	structureFields: StructureField[] = [];
	type: StructureType = StructureType.PRODUCT;
	mobileRatio?: number;
	tabletRatio?: number;
	desktopRatio?: number;
	productVariantsConfig?: string;
	childStructure?: string;
	parentStructure?: string;
}

export interface UserStructure extends Omit<Structure, 'structureFields'> {
	structureFields: UserStructureField[];
}
export class StructureLocalizedValues {
	constructor(public label: string, public description: string, public locale: string) {}
}
