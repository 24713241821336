import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';

//NB: è l'equivalente dell'addiction-list ma il ocmponente è pensato per utilizzare il virtualScroll

@Component({
  selector: 'addiction-virtual-list',
  standalone: true,
  imports: [CommonModule, ScrollingModule, CdkTableModule],
  templateUrl: './virtual-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualListComponent<T> {
  _itemsRows: T[] = [];

  @Input() itemSize: number = 50
  @Input() headerTemplate?: TemplateRef<unknown>;
  @Input() tableHeaderTemplate?: TemplateRef<unknown>;
  @Input() rowTemplate?: TemplateRef<unknown>;
  @Input() noItemsTemplate?: TemplateRef<unknown>;
  @Input({ required: true }) pageSize: number = 20;
  @Input({ required: true }) set data(data: { items: T[][]; pages?: number[]; totalItems?: number }) {
    this._availablePages = data.pages ? [...data.pages] : [];
    const tmpArray: T[] = Array.from({ length: data.totalItems ?? data.items[0].length });
    if (tmpArray.length > 0) {
      for (const page of this._availablePages) {
        tmpArray.splice(page * this.pageSize, data.items[page].length, ...data.items[page]);
      }
    }
    this._itemsRows = tmpArray;
  }

  @Output() rowClick = new EventEmitter<T>();
  @Output() pageChanges = new EventEmitter<number[]>();

  _availablePages: number[] = [];
  @ViewChild(CdkVirtualScrollViewport) scrollViewPort?: CdkVirtualScrollViewport;

  loadPaginatedData(pages: number[]) {
    this.pageChanges.emit(pages);
    this._availablePages = pages;
  }

  onScroll() {
    let end = this.scrollViewPort?.getRenderedRange().end;
    if (end) {
      end++;
    }
    const scrollRange = this.scrollViewPort?.getRenderedRange();
    if (scrollRange) {
      let pagesToLoad = this._range(this._getPageForIndex(scrollRange?.start), this._getPageForIndex(scrollRange?.end));
      // console.log(pagesToLoad, this.scrollViewPort?.getRenderedRange());

      if (pagesToLoad.length === 0) {
        pagesToLoad = [0];
      }
      if (pagesToLoad.every((val) => this._availablePages.includes(val))) {
        this._availablePages = [...pagesToLoad];
      }
      if (!this._arraysEqual(pagesToLoad, this._availablePages) || this._availablePages.length === 0) this.loadPaginatedData(pagesToLoad);
    }
  }

  private _getPageForIndex = (index: number) => Math.floor(index / this.pageSize);
  private _range = (start: number, stop: number) => Array.from({ length: stop - start + 1 }, (_, i) => start + i);
  private _arraysEqual(a: unknown[], b: unknown[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
}
