import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContextStore } from 'context-store';
import { combineLatest, map } from 'rxjs';
import { ApplicationActions, ConfigActions } from './core/state/app.actions';
import { ApplicationSelector, ConfigSelector, MediaLibrarySelectors } from './core/state/app.selectors';

@Component({
	selector: 'datalean-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	private store = inject(Store);
	private ctxStore = inject(ContextStore);
	protected isUploading$ = this.ctxStore.select(MediaLibrarySelectors.getQueue).pipe(map((queue) => queue.length > 0));

	constructor() {
		this.store.dispatch(ConfigActions.fetchConfig());

		combineLatest([this.store.select(ApplicationSelector.selectActiveLanguage), this.store.select(ConfigSelector.selectDefaultLanguage)]).subscribe(
			([activeLanguage, defaultLanguage]) => {
				if (!activeLanguage && defaultLanguage) {
					this.store.dispatch(ApplicationActions.setActiveLanguage({ activeLanguage: defaultLanguage }));
				}
			}
		);
	}
}
