export interface EnvironmentConfiguration {
	production: boolean;
	organizationUUID: string;
	userStructureUUID?: string;
	registration: {
		enabled: boolean;
		url: string;
	};
	userStructureUrl?: string;
	registrationUrl?: string;
	smartWidgetUrl?: string;
	mediaLibraryUrl: string;
}
