import { DestroyRef, Injectable, Type, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, distinct, filter, map } from 'rxjs';
import { MessageComponent } from '../components/message/message.component';
import { BaseModalContent } from '../components/modal/base-modal-content';
import { ModalDialogComponent } from '../components/modal/modal-dialog/modal-dialog.component';
import { ActiveModalService } from './active-modal.service';

type GenericOf<T> = T extends BaseModalContent<infer X> ? X : never;
export interface ModalOptions {
	title?: string;
	size?: 'default' | 'fullscreen';
}

@Injectable({ providedIn: 'root' })
/** Permette di aprire una modal in overlay e di renderizzarne un contenuto */
export class ModalService {
	private dialog = inject(MatDialog);
	private activeModal = inject(ActiveModalService);
	private translatService = inject(TranslateService);
	private currentDialogs: {
		[key: string]: MatDialogRef<ModalDialogComponent, unknown> | null;
	} = {};

	protected destroyRef = inject(DestroyRef);

	constructor() {
		// Si aggancia all'evento di chiusura della modal (evitando dip. circolari)
		this.activeModal._close$
			.pipe(
				distinct((x) => x.dialogId),
				filter(({ dialogId }) => this.isDialogOpen(dialogId)),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe(({ dialogId, reason, data }) => {
				// console.log('ModalService -> constructor -> this.activeModal._close$', dialogId, reason, data);
				this.closeDialog(dialogId, reason, data);
			});
	}

	/** Apre una finestra di dialogo */
	/** @returns Un observable (single emission) con il risultato della finestra di dialogo */
	openDialog<TComp extends BaseModalContent<TRes>, TRes>(
		component: Type<TComp>,
		options?: ModalOptions,
		inputData?: Partial<{ [key in keyof TComp]: TComp[key] }>
	): { id: string; result$: Observable<ModalResult<GenericOf<TComp>>> } {
		const opts: MatDialogConfig = {
			backdropClass: 'modal-backdrop',
			panelClass: options?.size || 'default',
			maxWidth: '',
			closeOnNavigation: true,
			disableClose: true,
			data: {
				component,
				inputData,
				title: options?.title,
			},
		};
		const dialog = this.dialog.open(ModalDialogComponent, opts);
		const { id } = dialog;
		dialog.componentInstance.setDialogId(id);

		this.currentDialogs[id] = dialog;

		return {
			result$: dialog.afterClosed().pipe(map((res) => res as ModalResult<GenericOf<TComp>>)),
			id,
		};
	}

	openGenericErrorDialog() {
		return this.openDialog(
			MessageComponent,
			{ title: this.translatService.instant('ERRORS.GENERIC_TITLE') },
			{
				message: 'ERRORS.GENERIC',
				showCancelButton: false,
			}
		);
	}

	/** Chiude la finestra di dialogo corrente */
	closeDialog<TRes>(dialogId: string, reason: CloseReason, data: TRes) {
		const currentDialog = this.currentDialogs[dialogId];
		if (!currentDialog || !currentDialog.close) return;
		delete this.currentDialogs[dialogId];
		currentDialog.close({ reason, data });
	}

	isDialogOpen(dialogId: string) {
		return !!this.currentDialogs[dialogId];
	}
}

export type CloseReason = 'CANCEL' | 'COMPLETE';

export interface ModalResult<T> {
	reason: CloseReason;
	data?: T;
}

export interface CheckedResult<T> {
	reason: CloseReason;
	data: T;
}
