import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Asset, Collection, DataleanBaseApiService, FilterSort, Parts } from 'addiction-components';
import { BehaviorSubject, combineLatest, filter, switchMap } from 'rxjs';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { environment } from 'src/environments/environment';

@Injectable()
export class CollectionSelectorDetailService {
	private dataleanApi = inject(DataleanBaseApiService);

	//state
	private _searchValue = signal<string | null>(null);
	private _status = signal<STATE_STATUS>(STATE_STATUS.LOADING);
	private _collectionUUID = signal<string | undefined>(undefined);
	private _collection = signal<Collection | undefined>(undefined);
	private _sortOption = signal<FilterSort>(environment.sortOptions[0]);
	private _selectedElements = signal<Set<string>>(new Set());

	// selectors

	collection = computed(() => this._collection());
	collectionUUID = computed(() => this._collectionUUID());
	selectedElements = computed(() => this._selectedElements());

	filteredCollectionAssets = computed(() => {
		const collection = this.collection();
		const searchValue = this.searchValue();

		let arrayToSort = collection?.assets;
		if (searchValue) arrayToSort = collection?.assets?.filter((asset) => asset.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));

		const sortOption = this.sortOption();
		const sortDirection = sortOption.value.split('#')[1] === 'asc' ? 1 : -1;

		return arrayToSort?.sort((a, b) => {
			let valueToSortA = a[sortOption.value.split('#')[0] as keyof Asset];
			if (typeof valueToSortA === 'string') {
				valueToSortA = valueToSortA.toLowerCase();
			}
			let valueToSortB = b[sortOption.value.split('#')[0] as keyof Asset];
			if (typeof valueToSortB === 'string') {
				valueToSortB = valueToSortB.toLowerCase();
			}
			if (valueToSortA === undefined || valueToSortA === null) {
				return 1;
			}
			if (valueToSortB === undefined || valueToSortB === null) {
				return -1;
			}
			if (valueToSortA < valueToSortB) {
				return -1 * sortDirection;
			}
			if (valueToSortA > valueToSortB) {
				return 1 * sortDirection;
			}
			return 0;
		});
	});
	status = computed(() => this._status());
	searchValue = computed(() => this._searchValue());
	sortOption = computed(() => this._sortOption());

	//actions rxjs
	reload$ = new BehaviorSubject<void>(undefined); //deve essere un behaviorSubject perchè altrimenti il combineLatest non si triggera
	collectionLoaded$ = combineLatest([toObservable(this.collectionUUID), this.reload$]).pipe(
		filter(([uuid]) => !!uuid),
		switchMap(([uuid]) => {
			return this.dataleanApi.getEntity<Collection>(environment.collectionsUrl, uuid!, [Parts.PREVIEW_ASSET_INFO]);
		})
	);

	constructor() {
		//  reducers rxjs
		this.collectionLoaded$.pipe(takeUntilDestroyed()).subscribe({
			next: (collection) => {
				this.setCollection(collection);
				this.setStatus(STATE_STATUS.READY);
			},
			error: () => this.setStatus(STATE_STATUS.ERROR),
		});

		this.reload$.pipe(takeUntilDestroyed()).subscribe({
			next: () => {
				this.setStatus(STATE_STATUS.LOADING);
			},
		});
	}

	// actions/reducers signal
	private setStatus(status: STATE_STATUS) {
		this._status.set(status);
	}
	setSortOption(sortOption: FilterSort) {
		this._sortOption.set(sortOption);
	}
	setSearchValue(searchValue: string | null) {
		this._searchValue.set(searchValue);
	}
	setCollection(collection: Collection) {
		this._collection.set(collection);
	}
	setCollectionUUID(uuid: string) {
		this._collectionUUID.set(uuid);
	}
	toggleSelectedElement(uuid: string) {
		this._selectedElements.update((selectedElements: Set<string>) => {
			selectedElements = new Set(selectedElements.has(uuid) ? [] : [uuid]);
			return selectedElements;
		});
	}
}
