import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToLocalizedValuePipe } from 'addiction-components';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { ProductVersioningActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { ProductService } from '../../services/product.service';
import { setProductVersioningSuccess } from './product-versioning.actions';
import { selectCachedPages, selectPagedData, selectPages, selectProductUUID, selectSort, selectStatus } from './product-versioning.selectors';

@Injectable()
export class ProductVersioningEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private productService = inject(ProductService);
	private translateService = inject(TranslateService);
	private localizablePipe = inject(ToLocalizedValuePipe);

	fetchProducts$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(ProductVersioningActions.setPages, ProductVersioningActions.setForceRefreshProductsVersioning),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectCachedPages),
				this.store.select(selectProductUUID),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte e il productuuid
			filter(([, pages, , , productUuid]) => !!pages?.length && !!productUuid),
			tap(() => {
				this.store.dispatch(ProductVersioningActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, cachedPages, productUid]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;
				return this.productService.getProductVersioning(productUid, actualPages, this.translateService.currentLang, sort).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						// console.log(startingResult);
						startingResult.pages = cachedPages;
						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.pageNumber);
								acc.totalProductsCount = item.paginationInfo.totalElements ?? 0;
								if (item.result) {
									acc.products[item.paginationInfo.pageNumber] = item.result.map((product) => ({
										...product,
										features: product.featureValueList.map((f) => (typeof f === 'string' ? '' : this.localizablePipe.transform(f.label || f.name))),
									}));
								}
							}
							return acc;
						}, structuredClone(startingResult));

						// console.log(result);

						return ProductVersioningActions.setProductVersioningSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						return of(ProductVersioningActions.setProductsError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductVersioningActions.setSort),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectCachedPages),
				this.store.select(selectProductUUID),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(([, pagesPrev, sortPrev, , productUUIDPrev], [, pagesCurr, sortCurr, cachedPages, productUUIDCurr]) => {
				return (
					pagesPrev.length === pagesCurr.length &&
					pagesPrev.every((f) => pagesCurr.includes(f)) &&
					sortCurr?.active === sortPrev?.active &&
					sortCurr?.direction === sortPrev?.direction &&
					pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0 &&
					productUUIDPrev === productUUIDCurr
				);
			}),
			map(() => ProductVersioningActions.resetCache())
		)
	);

	productVersioningSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setProductVersioningSuccess),
			map(({ data: { pages } }) => ProductVersioningActions.setCachedPages({ pages }))
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductVersioningActions.resetCache),
			map(() => ProductVersioningActions.setForceRefreshProductsVersioning())
		)
	);

	constructor() {}
}
