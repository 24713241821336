import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToLocalizedValuePipe } from 'addiction-components';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, debounceTime, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { CommunitySelectActions, ProductActions } from 'src/app/core/state/app.actions';
import { CommunitySelectSelectors, ProductSelector } from 'src/app/core/state/app.selectors';
import { STATE_STATUS } from 'src/app/shared/models';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { ProductService } from '../services/product.service';
import { setProductsSuccess } from './product.actions';

@Injectable()
export class ProductEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private productService = inject(ProductService);
	private translateService = inject(TranslateService);
	private localizablePipe = inject(ToLocalizedValuePipe);
	private configurationService = inject(ConfigurationService);
	private activeRoute = inject(Router);

	fetchProducts$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(ProductActions.setPages, ProductActions.setForceRefreshProducts),
			concatLatestFrom(() => [
				this.store.select(ProductSelector.selectPages),
				this.store.select(ProductSelector.selectSort),
				this.store.select(ProductSelector.selectGridSearch),
				this.store.select(ProductSelector.selectProductsFilters),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				this.store.select(ProductSelector.selectCachedPages),
			]),
			debounce(() => this.store.select(ProductSelector.selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages, , , filters]) => !!pages?.length && !!filters.structureUUID),
			tap(() => {
				this.store.dispatch(ProductActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, filters, communityUUID, cachedPages]) => {
				// console.log(filters);

				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) {
					this.store.dispatch(ProductActions.setStatus({ status: STATE_STATUS.READY }));
					return EMPTY;
				}
				const filterWithCommunity = structuredClone(filters);
				if (communityUUID) filterWithCommunity.communityUUID = communityUUID;
				return this.productService
					.fetchProducts(
						actualPages,
						sort,
						this.translateService.currentLang,
						gridSearch,
						filterWithCommunity,
						this.configurationService.getConfigValue('hasVersioning'),
					)
					.pipe(
						combineLatestWith(this.store.select(ProductSelector.selectPagedData)),
						first(),
						map(([data, startingResult]) => {
							startingResult.pages = cachedPages;
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.pages.push(item.paginationInfo.pageNumber);
									acc.totalProductsCount = item.paginationInfo.totalElements ?? 0;
									if (item.result) {
										acc.products[item.paginationInfo.pageNumber] = item.result.map((product) => ({
											...product,
											features: product.featureValueList.map((f) => (typeof f === 'string' ? '' : this.localizablePipe.transform(f.label || f.name))),
										}));
									}
								}
								return acc;
							}, structuredClone(startingResult));

							return ProductActions.setProductsSuccess({ data: result });
						}),
						catchError((error: HttpErrorResponse) => {
							console.error(error);
							return of(ProductActions.setProductsError({ error }));
						}),
					);
			}),
		),
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				ProductActions.setGridSearch,
				ProductActions.setSort,
				ProductActions.updateFilters,
				ProductActions.addFeatureValueToFilters,
				ProductActions.removeFeatureValueFromFilters,
			),
			concatLatestFrom(() => [
				this.store.select(ProductSelector.selectPages),
				this.store.select(ProductSelector.selectSort),
				this.store.select(ProductSelector.selectGridSearch),
				this.store.select(ProductSelector.selectProductsFilters),
				this.store.select(ProductSelector.selectCachedPages),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				([, pagesPrev, sortPrev, gridSearchPrev, filterPrev], [, pagesCurr, sortCurr, gridSearchCurr, filterCurr, cachedPages]) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						Object.values(filterPrev).every((f) => Object.values(filterCurr).includes(f)) &&
						Object.values(filterCurr).every((f) => Object.values(filterPrev).includes(f)) &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				},
			),
			map(() => ProductActions.resetCache()),
		),
	);

	deleteProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductActions.deleteProducts),
			// invoke API
			switchMap(({ uuids }) =>
				this.productService
					.multipleUpdate({
						uuidList: uuids,
						delete: true,
					})
					.pipe(
						map(() => ProductActions.setDeleteProductsSuccess()),
						catchError((error: HttpErrorResponse) => of(ProductActions.error({ error: { errorType: 'generic_http', data: error } }))),
						tap(() => this.productService.getStructureAndFeatures()),
					),
			),
		),
	);

	disableProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductActions.disableProducts),
			// invoke API
			switchMap(({ uuids }) =>
				this.productService
					.multipleUpdate({
						uuidList: uuids,
						active: false,
					})
					.pipe(
						map(() => ProductActions.setDisableProductsSuccess()),
						catchError((error: HttpErrorResponse) => of(ProductActions.error({ error: { errorType: 'generic_http', data: error } }))),
						tap(() => this.productService.getStructureAndFeatures()),
					),
			),
		),
	);

	productSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setProductsSuccess),
			map(({ data: { pages } }) => ProductActions.setCachedPages({ pages })),
		),
	);

	deleteAndDisableProduct$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				ProductActions.setDeleteProductsSuccess,
				ProductActions.setDeleteProductsError,
				ProductActions.setDisableProductsSuccess,
				ProductActions.setDisableProductsError,
			),
			map(() => ProductActions.resetCache()),
		),
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductActions.resetCache),
			tap(() => {
				this.store.dispatch(ProductActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			debounceTime(500),
			map(() => ProductActions.setForceRefreshProducts()),
			tap(() => {
				this.store.dispatch(ProductActions.setStatus({ status: STATE_STATUS.READY }));
			}),
		),
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url === '/products'),
			map(() => ProductActions.resetCache()),
		),
	);

	constructor() {}
}
