import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { SmartGroupsActions } from 'src/app/core/state/app.actions';
import { ApplicationUserSmartGroup } from 'src/app/shared/models';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';

export const SMART_GROUPS_TAG = 'smartGroups';

export interface SmartGroupsState {
	gridSearch: string | null;
	sort?: Sort;
	data: { totalSmartGroupsCount: number; groups: ApplicationUserSmartGroup[][] };
	pages: number[];
	status: STATE_STATUS;
	deleteStatus: STATE_STATUS;
	cachedPages: number[];
}

const initialState: SmartGroupsState = {
	status: STATE_STATUS.UNKNOWN,
	deleteStatus: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	pages: [],
	data: { totalSmartGroupsCount: 0, groups: [] },
	cachedPages: [],
	sort: { active: 'updateDate', direction: 'desc' },
};

export const reducers = createReducer(
	initialState,
	on(SmartGroupsActions.setSort, (state: SmartGroupsState, { sort }) => ({
		...state,
		sort,
	})),
	on(SmartGroupsActions.setPages, (state: SmartGroupsState, { pages }) => ({
		...state,
		pages,
	})),
	on(SmartGroupsActions.setSmartGroupsSuccess, (state: SmartGroupsState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				groups: data.groups,
				totalSmartGroupsCount: data.totalSmartGroupsCount,
			},
			pages: [...state.cachedPages, ...data.pages],
		};
	}),
	on(SmartGroupsActions.setSmartGroupsError, (state: SmartGroupsState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(SmartGroupsActions.setStatus, (state: SmartGroupsState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(SmartGroupsActions.setGridSearch, (state: SmartGroupsState, { search }) => {
		return {
			...state,
			gridSearch: search,
			cachedPages: [],
			data: { totalSmartGroupsCount: 0, groups: [] },
		};
	}),
	on(SmartGroupsActions.setDeleteDataleanSmartGroup, (state: SmartGroupsState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(SmartGroupsActions.setDeleteDataleanSmartGroupSuccess, (state: SmartGroupsState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(SmartGroupsActions.setDeleteDataleanSmartGroupError, (state: SmartGroupsState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
  on(SmartGroupsActions.setCachedPages, (state: SmartGroupsState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
  on(SmartGroupsActions.resetCache, (state: SmartGroupsState) => ({
		...state,
		cachedPages: [],
    status: STATE_STATUS.READY,
		pages: [0],
		data: { totalSmartGroupsCount: 0, groups: [] },
	}))
);

export const DataleanSmartGroupReducer = (state: SmartGroupsState | undefined, action: Action) => {
	return reducers(state, action);
};
