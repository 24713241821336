<div class="color-picker-container">
	<input
		[ngClass]="{ 'readonly': readonly }"
		type="color"
		id="color-picker"
		(click)="handleInputColorClick($event)"
		(input)="onColorPicked($event)"
		[formControl]="colorPickerFormControl" />
	<label for="color-picker">{{ "STRUCTURE.FIELD.HTML_COLOR" | translate }}</label>
</div>
