<addiction-search (search)="filterBy($event)"></addiction-search>

<ng-container *ngrxLet="tableRows$ as data">
	<datalean-table
		(pageChange)="onPageChanged($event)"
		[dataSource]="(tableRows$ | async) ?? { pages: [], items: [], totalItems: loading ? env.pageSize : 0 }"
		[pageSize]="pageSize"
		[noItemsTemplate]="noItemsTemplate"
		[columns]="columns"
		[templates]="[t1, t2]"
		(checkboxHeaderSelected)="onCheckboxHeaderSelected($event)">
		<ng-template #t1 let-item>
			<mat-checkbox
				(click)="$event.stopPropagation()"
				class="checkbox"
				[checked]="gcs.checkboxMap[item.uuid] === undefined ? gcs.selectAllChecked : gcs.checkboxMap[item.uuid]"
				(change)="onSelectionChange($event, item.uuid)"></mat-checkbox>
		</ng-template>
		<ng-template #t2 let-item>
			<span class="highlight">{{ item.name }}</span>
		</ng-template>
	</datalean-table>
</ng-container>

<ng-template #noItemsTemplate>
	<div class="no-results-container">
		<ng-lottie [options]="{ path: './assets/lottie/icn-dam-nocontent.json' }"></ng-lottie>
		<div class="title">
			{{ 'GRID.NO_RESULTS_TITLE' | translate }}
		</div>
		<div class="text">
			{{ 'GRID.NO_RESULTS_TEXT' | translate }}
		</div>
	</div>
</ng-template>

<div class="buttons-container">
	<button (click)="close()">
		<span>{{ 'ADD' | translate }}</span>
	</button>
</div>
