import { createAction, props } from '@ngrx/store';
import { HeaderState } from './header.state';

export const setConfig = createAction('[Header] Set Header Config', props<{ config: Partial<HeaderState> }>());
export const setShowConfig = createAction('[Header] Set Header Show Config', props<{ communitySelect: boolean; localeSelect: boolean }>());
export const setShowCommunitySelect = createAction('[Header] Set Show Community Select', props<{ show: boolean }>());
export const setShowLocales = createAction('[Header] Set Show Locales', props<{ show: boolean }>());
export const setShowBackButton = createAction('[Header] Set Show Back Button', props<{ showBackButton: boolean }>());
export const setShowPlusButton = createAction('[Header] Set Show Plus Button', props<{ showPlusButton: boolean }>());
export const setBackButtonUrl = createAction('[Header] Set Back Button Url', props<{ backButtonUrl: string[] }>());
export const setBackButtonFn = createAction('[Header] Set Back Button Fn', props<{ backButtonFn: () => void }>());
export const setRemoveButton = createAction('[Header] Set Remove Button', props<{ showRemoveButton: boolean }>());
export const activateBackButton = createAction('[Header] Activate Back Button', props<{ showBackButton: boolean; backButtonUrl?: string[] }>());
export const activatePlusButton = createAction('[Header] Activate Plus Button', props<{ showPlusButton: boolean }>());
export const activateRemoveButton = createAction('[Header] Activate Remove Button', props<{ showRemoveButton: boolean }>());
export const deactivateBackButton = createAction('[Header] Deactivate Back Button');
export const deactivatePlusButton = createAction('[Header] Deactivate Plus Button');
export const deactivateRemoveButton = createAction('[Header] Deactivate Remove Button');
export const setMediaLibraryGridViewOptions = createAction(
	'[Header] Set Media Library View Options',
	props<{ options: { assetWidth: number; assetdisplayMode: number } }>()
);
