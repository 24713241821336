<mat-accordion>
	<mat-expansion-panel
		*ngFor="let navLink of links$ | async; let i = index; trackBy: trackBy"
		[routerLink]="navLink.path"
		[disabled]="!navLink.children"
		[expanded]="checkIfNavLinkSelected(navLink, activePath$ | async)">
		<mat-expansion-panel-header [ngClass]="{ parent: navLink.children }" (click)="!navLink.children && this.accordion.closeAll()">
			<mat-panel-title>
				{{ navLink.label | translate }}
			</mat-panel-title>
		</mat-expansion-panel-header>
		<ng-container>
			<button
				*ngFor="let button of navLink.children; trackBy: trackBy"
				[routerLink]="button.path"
				[ngClass]="{ active: checkIfButtonActive(button.path, (activePath$ | async) ) }">
				{{ button.label | translate }}
			</button>
		</ng-container>
	</mat-expansion-panel>
</mat-accordion>
